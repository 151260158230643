import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type PostTriageProgressInputGender = SessionInput & {
	updateGender: boolean;
	gender: string;
};

export type PostTriageProgressInputAge = SessionInput & {
	updateAge: boolean;
	age: number;
	birthdate: Date;
};

export type PostTriageProgressInputBodyArea = SessionInput & {
	updateBodyArea: boolean;
	bodyAreaId: number;
};

export type PostTriageProgressInput = PostTriageProgressInputGender | PostTriageProgressInputAge | PostTriageProgressInputBodyArea;

async function CallPostTriageProgress(apiKey: string, input: PostTriageProgressInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('PostTriageProgress?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostTriageProgress');
	}

	return false;
}

export { CallPostTriageProgress };
