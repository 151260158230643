import styled, { keyframes } from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import { Gender } from '../../models';

interface ActivityComplaintBodyProps {
	disabled: boolean;
	$highlight?: number;
	$anchor?: { x: number, y: number };
	$useChildSvg?: boolean;
	$hasAnswer?: boolean;
	$bodySide?: boolean;
}

const ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

const ActivityComplaintWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${(props) => props.theme.spacings.medium};
`;

const flash = keyframes`
	0% { opacity: 0; }
	8% { opacity: 0; }
	10% { opacity: 0.2; }
	18% { opacity: 0.2; }
	20% { opacity: 0; }
	100% { opacity: 0; }
`;
const rippleInner = keyframes`
	0% { transform: scale(0); }
	8% { transform: scale(0); }
	10% { transform: scale(1.1); }
	12% { transform: scale(1.0); }
	14% { transform: scale(1.0); }
	16% { transform: scale(1.1); }
	18% { transform: scale(1.1); }
	20% { transform: scale(1.0); }
	22% { transform: scale(1.0); }
	24% { transform: scale(0); }
	100% { transform: scale(0); }
`;
const rippleOuter = keyframes`
	0% { transform: scale(0); opacity: 0; }
	8% { transform: scale(0); opacity: 0.4; }
	10% { transform: scale(1.1); }
	12% { transform: scale(1.4); }
	14% { transform: scale(1.4); }
	16% { transform: scale(1.1); }
	18% { transform: scale(1.1); }
	20% { transform: scale(1.4); }
	22% { transform: scale(1.4); opacity: 0.4; }
	24% { transform: scale(1.4); opacity: 0; }
	25% { transform: scale(0); opacity: 0; }
	100% { transform: scale(0); opacity: 0; }
`;
const ripplePositions = {
	child: {
		top: '80',
		left: '21'
	},
	adult: {
		top: '60',
		left: '29'
	}
}
const getRipplePosition = (child = false) => {
	return ripplePositions[child ? 'child' : 'adult'] ?? ripplePositions.adult;
}

const Body = styled.div<ActivityComplaintBodyProps>`
	position: relative;
	max-width: 100%;

	width: 100%;
	max-height: 500px;
	height: 100%;
	padding-inline-end: 0;
	display: flex;
	margin: ${(props) => props.theme.spacings.small} auto ${(props) => props.theme.spacings.small} auto;
	align-items: center;

	@media ${(props) => props.theme.screens.medium} {
		max-width: 250px;
		padding-inline-end: 1em;
	}

	g[data-bodyareaid]:focus {
		outline: none;

		path {
			opacity: ${(props) => !props.disabled && '0.4'};
		}
	}

	path[data-bodyareaid],
	g[data-bodyareaid] path {
		transition: opacity 0.15s ease-in;
		opacity: 0;
		fill: ${(props) => props.theme.colors.text900};
		cursor: ${(props) => !props.disabled && 'pointer'};

		&:focus,
		&:hover {
			opacity: ${(props) => !props.disabled && '0.4'};
			outline: none;
		}

		&:focus {
			outline: none;
		}
	}
	path[data-bodyareaid='${(props) => props.$highlight}'] {
		fill: ${(props) => props.theme.colors.accent};
		opacity: 0.4;
	}
	path[data-disabled],
	g[data-disabled] path {
		fill: ${(props) => props.theme.colors.text300};
		opacity: 0.2;
	}
	g[data-disabled] path[id='haar'],
	g[data-disabled] path[id='haarachter'] {
		fill: ${(props) => props.theme.colors.text300};
		opacity: 1;
	}

	path[id='haar'],
	path[id='haarachter'],
	g[data-bodyareaid] path[id='haar'],
	g[data-bodyareaid] path[id='haarachter'] {
		opacity: 1;
	}
	g[data-bodyareaid] path[id='haar']:hover,
	g[data-bodyareaid] path[id='haarachter']:hover {
		opacity: ${(props) => !props.disabled && '0.5'};
	}

	svg {
		width: 100%;
		height: 100%;
		max-height: 500px;
		&.child {
			height: 85%;
			#haarachter {
				opacity: 0;
			}
		}
	}

	path[data-selected],
	g[data-selected] path,
	g[data-selected] path[id='haar'],
	g[data-selected] path[id='haarachter'] {
		opacity: 0.6;
	}

	
	&:not(:hover) {
		path[id=${(props) => props.$bodySide ? 'rechterarm' : 'linkerarm'}],
		g[data-bodyareaid] path[id=${(props) => props.$bodySide ? 'rechterarm' : 'linkerarm'}] {
			animation: ${(props) => !props.$hasAnswer ? flash : ''} 15s linear infinite;
		}

		&::before {
			content: "";
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(${(props) => props.$anchor?.y}px + ${(props) => getRipplePosition(props.$useChildSvg).top}px);
			left: calc(${(props) => props.$anchor?.x}px + ${(props) => getRipplePosition(props.$useChildSvg).left}px);
			translate: -50% 0;
			border-radius: 100%;
			background-color: ${(props) => props.theme.colors.accent};
			transition: transform 0.15s ease-in-out;
			opacity: ${(props) => !props.$hasAnswer ? '0.2' : '0'};
			animation: ${(props) => !props.$hasAnswer ? rippleOuter : ''} 15s linear infinite;
		}
		&::after {
			content: "";
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(${(props) => props.$anchor?.y}px + ${(props) => getRipplePosition(props.$useChildSvg).top}px);
			left: calc(${(props) => props.$anchor?.x}px + ${(props) => getRipplePosition(props.$useChildSvg).left}px);
			translate: -50% 0;
			border-radius: 100%;
			background-color: ${(props) => props.theme.colors.accent};
			transition: transform 0.15s ease-in-out;
			opacity: ${(props) => !props.$hasAnswer ? 1 : 0};
			animation: ${(props) => !props.$hasAnswer ? rippleInner : ''} 15s linear infinite;
		}
    }
`;

const Switch = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: ${(props) => props.theme.colors.text200};
	border-radius: ${(props) => props.theme.radii.default};
	padding: 4px;
	margin-bottom: ${(props) => props.theme.spacings.small};

	& button, div {
		text-align: center;
		flex-grow: 1;
		cursor: pointer;
		border-radius: ${(props) => props.theme.radii.default};
		border: none;
		padding: 4px;
		font-size: ${(props) => props.theme.fontSizes.base};
		color: ${(props) => props.theme.colors.text600};
		background: transparent;
		
		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.active {
			background-color: white;
			color: ${(props) => props.theme.colors.text900};
			border: 1px solid ${(props) => props.theme.colors.borderLight};
		}
		
		&:focus {
			outline: solid 1px ${(props) => props.theme.colors.accent};
		}
	}

	@media ${(props) => props.theme.screens.medium} {
		width: 70%;
		margin: 0 auto;
	}
`;

const SvgLoader = styled.div`
	/* max-width: 250px; */

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ComplaintsTitle = styled.h3`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	width: 100%;
	color: ${(props) => props.theme.colors.text};
	text-transform: uppercase;
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	margin-bottom: 0;
	&:after {
		content: ' ';
		display: inline-block;
		height: 1px;
		background-color: ${(props) => props.theme.colors.text};
		width: 100%;
		margin-inline-start: 7px;
	}
`;

export default {
	ActivityBubble,
	ActivityComplaintWrapper,
	Body,
	Switch,
	SvgLoader,
	ComplaintsTitle
};
