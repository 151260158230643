import { Session, Settings } from '../../models';
import { CallGetSessionSummary } from '../services';

export const advicePdfEventlistenerHelper = async (settings: Settings, session: Session) => {
	try {
		const sessionSummary = await CallGetSessionSummary(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			pdfType: 'Default',
			languageCode: 'NL', // Always in Dutch
			returnAsUrl: true
		});

		if (!sessionSummary) return;

		if (settings.target instanceof Element) {
			settings.target.dispatchEvent(
				new CustomEvent('minddAdvicePdfRetrieval', {
					bubbles: true,
					detail: { downloadUrl: sessionSummary.pdfUrl, urgence: sessionSummary.urgence }
				})
			);
		}

		if (typeof settings.onAdvicePdfRetrieval === 'function') {
			settings.onAdvicePdfRetrieval(sessionSummary.pdfUrl, sessionSummary.urgence);
		}
	} catch (error) {
		console.error('Error downloading session report:', error);
	}
};
