import { isAxiosError } from 'axios';
import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { ProblemDetails, User } from '../../models';

export type CallBackEmailInput = SessionInput &
	LanguageInput & {
		user: User;
		userAgreedToPrivacyStatement: boolean;
		chosenTimeslot?: string | null;
	};

export type CallBackEmailOutput = {
	message: 'success' | 'ConsultationPreparation';
	callbackTime?: string;
};

async function CallPostCallBackEmail(apiKey: string, input: CallBackEmailInput): Promise<CallBackEmailOutput | ProblemDetails | undefined> {
	try {
		const { data } = await minddApiAxios.post<CallBackEmailOutput>('PostCallBackEmail?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallPostCallBackEmail');

		if (isAxiosError<ProblemDetails>(error) && error.response) {
			return error.response.data;
		}
	}

	return undefined;
}

export { CallPostCallBackEmail };
