import React from 'react';
import styled, { css } from 'styled-components';
import type { DivProps } from '../../models/widget/Styled';
import { ActivityBubbleProps, default as SA } from '../Activity/Activity.styled';

const ClearActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		background-color: transparent !important;
		font-size: 18px;
		//todo color dynamic?
		color: #60606a;
		box-shadow: none;
		padding: 0;
		border: none;
		margin-bottom: 0;
	}
`;

interface StartActivityWrapperProps {
	$isSmallContainer?: boolean;
}

const StartActivityWrapper = styled.div<StartActivityWrapperProps>`
	display: flex;
	flex-direction: ${(props) => (props.$isSmallContainer ? 'column' : 'row')};
	gap: ${(props) => (props.$isSmallContainer ? props.theme.spacings.small : props.theme.spacings.medium)};

	margin-top: ${(props) => props.theme.spacings.medium};
	margin-bottom: ${(props) => (props.$isSmallContainer ? props.theme.spacings.large : props.theme.spacings.small)};

	& > * {
		flex: 1;
	}
`;

const ActivityWelcome = styled.div`
	display: flex;
	flex-direction: column;
`;

const Footer = styled.div`
	display: flex;
	flex-wrap: wrap-reverse;
	align-items: center;
	justify-content: space-between;
	gap: ${(props) => props.theme.spacings.small};
	padding-bottom: ${(props) => props.theme.spacings.medium};
`;

interface LanguageSelectorWrapperProps {
	$smallContainer?: boolean;
}

const LanguageSelectorWrapper = styled.div<LanguageSelectorWrapperProps>`
	${(props) =>
		!props.$smallContainer &&
		css`
			display: flex;
			justify-content: flex-end;
		`}
`;

export default {
	ClearActivityBubble: (props: DivProps<ActivityBubbleProps>) => (
		// @ts-expect-error prop types are correct
		<ClearActivityBubble {...props} data-minddclass="clearactivitybubble">
			{props.children}
		</ClearActivityBubble>
	),
	ActivityWelcome,
	StartActivityWrapper,
	LanguageSelectorWrapper,
	Footer
};
