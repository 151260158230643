import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { useTheme } from 'styled-components';

import S from './styled';

import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import type { ActivityAnswerAgeSelector, CalculatedAge, DateOfBirth } from '../../models';
import useSelectStyles from '../../helpers/styled/Select';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

type ValidationStatus = '' | 'valid' | 'invalid' | 'maxAgeExceeded';

type AgeYearInputProps = {
	confirmButtonClassName: string;
	disabled: boolean;
	step?: number;
	title: string | JSX.Element;
	answer?: { dob: DateOfBirth; age: CalculatedAge; yearInput?: string; ageUnit?: AgeUnit; additionalInput?: string };
	onChange: (answer?: ActivityAnswerAgeSelector) => void;
};

export enum AgeUnit {
	Year = 'jaar',
	Month = 'maanden',
	Week = 'weken'
}

type AgeYearLabels = {
	WidgetAgeYearLabel: string;
	WidgetDisplayAgeYearsUnit: string;
	WidgetDisplayAgeMonthsUnit: string;
	WidgetDisplayAgeMonthUnit: string;
	WidgetDisplayAgeWeeksUnit: string;
	WidgetDisplayAgeWeekUnit: string;
	WidgetDateOfBirthRequestError: string;
	WidgetMaxAgeRequestError: string;
	WidgetConfirmButton: string;
	WidgetAgeWeekPlaceholder: string;
	WidgetAgeMonthPlaceholder: string;
	WidgetAgeYearShortPlaceholder: string;
	WidgetAgeDescription: string;
};

type InputState = {
	yearInput: string;
	ageUnit: AgeUnit;
	additionalInput: string;
};

const AgeYearInput: FunctionComponent<AgeYearInputProps> = (props) => {
	const [{ profile, settings, modal }] = useStateContext();

	const [initialState] = useState(() => getInitialState(props.answer, profile.age, settings.forceInitialStateEmpty));
	const { dob, age: initialAge, inputState: initialInputState } = initialState;

	const [inputState, setInputState] = useState<InputState>(initialInputState);
	const [age, setAge] = useState<CalculatedAge>(initialAge);

	const labels = useMemo<AgeYearLabels>(
		() => ({
			WidgetAgeYearLabel: getLabel('WidgetAgeLabel', settings.applicationTexts, true),
			WidgetDisplayAgeYearsUnit: getLabel('WidgetDisplayAgeYearsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthsUnit: getLabel('WidgetDisplayAgeMonthsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthUnit: getLabel('WidgetDisplayAgeMonthUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeeksUnit: getLabel('WidgetDisplayAgeWeeksUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeekUnit: getLabel('WidgetDisplayAgeWeekUnit', settings.applicationTexts, true),
			WidgetDateOfBirthRequestError: getLabel('WidgetDateOfBirthRequestError', settings.applicationTexts, true),
			WidgetMaxAgeRequestError: getLabel('WidgetMaxAgeRequestError', settings.applicationTexts, true),
			WidgetConfirmButton: getLabel('WidgetConfirmButton', settings.applicationTexts, true),
			WidgetAgeWeekPlaceholder: getLabel('WidgetAgeWeekPlaceholder', settings.applicationTexts, true),
			WidgetAgeMonthPlaceholder: getLabel('WidgetAgeMonthPlaceholder', settings.applicationTexts, true),
			WidgetAgeYearShortPlaceholder: getLabel('WidgetAgeYearShortPlaceholder', settings.applicationTexts, true),
			WidgetAgeDescription: getLabel('WidgetAgeDescription', settings.applicationTexts, true)
		}),
		[settings.applicationTexts]
	);

	const [validationStatus, setValidationStatus] = useState<ValidationStatus>(props.answer ? 'valid' : '');

	const themeContext = useTheme();
	const selectStyles = useSelectStyles<{ value: AgeUnit; label: string }>(themeContext);
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;

	const showAdditionalInput: boolean = useMemo(() => inputState.ageUnit === AgeUnit.Month || inputState.ageUnit === AgeUnit.Week, [inputState.ageUnit]);

	const validateAge = useCallback(
		(value: string, unit: AgeUnit) => {
			const intValue = parseInt(value, 10);
			if (isNaN(intValue)) {
				return setValidationStatus('');
			}

			const exceedsLimits = (unit === AgeUnit.Month && intValue > 24) || (unit === AgeUnit.Week && intValue > 52);
			if (exceedsLimits) {
				return setValidationStatus('maxAgeExceeded');
			}

			const calculatedAge = getCalculatedAge(value, unit, labels);
			if (!calculatedAge) {
				setAge({ age: '', ageInYears: 0, displayAge: '' });
				return setValidationStatus('');
			}

			setAge(calculatedAge);

			if (calculatedAge.ageInYears <= 0) {
				setValidationStatus('invalid');
			} else if (calculatedAge.ageInYears > settings.maxAge) {
				setValidationStatus('maxAgeExceeded');
			} else {
				setValidationStatus('valid');
			}
		},
		[labels, settings.maxAge]
	);

	const handleOnChangeYearInput = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');
		const intValue = parseInt(value, 10);

		setValidationStatus('');

		if (intValue === 0) {
			setInputState((prevState) => ({
				...prevState,
				ageUnit: AgeUnit.Month,
				yearInput: value
			}));
			setAge({ age: '0', ageInYears: 0, displayAge: '' });
		} else {
			setInputState((prevState) => ({
				...prevState,
				ageUnit: AgeUnit.Year,
				yearInput: value,
				additionalInput: ''
			}));
		}
	};

	const handleOnChangeAdditional = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');
		setInputState((prevState) => ({
			...prevState,
			additionalInput: value
		}));
	};

	const handleOnChangeAgeUnit = (selected?: AgeUnit) => {
		if (selected) {
			setInputState((prevState) => ({
				...prevState,
				ageUnit: selected
			}));
		}
	};

	useEffect(() => {
		const inputValue = inputState.ageUnit === AgeUnit.Year ? inputState.yearInput : inputState.additionalInput;
		validateAge(inputValue, inputState.ageUnit);
	}, [inputState, validateAge]);

	useEffect(() => {
		if (validationStatus === 'valid') {
			void props.onChange({ ...inputState, age, dob });
		} else {
			void props.onChange(undefined);
		}
	}, [validationStatus, inputState, age, dob]);

	const ageUnitOptions = useMemo(
		() => [
			{ value: AgeUnit.Month, label: labels.WidgetDisplayAgeMonthsUnit },
			{ value: AgeUnit.Week, label: labels.WidgetDisplayAgeWeeksUnit }
		],
		[labels]
	);

	return (
		<>
			<S.Row>
				<S.Col>
					<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}>{labels.WidgetAgeYearLabel}</S.Label>
					<S.Input
						id={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}
						disabled={props.disabled}
						autoComplete="off"
						name="year"
						maxLength={3}
						type="number"
						inputMode="numeric"
						pattern="[0-9]*"
						placeholder={labels.WidgetAgeYearShortPlaceholder}
						value={inputState.yearInput}
						onChange={handleOnChangeYearInput}
						data-testid="age-year-input"
					/>
				</S.Col>
			</S.Row>
			{showAdditionalInput && (
				<>
					<S.Row>
						<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeAdditionalInput`}>{labels.WidgetAgeDescription}</S.Label>
					</S.Row>
					<S.AdditionalInputRow>
						<S.Col $flex={!isMobile ? 3 : undefined}>
							<S.Input
								id={`MINDD-Widget-${props.step ?? 0}-AgeAdditionalInput`}
								disabled={props.disabled}
								autoComplete="off"
								name="additionalInput"
								maxLength={2}
								type="number"
								inputMode="numeric"
								pattern="[0-9]*"
								placeholder={inputState.ageUnit === AgeUnit.Month ? labels.WidgetAgeMonthPlaceholder : labels.WidgetAgeWeekPlaceholder}
								value={inputState.additionalInput}
								onChange={handleOnChangeAdditional}
								data-testid="age-additional-input"
							/>
						</S.Col>
						<S.Col>
							<Select
								value={ageUnitOptions.find((o) => o.value === inputState.ageUnit)}
								options={ageUnitOptions}
								onChange={(e) => handleOnChangeAgeUnit((e as SingleValue<{ value: AgeUnit; label: string }>)?.value)}
								isClearable={false}
								styles={selectStyles}
								id="age-additional-select"
							/>
						</S.Col>
					</S.AdditionalInputRow>
				</>
			)}

			{modal.open && (
				<ErrorMessage showError={['invalid', 'maxAgeExceeded'].includes(validationStatus)}>
					{validationStatus === 'invalid' && labels.WidgetDateOfBirthRequestError}
					{validationStatus === 'maxAgeExceeded' && labels.WidgetMaxAgeRequestError}
				</ErrorMessage>
			)}
		</>
	);
};

AgeYearInput.displayName = 'AgeYearInput';
export default AgeYearInput;

const getInitialState = (answer?: ActivityAnswerAgeSelector, profileAge?: string | null, forceInitialStateEmpty?: boolean) => {
	const shouldUseProfileAge = !forceInitialStateEmpty && !!profileAge && !answer;
	const parsedProfileAge = shouldUseProfileAge && profileAge ? parseInt(profileAge, 10) : null;

	// dob is not used in AgeYearInput but ActivityAnswerAgeSelector expects a value for dob
	let dob: DateOfBirth = { day: undefined, month: undefined, year: undefined };
	let age: CalculatedAge = { age: '', ageInYears: 0, displayAge: '' };

	let inputState: InputState = {
		yearInput: '',
		ageUnit: AgeUnit.Year,
		additionalInput: ''
	};

	// If profile age is used
	if (shouldUseProfileAge && parsedProfileAge !== null) {
		if (parsedProfileAge === 0) {
			inputState = { yearInput: '0', ageUnit: AgeUnit.Month, additionalInput: '' };
			age = { age: '0', ageInYears: 0, displayAge: '' };
		} else {
			inputState = { yearInput: parsedProfileAge.toString(), ageUnit: AgeUnit.Year, additionalInput: '' };
		}
	} else {
		// If answer is passed via props, set dob, age, and input state
		// Else set default values
		dob = answer?.dob ?? dob;
		age = answer?.age ?? age;
		inputState = {
			yearInput: answer?.yearInput ?? inputState.yearInput,
			ageUnit: answer?.ageUnit ?? inputState.ageUnit,
			additionalInput: answer?.additionalInput ?? inputState.additionalInput
		};
	}

	return { dob, age, inputState };
};

const getCalculatedAge = (value: string, ageUnit: AgeUnit, labels: AgeYearLabels): CalculatedAge | undefined => {
	const intValue = parseInt(value, 10);

	if (isNaN(intValue)) {
		return undefined;
	}

	let ageInYears: number | undefined = undefined;
	let displayAgeUnit = '';

	switch (ageUnit) {
		case AgeUnit.Year:
			ageInYears = intValue;
			displayAgeUnit = labels.WidgetDisplayAgeYearsUnit;
			break;
		case AgeUnit.Month:
			ageInYears = intValue / 12;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeMonthsUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeMonthUnit;
			}
			break;
		case AgeUnit.Week:
			ageInYears = intValue / 52;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeWeeksUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeWeekUnit;
			}
			break;
	}

	return {
		age: ageInYears.toFixed(3),
		ageInYears: ageInYears,
		displayAge: `${intValue} ${displayAgeUnit}`
	};
};
