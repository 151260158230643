import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { ConsultationPreparationQuestion } from '../../models';

export type ConsultationPreparationGetNextInput = SessionInput &
	LanguageInput & {
		questionId: number;
		answerId: number;
		answerChoices?: number[];
		answerText?: string;
	};

export type ConsultationPreparationGetNextOutput = {
	message: 'succes' | 'ConsultationPreparation';
	callbackTime?: string;
	question?: ConsultationPreparationQuestion;
};

async function CallConsultationPreparationGetNext(
	apiKey: string,
	input: ConsultationPreparationGetNextInput
): Promise<ConsultationPreparationGetNextOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<ConsultationPreparationGetNextOutput>('ConsultationPreparationGetNext?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallConsultationPreparationGetNext');
	}

	return undefined;
}

export { CallConsultationPreparationGetNext };
