import { useCallback } from 'react';

import { useStateContext } from '../useStateContext';
import { CallConsultationPreparationGetNext } from '../../services';
import type { ActivityStep, ActivityStepConsultationPreparation } from '../../../models';

export type UseNextStepConsultationPreparationResultType = (currentStep: ActivityStepConsultationPreparation) => Promise<ActivityStep>;

export const useNextStepConsultationPreparation: () => UseNextStepConsultationPreparationResultType = () => {
	const [{ session, settings }] = useStateContext();

	return useCallback(
		async (currentStep): Promise<ActivityStep> => {
			const lastQuestion = currentStep.questions.at(-1);
			if (!lastQuestion || !lastQuestion.answer) {
				return {
					step: 0,
					id: `getNext-unknown`,
					type: 'noNextStepFound'
				};
			}

			const nextQuestionResult = await CallConsultationPreparationGetNext(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				questionId: lastQuestion.question.id,
				answerId: lastQuestion.answer.answerId,
				answerChoices: lastQuestion.answer.answerChoices,
				answerText: lastQuestion.answer.answerText,
				languageCode: settings.selectedLanguage.code
			});

			if (nextQuestionResult?.question) {
				return {
					step: currentStep.step,
					id: currentStep.id,
					type: currentStep.type,
					questions: [
						...currentStep.questions,
						{
							type: 'consultationPreparationQuestion' as const,
							question: nextQuestionResult.question
						}
					],
					empathy: currentStep.empathy,
					urgence: currentStep.urgence,
					showImageField: currentStep.showImageField
				};
			} else {
				return {
					step: 0,
					id: 'consultationPreparation-requestCallback',
					type: 'requestCallback',
					urgence: currentStep.urgence,
					showImageField: currentStep.showImageField
				};
			}
		},
		[session, settings.ApiKey, settings.selectedLanguage]
	);
};
