import React from 'react';
import SessionSummaryForm from '../SessionSummaryForm/SessionSummaryForm';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { CallGetSessionSummary } from '../../helpers/services';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import S from './DownloadButton.styled';
import SA from '../ActivityAdvice/ActivityAdvice.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DownloadButton = () => {
	const [{ profile, settings, session }] = useStateContext();

	const handleSessionReportDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		CallGetSessionSummary(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			pdfType: 'Default',
			languageCode: settings.selectedLanguage.code,
			showUrgenceInPdf: false
		})
			.then((sessionSummary) => {
				if (!sessionSummary) return;

				const url = window.URL.createObjectURL(sessionSummary);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'file.pdf');
				link.download = `MINDD-Zelftriage-${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error('Error downloading session report:', error);
			});
	};

	return (
		<>
			{/* U kunt hier het hele gespreksverslag en advies downloaden. */}
			{(!settings.prefill || (settings.prefill && settings.prefill.active !== true)) && (
				<S.DownloadButton>
					{!settings.getSessionSummaryUsingForm && (
						<div>
							<SA.AdviceHeaderButton onClick={handleSessionReportDownload}>
								<FontAwesomeIcon icon={faDownload} />
								{getLabel('SessionReportDownloadLink', settings.applicationTexts, true)}
							</SA.AdviceHeaderButton>
						</div>
					)}
					{settings.getSessionSummaryUsingForm && (
						<SessionSummaryForm
							showAsButton
							fields={{ Name: profile.name ?? undefined, Dob: `${profile.dob.day ?? ''}-${profile.dob.month ?? ''}-${profile.dob.year ?? ''}` }}
							title={getLabel('SessionReportDownloadLinkTitle', settings.applicationTexts, true)}
						>
							{getLabel('SessionReportDownloadLink', settings.applicationTexts, true)}
						</SessionSummaryForm>
					)}
				</S.DownloadButton>
			)}
		</>
	);
};

export default DownloadButton;
