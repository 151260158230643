import { AxiosResponse } from 'axios';

import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput } from './BaseInput';
import type { ApplicationText } from '../../models';

export type GetApplicationTextsInput = Partial<LanguageInput>;

type GetApplicationTextsInputFull = GetApplicationTextsInput & {
	applicationType: 'web';
};

export type GetApplicationTextsOutput = {
	applicationTexts: ApplicationText[];
};

async function CallGetApplicationTexts(apiKey: string, input: GetApplicationTextsInput): Promise<GetApplicationTextsOutput | undefined> {
	try {
		const { data } = await minddApiAxios.get<GetApplicationTextsOutput, AxiosResponse<GetApplicationTextsOutput>, GetApplicationTextsInputFull>(
			`GetApplicationTexts?api-version=2.1&applicationType=web&languageCode=${input.languageCode ?? ''}`,
			{
				headers: {
					'MINDD-OC-ApiKey': apiKey
				}
			}
		);

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetApplicationTexts');
	}

	return undefined;
}

export { CallGetApplicationTexts };
