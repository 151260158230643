import { useStateContext } from './useStateContext';
import { useMemo } from 'react';

const NEVER_DISABLED = ['advice', 'requestCallbackEnd'];

export const useWidgetIsDisabled = () => {
	const [{ conversation, sectionIndex }] = useStateContext();

	const triageIsDisabled = useMemo(
		() => conversation.some((step) => ['consultationPreparation', 'requestCallback', 'survey'].includes(step.type)),
		[conversation.length]
	);
	const contactRequestIsDisabled = useMemo(() => conversation.some((step) => step.type === 'callbackRequestScheduler'), [conversation.length]);
	const schedulerIsDisabled = useMemo(() => conversation.some((step) => step.type === 'requestCallbackEnd'), [conversation.length]);
	const hasConsultationPreparation = useMemo(() => conversation.some((step) => step.type === 'consultationPreparation'), [conversation.length]);

	const sectionIsDisabled = useMemo(() => {
		if (NEVER_DISABLED.includes(conversation[sectionIndex]?.type ?? '')) {
			return false;
		}

		if (schedulerIsDisabled) {
			if (conversation[sectionIndex]?.type === 'survey' && !conversation[sectionIndex].answer) {
				return false;
			}
			return true;
		}

		if (contactRequestIsDisabled) {
			return sectionIndex <= conversation.findIndex((step) => step.type === 'requestCallback');
		}

		const pastAdvice =
			hasConsultationPreparation || conversation.some((step) => step.type === 'requestCallback') || conversation.some((step) => step.type === 'survey');

		if (!pastAdvice) {
			return false;
		}

		if (conversation[sectionIndex]?.type === 'survey' && conversation[sectionIndex].answer) {
			return true;
		}

		return (
			triageIsDisabled && sectionIndex < conversation.findIndex((step) => ['consultationPreparation', 'requestCallback', 'survey'].includes(step.type))
		);
	}, [conversation.length, sectionIndex, triageIsDisabled, contactRequestIsDisabled, schedulerIsDisabled, hasConsultationPreparation]);

	return {
		triageIsDisabled,
		contactRequestIsDisabled,
		schedulerIsDisabled,
		sectionIsDisabled
	};
};
