import React, { FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabelWithOverride } from '../../helpers/constants/getLabelWithOverride';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo/Logo';

interface ActivityWelcomeProps {
	children: React.ReactNode;
}

export const WelcomeText: FunctionComponent = () => {
	const [{ settings }] = useStateContext();
	const welcomeText = getLabelWithOverride(settings.welcome_text, 'WidgetWelcomeText', settings.applicationTexts, settings.selectedLanguage.code);

	return (
		<S.ClearActivityBubble>
			<SA.BubbleTitle>{welcomeText}</SA.BubbleTitle>
		</S.ClearActivityBubble>
	);
};

const ActivityWelcome: FunctionComponent<ActivityWelcomeProps> = ({ children: startActivity }) => {
	const [{ settings }] = useStateContext();
	const [, widgetIsSmall] = useWidgetIsNarrow();

	const showLanguageSelector = settings.showLanguageSelector && settings.useLanguageSelector;
	const showWelcomeText = settings.widget_showWelcomeText;
	const showLogo = !settings.hide_footer;

	return (
		<S.ActivityWelcome>
			{showLanguageSelector && (
				<S.LanguageSelectorWrapper $smallContainer={widgetIsSmall}>
					<LanguageSelector />
				</S.LanguageSelectorWrapper>
			)}

			<S.StartActivityWrapper $isSmallContainer={widgetIsSmall}>
				{showWelcomeText && <WelcomeText />}
				{startActivity}
			</S.StartActivityWrapper>

			<S.Footer>
				<TermsOfUse />
				{showLogo && <Logo />}
			</S.Footer>
		</S.ActivityWelcome>
	);
};

export default ActivityWelcome;
