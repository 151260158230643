import { useCallback } from 'react';

import { StateContextType, useStateContext } from '../useStateContext';
import { CallGetStartQuestion } from '../../services';
import { getLabel } from '../../constants/getLabels';
import type { ActivityStep, Gender, SearchTriageGroup } from '../../../models';

export type UseNextStepSearchTriageResultType = (
	triageGroup: SearchTriageGroup | null,
	bodyAreaId?: number | null,
	gender?: Gender | null,
	age?: string | null,
	birthdate?: Date,
	hasAskedDoctor?: boolean
) => Promise<ActivityStep>;

export const useNextStepSearchTriage: () => UseNextStepSearchTriageResultType = () => {
	const [{ session, settings }]: StateContextType = useStateContext();

	const getFirstQuestion = useCallback(
		async (
			triageId: number,
			bodyAreaId: number | null | undefined,
			gender: Gender | null | undefined,
			age: string,
			birthdate: Date | undefined
		): Promise<ActivityStep> => {
			const requestType = settings.startWithAbcdTriage ? 'AfterAbcdTriage' : 'StartTriage';

			const startQuestion = await CallGetStartQuestion(settings.ApiKey, {
				triageId: triageId,
				gender: gender,
				age: age,
				birthdate: birthdate,
				bodyAreaId: bodyAreaId,
				requestType: requestType,
				languageCode: settings.selectedLanguage.code,
				sessionId: session.id,
				sessionToken: session.token
			});

			if (startQuestion) {
				return {
					step: 0,
					id: `triage${startQuestion.startQuestion.triageType}`,
					type: `triage${startQuestion.startQuestion.triageType}`,
					questions: [
						{
							question: startQuestion.startQuestion,
							empathy: startQuestion.empathy
						}
					]
				};
			}

			return {
				step: 0,
				id: `startQuestion-${triageId}`,
				type: 'noNextStepFound'
			};
		},
		[session, settings.ApiKey, settings.selectedLanguage.code, settings.startWithAbcdTriage]
	);

	return useCallback(
		async (
			triageGroup: SearchTriageGroup | null,
			bodyAreaId?: number | null,
			gender?: Gender | null,
			age?: string | null,
			birthdate?: Date,
			hasAskedDoctor?: boolean
		): Promise<ActivityStep> => {
			if (triageGroup === null) {
				return {
					step: 0,
					id: 'searchTriageComplaintSelector',
					type: 'searchComplaintSelector',
					title: getLabel('QuestionWhatTriage', settings.applicationTexts),
					profile: 'selectedSearchTriageGroup'
				};
			}

			let triages = triageGroup.triages;
			if (triageGroup.askForBodyPart) {
				if (bodyAreaId) {
					triages = triages.filter((t) => t.bodyAreaIds.some((ba) => ba === bodyAreaId));
				} else {
					return {
						step: 0,
						id: 'searchTriageBodyAreaSelector',
						type: 'triageGroupBodyAreaSelector',
						title: getLabel('QuestionWhatBodyPart', settings.applicationTexts),
						info: getLabel('QuestionWhatBodyPartInfo', settings.applicationTexts, false, true),
						profile: 'bodypart',
						triageGroup: triageGroup
					};
				}
			}

			if (triages.length === 0) {
				return {
					step: 0,
					id: 'searchNoTriage',
					type: 'noTriageFound'
				};
			}

			if (triageGroup.askForGender) {
				if (gender) {
					const genderLowerCase = gender.toLowerCase();
					if (genderLowerCase === 'male') {
						triages = triages.filter((t) => t.male);
					} else if (genderLowerCase === 'female') {
						triages = triages.filter((t) => t.female);
					} else {
						console.log('Invalid value for gender');
						throw Error('Invalid value for gender');
					}
				} else {
					return {
						step: 0,
						id: 'searchTriageGenderSelector',
						type: 'genderSelector',
						title: getLabel('QuestionWhatGender', settings.applicationTexts),
						info: getLabel('QuestionWhatGenderInfo', settings.applicationTexts, false, true),
						profile: 'gender',
						triageGroup: triageGroup
					};
				}
			}

			if (triages.length === 0) {
				return {
					step: 0,
					id: 'searchNoTriage',
					type: 'noTriageFound'
				};
			}

			if (triageGroup.askForAge) {
				if (age) {
					const ageFloat = parseFloat(age);
					triages = triages.filter((t) => (t.ageMin <= ageFloat || t.ageMin === 0) && (t.ageMax > ageFloat || t.ageMax === 0));
				} else {
					return {
						step: 0,
						id: 'searchTriageAgeSelector',
						type: 'ageSelector',
						title: getLabel('QuestionWhatAge', settings.applicationTexts),
						info: getLabel('QuestionWhatAgeInfo', settings.applicationTexts, false, true),
						profile: 'age',
						triageGroup: triageGroup
					};
				}
			}

			if (triages.length === 0) {
				return {
					step: 0,
					id: 'searchNoTriage',
					type: 'noTriageFound'
				};
			}

			if (settings.askForGPPost && !hasAskedDoctor) {
				return {
					step: 0,
					id: 'searchTriageAskGPPost',
					type: 'askGPPost',
					triageId: triages[0].id
				};
			}

			return getFirstQuestion(triages[0].id, bodyAreaId, gender, age ?? '0', birthdate);
		},
		[settings.applicationTexts, settings.askForGPPost, getFirstQuestion]
	);
};
