import React, { FunctionComponent, useEffect, useCallback, useId, useState, useMemo } from 'react';
import { default as SA } from '../Activity/Activity.styled';
import SP from '../Procedure/Procedure.styled';
import ST from '../ActivityTriage/ActivityTriage.styled';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { PostTriageProgressInputGender } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { getOverrideLabel } from '../../helpers/constants/getOverrideLabel';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import { RadioButtonGroup } from '../Buttons/Buttons';
import type { Gender, LanguageApplicationTexts } from '../../models';
import StartButton from '../StartButton/StartButton';
import { ActivityProps } from '../Activity';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { useExecutionLock } from '../../helpers/hooks/useExecutionLock';

interface ActivityGenderProps extends ActivityProps {
	title?: string | JSX.Element;
	info?: string | JSX.Element;
	answer?: { gender: Gender } | undefined;
}

const ActivityGender: FunctionComponent<ActivityGenderProps> = ({
	handleActivityResponse: baseHandleActivityResponse,
	handleNext,
	setHandleNext,
	setDisableNext,
	...props
}) => {
	const [{ settings, profile }, dispatch] = useStateContext();
	const label: string | LanguageApplicationTexts | undefined =
		settings.labels && settings.labels.QuestionWhatGender ? settings.labels.QuestionWhatGender : undefined; // note: undefined here instead of null
	const bubbleText = getOverrideLabel(label, props.title ?? '', settings.selectedLanguage.code, false);
	const maleQuestionTitle = getLabel('GenderSelectorMaleLabel', settings.applicationTexts, true) || 'Man';
	const maleAnswerValue = 'Male';
	const femaleQuestionTitle = getLabel('GenderSelectorFemaleLabel', settings.applicationTexts, true) || 'Vrouw';
	const femaleAnswerValue = 'Female';
	const [selected, setSelected] = useState<Gender | undefined>(profile.gender ?? props.answer?.gender);
	const isMaleChecked = useMemo(() => selected === maleAnswerValue, [selected, maleAnswerValue]);
	const isFemaleChecked = useMemo(() => selected === femaleAnswerValue, [selected, femaleAnswerValue]);
	const [widgetIsNarrow] = useWidgetIsNarrow();
	const stepNumber = useCurrentStepNumber(props.id);
	const { executeWithLock, isExecuting } = useExecutionLock(setDisableNext);

	const handleActivityResponse = useCallback(async () => {
		await executeWithLock(async () => {
			if (selected) {
				const next = await baseHandleActivityResponse({ gender: selected }, { updateGender: true, gender: selected } as PostTriageProgressInputGender);

				if (next !== false) {
					dispatch({
						type: 'updateProfile/Gender',
						gender: selected
					});
					handleNext();
				} else {
					setSelected(props.answer?.gender);
				}
			} else if (!props.modalOpen) {
				// Starts session and open modal
				await baseHandleActivityResponse(undefined);
			}
		});
	}, [selected, props.answer, props.modalOpen, executeWithLock, baseHandleActivityResponse, dispatch, handleNext]);

	useEffect(() => {
		if (selected) {
			setHandleNext(() => async () => {
				await handleActivityResponse();
			});
		}
	}, [selected, setHandleNext, handleActivityResponse]);

	// Handle change if selected was loaded from profile
	useEffect(() => {
		if (profile.gender) {
			// Running the dispatch immediately doesn't work for some reason, so wait 1ms
			// TODO find a better solution
			setTimeout(() => {
				dispatch({
					type: 'conversation/setStepAnswer',
					stepId: props.id,
					answer: { gender: profile.gender as unknown as never }
				});
			}, 1);
		}
	}, []);

	const handleChange = (value: Gender) => {
		if (props.isLastActivity) {
			// Set initial answer to enable next
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: { gender: value }
			});
		}
		setSelected(value);
	};

	const nameAttr = useId();

	return (
		<SA.ActivityGroup>
			<SA.ActivityBubble $modalOpen={props.modalOpen ?? false} $widgetIsNarrow={widgetIsNarrow}>
				<ActivityBubbleTitle
					title={bubbleText}
					info={props.info}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity}
					modalOpen={props.modalOpen}
					disabled={props.disabled}
					widgetIsNarrow={widgetIsNarrow}
					stepNumber={stepNumber}
				/>
				<RadioButtonGroup modalOpen={props.modalOpen ?? false}>
					<ST.CheckBox checked={isMaleChecked}>
						<SP.Field
							type="radio"
							name={nameAttr}
							id={`gender_${maleAnswerValue}_${nameAttr}`}
							disabled={props.disabled}
							onChange={() => handleChange(maleAnswerValue)}
							checked={isMaleChecked}
						/>
						<SP.Label htmlFor={`gender_${maleAnswerValue}_${nameAttr}`}>{maleQuestionTitle}</SP.Label>
					</ST.CheckBox>
					<ST.CheckBox checked={isFemaleChecked}>
						<SP.Field
							type="radio"
							name={nameAttr}
							id={`gender_${femaleAnswerValue}_${nameAttr}`}
							disabled={props.disabled}
							onChange={() => handleChange(femaleAnswerValue)}
							checked={isFemaleChecked}
						/>
						<SP.Label htmlFor={`gender_${femaleAnswerValue}_${nameAttr}`}>{femaleQuestionTitle}</SP.Label>
					</ST.CheckBox>
				</RadioButtonGroup>
			</SA.ActivityBubble>

			{!props.modalOpen && props.isFirstActivity && <StartButton onStart={() => void handleActivityResponse()} disabled={isExecuting} />}
		</SA.ActivityGroup>
	);
};

export default ActivityGender;
