import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type SurveyPostInquiryFormInput = SessionInput & {
	email?: string;
	phoneNumber?: string;
	userAgreedToPrivacyStatement: boolean;
};

async function CallSurveyPostInquiryForm(apiKey: string, input: SurveyPostInquiryFormInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('SurveyPostInquiryForm?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallSurveyPostInquiryForm');
	}

	return false;
}

export { CallSurveyPostInquiryForm };
