import styled from 'styled-components';

const DaySelectorContainerWrapper = styled.div`
	position: relative;
`;

const ScrollButtonWrapper = styled.div`
	position: absolute;
	z-index: 100;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 100%;
	display: flex;
	align-items: center;
	button {
		background: ${(props) => props.theme.colors.accent};
		padding: 8px 15px;
	}
	button:focus {
		outline: none;
		border: none;
	}
`;

const LeftScrollButtonWrapper = styled(ScrollButtonWrapper)`
	left: 20px;
	background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;

const RightScrollButtonWrapper = styled(ScrollButtonWrapper)`
	right: -20px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;

const DaySelectorContainer = styled.div`
	display: flex;
	overflow-x: scroll;
	background: #f8f8f8;
	scrollbar-width: thin;
`;

interface DayButtonProps {
	$isSelected?: boolean;
	$isDisabled?: boolean;
}

const DayButton = styled.button<DayButtonProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	outline: none;
	&:focus {
		outline: none;
	}
	flex: 0 0 auto;
	margin: ${(props) => props.theme.spacings.small};
	padding: ${(props) => props.theme.spacings.small} ${(props) => props.theme.spacings.small};
	cursor: pointer;
	color: black;
	width: 86px;
	height: 100px;
	border: 1px solid gray; // default
	background: ${(props) => (props.$isSelected ? props.theme.colors.accentLight : 'white')};
	border-color: ${(props) => (props.$isSelected ? props.theme.colors.accent : 'gray')};
	border-width: ${(props) => (props.$isSelected ? '2px' : '1px')};

	&:disabled {
		background-color: transparent;
		cursor: not-allowed;
		opacity: 0.7;
		border: none;
	}

	.dayName,
	.monthName {
		color: #333;
		text-align: center;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-wrap: nowrap;
		overflow: hidden;
	}

	.dayOfTheMonth {
		color: ${(props) => (props.$isDisabled ? 'gray' : props.theme.colors.accent)};
		text-align: center;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 26px;
	}
`;

const S = {
	DaySelectorContainer,
	DayButton,
	DaySelectorContainerWrapper,
	LeftScrollButtonWrapper,
	RightScrollButtonWrapper
};

export default S;
