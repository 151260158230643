import styled from 'styled-components';

interface ButtonsProps {
	$sticky: boolean;
}

const Buttons = styled.div<ButtonsProps>`
	position: ${(props) => (props.$sticky ? 'sticky' : 'auto')};
	bottom: 0;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: ${(props) => props.theme.spacings.small};
	padding-bottom: ${(props) => props.theme.spacings.small};

	@media ${(props) => props.theme.screens.medium} {
		bottom: 0;
	}
`;

const Button = styled.button`
	cursor: pointer;

	padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.large}`};
	//outline: 0;
	font-weight: initial !important;
	text-transform: initial !important;
	transition: initial !important;
	font-size: initial !important;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	border-radius: ${(props) => props.theme.radii.default};

	text-align: center;
	width: 50%;
	margin-right: 0;

	&[disabled] {
		cursor: default;
	}

	// Previous button
	&:first-child {
		background-color: ${(props) => props.theme.colors.accent100};
		color: ${(props) => props.theme.colors.text700};
		border: solid 1px ${(props) => props.theme.colors.accent300};

		&:hover:not([disabled]) {
			background-color: ${(props) => props.theme.colors.accent200};
			color: ${(props) => props.theme.colors.accent800};
		}

		&[disabled] {
			background-color: ${(props) => props.theme.colors.text100};
			color: ${(props) => props.theme.colors.text700};
		}
	}

	// Next button
	&:last-child {
		background-color: ${(props) => props.theme.colors.accent};
		color: ${(props) => props.theme.colors.text100};
		border: none;

		&:hover:not([disabled]) {
			background-color: ${(props) => props.theme.colors.accent600};
			color: ${(props) => props.theme.colors.text100};
		}

		&[disabled] {
			background-color: ${(props) => props.theme.colors.text300};
			color: ${(props) => props.theme.colors.text700};
		}
	}
`;

const ButtonLoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export default {
	Buttons,
	Button,
	ButtonLoaderWrapper
};
