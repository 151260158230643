import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';

import Button from '../Button';
import React from 'react';
import type { InputProps, SelectProps } from '../../models/widget/Styled';

interface ColProps {
	$flex?: number;
}

const AgeUnitButton = styled(Button)`
	&&& {
		display: inline-block;
		margin-top: ${(props) => props.theme.spacings.small};
		text-transform: capitalize !important;
	}
`;

const Form = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: ${(props) => props.theme.spacings.medium} 0;
	@media ${(props) => props.theme.screens.extraSmall} {
		margin-top: ${(props) => props.theme.spacings.medium};
	}
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.medium};
	margin-top: ${(props) => props.theme.spacings.medium};
`;

const AdditionalInputRow = styled(Row)`
	margin-top: 0;
`;

const Col = styled.div<ColProps>`
	flex: ${(props) => (props.$flex === undefined ? 1 : props.$flex)};
	display: flex;
	flex-direction: column;
	gap: 0;
`;

const FieldSet = styled.fieldset`
	border: 0;
	&:focus-visible {
		outline: none;
	}
`;

const Legend = styled(SA.VisuallyHidden).attrs({ as: 'legend' })``;

const Label = styled.div.attrs<{ htmlFor: string }>((props) => ({ as: 'label', htmlFor: props.htmlFor }))<{ htmlFor: string }>`
	color: ${(props) => props.theme.colors.text900};
`;

const Input = styled.input`
	text-align: center;
	font-size: inherit;
	border-radius: ${(props) => props.theme.radii.default} !important;
	border: 1px solid #cccccc !important;
	padding: ${(props) =>
		`calc(0.38 * ${props.theme.spacings.medium}) ${props.theme.spacings.small}`}; // Vertical padding of 0.38rem makes the input the same height as the age unit select
	width: 100%;
	background: transparent !important;
	appearance: 'textfield';
	text-align: start;
	box-shadow: none;

	background: transparent;
	font-weight: normal;
	height: auto;

	&:focus {
		outline: solid 2px ${(props) => props.theme.colors.accent400};
	}
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		webkit-appearance: none;
	}
`;

const Select = styled.select`
	&& {
		display: block !important;
		border: 1px solid #60606a;
		background: transparent;
		padding: 8px 10px 7px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		&:focus {
			outline: 0;
			border-color: #60606a;
		}
	}
`;

const Error = styled(SP.Error)`
	width: 100%;
`;

const S = {
	AgeUnitButton,
	Form,
	Row,
	Col,
	AdditionalInputRow,
	FieldSet,
	Legend,
	Label,
	Input: React.forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => <Input {...props} ref={ref} data-minddclass="input" />),
	Select: (props: SelectProps) => (
		<Select {...props} data-minddclass="fieldselect">
			{props.children}
		</Select>
	),
	Error
};

export default S;
