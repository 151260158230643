import React, { useState, useEffect, FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import { default as SST } from '../SectionTriage/SectionTriage.styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useNextStepSurvey } from '../../helpers/hooks/nextStep/useNextStepSurvey';
import { CallSurveyStartQuestion } from '../../helpers/services';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import type { ActivityAnswerSurvey, ActivityAnswerSurveyQuestion, ActivityStepSurvey, SurveyQuestion } from '../../models';
import { default as SurveyStartQuestion } from '../SurveyQuestion/SurveyQuestion';
import { ActivityProps } from '../Activity';
import { useTheme } from 'styled-components';
import Preloader from '../Preloader/Preloader';

type SurveyIntroProps = ActivityProps & {
	surveyAnswer?: ActivityAnswerSurvey;
};

const SurveyIntro: FunctionComponent<SurveyIntroProps> = (props) => {
	const [{ settings, session, conversation }, dispatch] = useStateContext();
	const handleNextStepSurvey = useNextStepSurvey();
	const themeContext = useTheme();

	const [startQuestion, setStartQuestion] = useState<SurveyQuestion | undefined>(props.surveyAnswer?.introQuestion?.question);
	const [surveyFinished, setSurveyFinished] = useState(!!props.surveyAnswer?.surveyFinished);
	const [loading, setLoading] = useState(false);

	const handleSurveyAnswer = async (answer: ActivityAnswerSurveyQuestion) => {
		if (!startQuestion) return;

		if (JSON.stringify(props.surveyAnswer?.introQuestion?.answer) === JSON.stringify(answer)) {
			return props.handleNext();
		}

		const surveyStartQuestionAndAnswer = { question: startQuestion, answer };

		const surveyStep: ActivityStepSurvey = {
			step: 0,
			type: 'survey',
			id: `survey-${startQuestion.id}`,
			surveyIntroStepId: props.id,
			introQuestion: surveyStartQuestionAndAnswer,
			questions: []
		};

		const nextStepSurvey = await handleNextStepSurvey(surveyStep);

		const surveyAnswer: ActivityAnswerSurvey = {
			introQuestion: surveyStartQuestionAndAnswer,
			surveyFinished: nextStepSurvey.type === 'surveyEnd'
		};

		const surveyIntroLocationStep = conversation.find((c) => c.id === props.id);
		const updatedAnswer = { ...surveyIntroLocationStep?.answer, surveyAnswer };

		dispatch({
			type: 'conversation/setStepAnswer',
			stepId: props.id,
			answer: updatedAnswer
		});

		if (nextStepSurvey.type !== 'surveyEnd') {
			dispatch({
				type: 'conversation/setNextStep',
				currentStepId: props.id,
				step: nextStepSurvey
			});

			props.handleNext();
		} else {
			setSurveyFinished(true);
		}
	};

	useEffect(() => {
		const fetchStartQuestion = async () => {
			setLoading(true);
			const surveyStartQuestion = await CallSurveyStartQuestion(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				languageCode: settings.selectedLanguage.code
			});
			setLoading(false);

			if (surveyStartQuestion) {
				setStartQuestion(surveyStartQuestion.question);
			}
		};

		if (!startQuestion && !surveyFinished) {
			void fetchStartQuestion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<SA.BubbleSubTitle>{renderMarkdown(settings.survey.labels.intro)}</SA.BubbleSubTitle>

			{!surveyFinished && (
				<>
					{startQuestion && (
						<SurveyStartQuestion
							{...props}
							isLastQuestion={true}
							handleQuestionAnswer={handleSurveyAnswer}
							question={startQuestion}
							stepNumber={1}
							isFirstQuestion={true}
							answer={props.surveyAnswer?.introQuestion?.answer}
						/>
					)}
					{loading && <Preloader style={{ marginTop: themeContext.spacings.large }} />}
				</>
			)}

			{surveyFinished && (
				<SA.EmpathyBubble $style="success">
					<SST.TriageCompleteEmpathy>
						<SST.TriageCompleteCheck />
						<SA.EmpathyBubbleTitle $style="success">
							{renderMarkdown(props.surveyAnswer?.inquirySent ? settings.survey.labels.formSuccess : settings.survey.labels.success)}
						</SA.EmpathyBubbleTitle>
					</SST.TriageCompleteEmpathy>
				</SA.EmpathyBubble>
			)}
		</>
	);
};

export default SurveyIntro;
