import { Gender, Profile, Settings } from '../../models';
import { capitalizeFirstLetter } from '../support/utils';

export const initInitialProfile = (profile: Profile, settings: Settings): Profile => {
	const initialProfile = { ...profile };
	const searchParams = new URLSearchParams(document.location.search);

	const isValidAge = (value: string): boolean => {
		const age = Number(value);
		return Number.isInteger(age) && age >= 0 && age <= settings.maxAge;
	};

	searchParams.forEach((spValue, spKey) => {
		const key = spKey.toLowerCase();
		const value = decodeURIComponent(spValue).trim();

		if (key === 'externalid') {
			initialProfile.externalId = value;
		} else if (!settings.forceInitialStateEmpty) {
			switch (key) {
				case 'tel':
					initialProfile.phoneNumber = value;
					break;
				case 'gender':
					if (['male', 'female'].includes(value.toLowerCase())) {
						initialProfile.gender = capitalizeFirstLetter(value.toLowerCase()) as Gender;
					}
					break;
				case 'age':
					if (!settings.askForBirthday && isValidAge(value)) {
						initialProfile.age = value;
					}
					break;
			}
		}
	});

	if (settings.prefill?.active) {
		initialProfile.bodypart = settings.prefill.bodypart ?? initialProfile.bodypart;
		initialProfile.complaint = settings.prefill.complaint ?? initialProfile.complaint;
	}

	return initialProfile;
};
