import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import Button from '../Button';

const Comment = styled.textarea`
	display: block;
	border-radius: ${(props) => props.theme.radii.default};
	width: 100%;
	max-width: 400px;
	height: 200px;
	padding: 10px;
	margin-bottom: 1em;
	&:focus {
		outline: none;
	}
`;

const SurveyAnswerButtons = styled(SA.ActivityActions)`
	justify-content: start;
	flex-direction: column;
	margin-top: ${(props) => props.theme.spacings.extraSmall};
	margin-bottom: 0;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.spacings.extraSmall};
	padding-inline-start: 0;

	> * {
		margin: ${(props) => props.theme.spacings.small} 0;
	}
	@media ${(props) => props.theme.screens.medium} {
		margin-top: ${(props) => props.theme.spacings.smallMedium};
		flex-direction: row;
		padding-inline-start: unset;

		> * {
			margin: 0 ${(props) => props.theme.spacings.small};
			margin-bottom: ${(props) => props.theme.spacings.medium};
			i,
			em,
			b,
			strong {
				font-style: normal;
				font-weight: normal;
				white-space: nowrap; /* trick to prevent ie. phonenumber to not wrap*/
			}
		}
	}
`;

const SurveyAnswerButton = styled(Button)`
	&&& {
		margin: ${(props) => props.theme.screens.extraSmall} 0;

		@media ${(props) => props.theme.screens.medium} {
			margin: ${(props) => props.theme.spacings.extraSmall} ${(props) => props.theme.spacings.small} ${(props) => props.theme.spacings.extraSmall} 0;
		}
	}
`;

export default {
	Comment,
	SurveyAnswerButtons,
	SurveyAnswerButton
};
