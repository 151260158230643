import { useState, useEffect, MutableRefObject } from 'react';

interface WidgetContainerSize {
	width: number;
	height: number;
}

// TODO: replace useWidgetIsNarrow by this hook, and don't use narrowBreakpoint anymore (?)
const useWidgetContainerSize = (containerRef: MutableRefObject<HTMLDivElement>): WidgetContainerSize => {
	// possibly use different initialization
	const [size, setSize] = useState<WidgetContainerSize>({
		width: containerRef?.current?.clientWidth ?? window.innerWidth,
		height: containerRef?.current?.clientHeight ?? window.innerHeight
	});

	useEffect(() => {
		if (containerRef) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const { width, height } = entry.contentRect;
					setSize({ width: width, height: height });
				}
			});

			resizeObserver.observe(containerRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [containerRef]);

	return size;
};

export default useWidgetContainerSize;
