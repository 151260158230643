import React, { FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import DownloadButton from '../DownloadButton/DownloadButton';
import EndButtons from '../EndButtons/EndButtons';
import type { ActivityAnswer } from '../../models';

interface ActivityEndActionsProps {
	showFeedbackAction?: boolean;
	hideDownloadButton?: boolean;
	handleActivityResponse: (answer: ActivityAnswer) => Promise<false | undefined>;
}

const ActivityEndActions: FunctionComponent<ActivityEndActionsProps> = (props) => {
	const [{ conversation }] = useStateContext();
	const { showFeedbackAction = true } = props;
	const hideDownloadButton = props.hideDownloadButton || conversation.some((c) => c.type === 'requestCallbackEnd');

	const handleActivityEndActions = async (answer: { message: string }) => {
		switch (answer.message) {
			case 'feedback':
				await props.handleActivityResponse({ isFeedbackStarted: true });
				break;
			// no default
		}
	};

	return (
		<>
			{!hideDownloadButton && <DownloadButton />}

			<SA.LineDivider />

			<EndButtons showFeedbackAction={showFeedbackAction} handleActivityResponse={(message) => void handleActivityEndActions(message)} />
		</>
	);
};

export default ActivityEndActions;
