import React, { useState, useRef, useEffect, FunctionComponent, InputHTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import { DateTime } from 'luxon';

import { default as SA } from '../Activity/Activity.styled';

import { default as SP } from '../Procedure/Procedure.styled';
import Button from '../Button';

import { InputCheckBox, setInputFilter } from '../FormComponents/FormComponents';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostZipCode } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import { createDateForDob } from '../../helpers/support/createDateForDob';

interface ProcedurePostcodeRequestProps {
	disabled?: boolean;
}

const ProcedurePostcodeRequest: FunctionComponent<ProcedurePostcodeRequestProps> = (props) => {
	const [{ profile, settings, session }] = useStateContext();
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isSending, setIsSending] = useState(false);
	const [sendStatus, setSendStatus] = useState(false);
	const [validationStatus, setValidationStatus] = useState<boolean | null>(null);
	const [fields, setFields] = useState({
		ZipCode: '',
		UserAgreedToUsage: false,
		Dob: ''
	});
	const theme = useTheme();

	let profileDobDateTime: DateTime;
	const profileDobDate = createDateForDob(profile.dob);
	if (profileDobDate) {
		profileDobDateTime = DateTime.fromJSDate(profileDobDate, { zone: 'utc' });
	}

	useEffect(() => {
		if (settings.askForzipCodeIncludeBirthdate && profileDobDateTime)
			setFields({
				...fields,
				Dob: profileDobDateTime.toFormat('dd-MM-yyyy')
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeFormField = (field: React.ChangeEvent<HTMLInputElement>) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked
			});
		} else if (field.target.type === 'date') {
			setFields({
				...fields,
				[field.target.name]:
					field.target.value === '' || field.target.value.trim() === '' ? '' : DateTime.fromISO(field.target.value).toFormat('dd-MM-yyyy')
			});
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? '' : field.target.value
			});
		}
	};

	const handleSendData = async () => {
		setIsDisabled(true);
		setIsSending(true);

		const valid = session !== null && !!fields.ZipCode && fields.UserAgreedToUsage && (!settings.askForzipCodeIncludeBirthdate || !!fields.Dob);

		if (valid) {
			const result = await CallPostZipCode(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				zipCode: fields.ZipCode.trim(),
				birthdate: fields.Dob,
				userAgreedToUsage: fields.UserAgreedToUsage
			});

			if (result) {
				setSendStatus(true);
				setIsSending(false);
				setIsDisabled(false);
			} else {
				setValidationStatus(false);
				setIsSending(false);
				setIsDisabled(false);
			}
		} else {
			setValidationStatus(false);
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	return (
		<React.Fragment>
			{!sendStatus && session && (
				<>
					<SA.BubbleSubTitle>{getLabel('postcodeRequestLabel', settings.applicationTexts)}</SA.BubbleSubTitle>
					<SP.Form>
						<SP.Row>
							<SP.Col $size={12}>
								<InputNumber
									onChange={handleChangeFormField}
									name="ZipCode"
									id="zipcode"
									label={getLabel('postcodeFieldLabel', settings.applicationTexts)}
								></InputNumber>
							</SP.Col>
						</SP.Row>
						<SP.Row>
							<InputCheckBox
								onChange={handleChangeFormField}
								name="UserAgreedToUsage"
								id="consent"
								$label={getLabel('postcodeRequestConsent', settings.applicationTexts)}
								type="checkbox"
								checked={fields.UserAgreedToUsage}
							/>
						</SP.Row>

						<SP.RowClean>
							{validationStatus === false && <SP.Error>{getLabel('postcodeRequestError', settings.applicationTexts)}</SP.Error>}
							<SA.ActivityActions style={{ margin: 0 }}>
								<Button onClick={() => void handleSendData()} disabled={isDisabled} $state>
									{isSending
										? getLabel('WidgetSendingButton', settings.applicationTexts, true)
										: getLabel('WidgetSendButton', settings.applicationTexts, true)}
								</Button>
							</SA.ActivityActions>
						</SP.RowClean>
					</SP.Form>
				</>
			)}
			{sendStatus && <SP.Succes>{getLabel(`postcodeRequestSuccess`, settings.applicationTexts)}</SP.Succes>}
		</React.Fragment>
	);
};

export default ProcedurePostcodeRequest;

const InputNumber: FunctionComponent<InputHTMLAttributes<HTMLInputElement> & { label: string | JSX.Element }> = (props) => {
	const inputNumberField = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

	useEffect(() => {
		setInputFilter(inputNumberField, function (value: string) {
			return /^[0-9]*$/.test(value);
			//return /^[\d*(.?)\s\d]*$/.test(value); // Allow digits and '.' only, using a RegExp
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<SP.Label htmlFor={props.name}>{props.label}</SP.Label>
			<SP.Field ref={inputNumberField} type="text" pattern="[0-9]*" maxLength={4} {...props} autoComplete="off" />
		</React.Fragment>
	);
};
