import React, { useState, useEffect, useCallback, useRef, FunctionComponent } from 'react';

import { useTheme } from 'styled-components';

import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';

import { default as SA } from '../Activity/Activity.styled';
import S from './ActivityBodyArea.styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import Loader from '../Loader/Loader';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetBodyAreas, PostTriageProgressInput, PostTriageProgressInputBodyArea } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import type { ActivityAnswer } from '../../models';

interface ActivityBodyAreaProps {
	title: string | JSX.Element;
	handleActivityResponse: (answer: ActivityAnswer, triageProgress?: PostTriageProgressInput) => Promise<false | undefined>;
	triageGroup: {
		triages: { bodyAreaIds: number[] }[];
	};
	disabled: boolean;
	info?: string | JSX.Element;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	modalOpen?: boolean;
}

const ActivityBodyArea: FunctionComponent<ActivityBodyAreaProps> = (props) => {
	const [{ profile, settings }, dispatch] = useStateContext();
	const themeContext = useTheme();
	const svgRef = useRef<SVGElement>() as React.MutableRefObject<SVGElement>;

	const { handleActivityResponse } = props;

	const [bodySide, setBodySide] = useState(true);
	const [svg, setSvg] = useState<{ front?: string; back?: string }>({
		front: undefined,
		back: undefined
	});

	useEffect(() => {
		const fetchBodyareas = async () => {
			let bodyAreaIds: number[] = [];
			if (props.triageGroup !== null && props.triageGroup.triages !== null) {
				bodyAreaIds = props.triageGroup.triages.flatMap((t) => t.bodyAreaIds);
				bodyAreaIds = [...new Set(bodyAreaIds)]; // Only want unique values
			}

			const bodyAreas = await CallGetBodyAreas(settings.ApiKey, {
				gender: undefined,
				age: 0,
				bodyAreaIds: bodyAreaIds,
				languageCode: settings.selectedLanguage.code
			});

			setSvg({
				front: bodyAreas?.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Voor';
				})[0].svg,
				back: bodyAreas?.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Achter';
				})[0].svg
			});

			ReactTooltip.rebuild();
		};
		void fetchBodyareas();
	}, [props.triageGroup, settings.ApiKey, settings.selectedLanguage.code]);

	const processBodyAreaSelect = useCallback(
		async (bodyAreaElement: Element, svgElement: Element) => {
			const bodyAreaIdString = bodyAreaElement.getAttribute('data-bodyareaid') || null;
			if (!bodyAreaIdString /* || bodyAreaElement.length === 0*/) {
				return;
			}

			const bodyAreaId = parseInt(bodyAreaIdString);
			if (bodyAreaId) {
				try {
					svgElement.querySelector('[data-selected]')?.removeAttribute('data-selected');
					const ariaPressedelements = svgElement.querySelectorAll('[aria-pressed="true"]');
					ariaPressedelements.forEach((el) => el.setAttribute('aria-pressed', 'false'));
				} catch (e) {
					// We ignore the error
				}

				bodyAreaElement.setAttribute('data-selected', 'true');
				bodyAreaElement.setAttribute('aria-pressed', 'true');

				await handleActivityResponse({ bodyAreaId: bodyAreaId }, { updateBodyArea: true, bodyAreaId: bodyAreaId } as PostTriageProgressInputBodyArea);

				dispatch({
					type: 'updateProfile',
					profile: {
						...profile,
						bodypart: bodyAreaId
					}
				});
			}
		},
		[dispatch, handleActivityResponse, profile]
	);

	// When control enabled make sure the tabindex on the SVG is 0, else -1 to disable tabbing
	// When control enabled attach a document event listener to keydown so we can select the body area using keyboard
	// Also use event listener on keydown to hide the tooltip on esc
	useEffect(() => {
		if (svgRef.current) {
			const tabIndexElements = svgRef.current.querySelectorAll('svg [tabindex]');
			if (tabIndexElements && tabIndexElements.length > 0) {
				const tabIndex = props.disabled ? '-1' : '0';
				tabIndexElements.forEach((el) => el.setAttribute('tabindex', tabIndex));
			}
		}

		if (!props.disabled) {
			const documentKeyDown = async (event: KeyboardEvent) => {
				if (event.key === 'Escape' || event.key === 'Esc') {
					ReactTooltip.hide();
				} else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
					const bodyAreaElement = (event.target as Element)?.closest('[data-bodyareaid]');
					const svgElement = (event.target as Element)?.closest('svg');
					if (bodyAreaElement && svgElement) {
						await processBodyAreaSelect(bodyAreaElement, svgElement);

						event.preventDefault();
					}
				}
			};

			document.addEventListener('keydown', (e) => void documentKeyDown(e));

			return () => {
				document.removeEventListener('keydown', (e) => void documentKeyDown(e));
			};
		}
	}, [props.disabled, processBodyAreaSelect]);

	const handleBodyAreaSelect = async (event: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>) => {
		event.preventDefault();

		const bodyAreaElement = (event.target as HTMLElement).closest('[data-bodyareaid]');
		const svgElement = (event.target as HTMLElement).closest('svg');
		if (bodyAreaElement && svgElement) {
			await processBodyAreaSelect(bodyAreaElement, event.currentTarget);
		}
	};

	const handleBodySideChange = () => {
		setBodySide(!bodySide);
	};

	const preProcesSvg = (code: string) => {
		code = code.replace(/fill="#00b2ff"/g, `fill="${themeContext.colors.text900}"`);
		code = code.replace(/data-bodyareaid="(\d+)"/gm, '$& tabindex="0" role="button" aria-pressed="false"');
		code = code.replace(/data-tip="(.*?)"/gm, '$& aria-label="$1"');
		return code;
	};

	return (
		<SA.ActivityBubble>
			<ActivityBubbleTitle
				title={props.title}
				info={props.info}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
			/>

			<S.ActivityBodyAreaWrapper>
				<S.Switch>
					<span
						className={bodySide ? 'active' : ''}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onClick={bodySide ? () => {} : handleBodySideChange}
						title={getLabel('BodySwitchFrontLabel', settings.applicationTexts, true)}
					>
						{getLabel('BodySwitchFrontLabel', settings.applicationTexts, true)}
					</span>
					<span
						className={!bodySide ? 'active' : ''}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onClick={!bodySide ? () => {} : handleBodySideChange}
						title={getLabel('BodySwitchBackLabel', settings.applicationTexts, true)}
					>
						{getLabel('BodySwitchBackLabel', settings.applicationTexts, true)}
					</span>
				</S.Switch>

				<S.Body disabled={props.disabled}>
					{svg.front && svg.back ? (
						<SVG
							innerRef={svgRef}
							onError={(error) => console.log(error.message)}
							onLoad={ReactTooltip.rebuild}
							preProcessor={preProcesSvg}
							src={bodySide === true ? svg.front : svg.back}
							onClick={(e) => void handleBodyAreaSelect(e)}
							onTouchEnd={(e) => void handleBodyAreaSelect(e)}
						/>
					) : (
						<S.SvgLoader>
							<Loader />
						</S.SvgLoader>
					)}
				</S.Body>

				{!props.disabled && <ReactTooltip globalEventOff="click" disableInternalStyle={true} />}
			</S.ActivityBodyAreaWrapper>
		</SA.ActivityBubble>
	);
};

export default ActivityBodyArea;
