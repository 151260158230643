import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SectionTriage = styled.div`
	&&& {
		padding: 0;
		margin: 0;
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-y: auto;
	}
`;

const TriageCompleteEmpathy = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;
`;

const TriageCompleteCheck = styled(FontAwesomeIcon)`
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.success};
	width: 15px;
	height: 15px;
	padding: 2px;
	color: ${(props) => props.theme.colors.noticeSuccess};
`;

const S = {
	SectionTriage,
	TriageCompleteEmpathy,
	TriageCompleteCheck: () => <TriageCompleteCheck icon={faCheck} size="2xs" />
};

export default S;
