import styled, { keyframes } from 'styled-components';
import type { ThemeType } from '../../models';

const animated = (theme: ThemeType) => keyframes`
	0% {
		background-color: ${theme.colors.accent100};
	}
	16% {
		background-color: ${theme.colors.accent};
	}
	33% {
		background-color: ${theme.colors.accent};
	}
	50% {
		background-color: ${theme.colors.accent100};
	}
	100% {
		background-color: ${theme.colors.accent100};
	}
`;

const S = {
	Wrapper: styled.div`
		display: flex;
		flex-direction: row;
		gap: ${(props) => props.theme.spacings.extraSmall};
	`,
	Bubble: styled.div`
		width: 8px;
		height: 8px;
		border-radius: ${(props) => props.theme.radii.default};
		background-color: ${(props) => props.theme.colors.accent100};
		animation: ${(propr) => animated(propr.theme)} 1.6s linear infinite;

		&:nth-child(1) {
			animation-delay: 0ms;
		}
		&:nth-child(2) {
			animation-delay: 400ms;
		}
		&:nth-child(3) {
			animation-delay: 800ms;
		}
		&:nth-child(4) {
			animation-delay: 1200ms;
		}
	`,
};

export default S;
