import React from 'react';
import styled from 'styled-components';
import type { DivProps } from '../../models/widget/Styled';

interface EndButtonsContainerProps {
	$justifyContent: 'space-between' | 'flex-end';
}

const EndButtonsContainer = styled.div<EndButtonsContainerProps>`
	display: flex;
	justify-content: ${(props) => props.$justifyContent};
`;

const S = {
	EndButtonsContainer: (props: DivProps<EndButtonsContainerProps>) => (
		<EndButtonsContainer {...props} data-minddclass="endbuttonscontainer">
			{props.children}
		</EndButtonsContainer>
	)
};

export default S;
