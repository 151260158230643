import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type PostSearchTriageProgressInput = SessionInput & {
	hitChoosen?: string;
	triageChoosen?: string;
	askForBodyPart: boolean;
	askForGender: boolean;
	askForAge: boolean;
};

async function CallPostSearchTriageProgress(apiKey: string, input: PostSearchTriageProgressInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('PostSearchTriageProgress?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostSearchTriageProgress');
	}

	return false;
}

export { CallPostSearchTriageProgress };
