import React, { FunctionComponent, useCallback } from 'react';

import { default as SA } from '../Activity/Activity.styled';

import ActivityEndActions from '../ActivityEndActions/ActivityEndActions';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { ActivityProps } from '../Activity';
import type { ActivityAnswerSurveyEnd } from '../../models';

interface SurveyEndProps extends ActivityProps {
	answer?: ActivityAnswerSurveyEnd;
}

const SurveyEnd: FunctionComponent<SurveyEndProps> = (props) => {
	const [{ settings }] = useStateContext();

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityEndActions = () => {
		return props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
	};

	return (
		<SA.ActivityBubble ref={focusLastActivity} tabIndex={-1}>
			<SA.BubbleTitle>{renderMarkdown(settings.survey.labels.success)}</SA.BubbleTitle>

			<ActivityEndActions
				showFeedbackAction={false}
				hideDownloadButton={settings.downloadConversationDisabled}
				handleActivityResponse={handleActivityEndActions}
			/>
		</SA.ActivityBubble>
	);
};

export default SurveyEnd;
