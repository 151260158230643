import React from 'react';
import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import InternalLanguageSelector from '../LanguageSelector';
import type { DivProps } from '../../models/widget/Styled';

const LanguageSelector = styled(InternalLanguageSelector)`
	position: absolute;
	inset-inline-end: 15px;

	@media ${(props) => props.theme.screens.medium} {
		inset-inline-end: 30px;
	}
`;

const BubbleTitle = styled(SA.BubbleTitle)`
	${LanguageSelector} + & {
		width: calc(100% - 80px);
		min-height: 64px;
	}
`;

const ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

const Form = styled(SP.Form)`
	margin: ${(props) => props.theme.spacings.medium} 0;
`;

const TriageSearchContainer = styled.div`
	position: relative;

	${SP.FieldRound} {
		padding-left: 29px;

		&hover {
			box-shadow: 0 1px 6px ${(props) => props.theme.colors.accent};
		}
	}

	svg {
		position: absolute;
		top: 6px;
		left: 5px;
		width: 24px;
		height: 24px;
	}
`;

const Complaints = styled.div``;

const ComplaintsTitle = styled.div`
	width: calc(100% - ${(props) => `calc(0.35 * ${props.theme.spacings.medium})`}); // simplify?
	color: ${(props) => props.theme.colors.text};
	text-transform: uppercase;
	display: none;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	&:after {
		content: ' ';
		display: inline-block;
		height: 1px;
		background-color: ${(props) => props.theme.colors.text};
		width: 100%;
		margin-inline-start: 7px;
	}
	@media ${(props) => props.theme.screens.medium} {
		display: flex;
	}
`;

const ComplaintsEmpty = styled.div`
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	font-style: italic;
`;

const ComplaintsList = styled(SA.ComplaintsList)``;

const Complaint = styled(SA.Complaint)``;

const ComplaintButton = styled(SA.ComplaintButton)``;

export default {
	LanguageSelector,
	BubbleTitle,
	ActivityBubble,
	Form,
	TriageSearchContainer,
	Complaints: (props: DivProps) => (
		<Complaints {...props} data-minddclass="complaints">
			{props.children}
		</Complaints>
	),
	ComplaintsTitle,
	ComplaintsEmpty,
	ComplaintsList,
	Complaint,
	ComplaintButton
};
