import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import { default as SSQ } from '../SurveyQuestion/SurveyQuestion.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import Button from '../Button';
import LanguageSelector from '../LanguageSelector';

interface CheckBoxProps {
	checked?: boolean;
	disabled?: boolean;
}

const S = {
	LanguageSelector: styled(LanguageSelector)`
		position: absolute;
		inset-inline-end: 15px;

		@media ${(props) => props.theme.screens.medium} {
			inset-inline-end: 30px;
		}
	`,
	ActivityBubble: styled(SA.ActivityBubble)`
		position: relative;
	`,
	TriageAnswerButtons: styled(SSQ.SurveyAnswerButtons)``,
	Button: styled(Button)`
		&&& {
			margin: ${(props) => props.theme.spacings.extraSmall} 0 ${(props) => props.theme.spacings.extraSmall} 0;

			@media ${(props) => props.theme.screens.medium} {
				margin: ${(props) => props.theme.spacings.extraSmall} ${(props) => props.theme.spacings.medium} ${(props) => props.theme.spacings.extraSmall} 0;
			}
		}
	`,
	CheckBox: styled(SP.RowCheckbox)<CheckBoxProps>`
		border: solid ${(props) => (props.checked ? '2' : '1')}px ${(props) => (props.checked ? props.theme.colors.accent : '#0000001A')};
		padding: 0;
		border-radius: ${(props) => props.theme.radii.default};
		background-color: ${(props) => (props.checked ? props.theme.colors.accent100 : '')};
		position: relative;
		margin: 0;

		&:has(*:focus) {
			outline: solid 1px ${(props) => props.theme.colors.accent};
		}

		&:not(:first-child) {
			padding: 0;
		}

		& input[type='checkbox'] {
			// Hide browser checkbox
			z-index: -1000;
			position: absolute;
			width: 0;
			height: 0;

			& + label {
				margin-left: ${(props) => (props.checked ? '-1px' : '0')};
				padding: ${(props) => (props.checked ? `calc(${props.theme.spacings.small} - 1px)` : props.theme.spacings.small)};
				padding-left: ${(props) => props.theme.spacings.extraExtraLarge};

				&::before {
					// Custom checkbox
					content: '${(props) => (props.checked ? '\\2713' : '')}';
					font-size: 11px;
					line-height: 11px;
					width: ${(props) => (props.checked ? '16' : '17')}px;
					height: ${(props) => (props.checked ? '16' : '17')}px;
					border: solid 2px ${(props) => (props.checked ? 'black' : props.theme.colors.text400)};
					border-radius: ${(props) => props.theme.radii.default};
					background-color: ${(props) => (props.checked ? 'black' : 'white')};
					color: #fff;
					padding: 1.5px 2.5px;
					position: absolute;
					left: ${(props) => props.theme.spacings.small};
					top: 11px;
				}
			}
		}

		& input[disabled] ~ label {
			cursor: default;
		}

		& label {
			font-weight: 400;
			margin-bottom: 0;
			cursor: pointer;
		}

		& input[type='radio'] {
			z-index: -1000;
			position: absolute;
			width: 0;
			height: 0;

			& + label {
				margin-left: ${(props) => (props.checked ? '-1px' : '0')};
				padding: ${(props) => (props.checked ? `calc(${props.theme.spacings.small} - 1px)` : props.theme.spacings.small)};
				padding-left: ${(props) => props.theme.spacings.extraExtraLarge};

				&::before {
					// Custom radio button
					content: '';
					display: inline-block;
					border: solid ${(props) => (props.checked ? '5' : '3')}px #333333;
					border-radius: 10px;
					width: ${(props) => (props.checked ? '19' : '20')}px;
					height: ${(props) => (props.checked ? '19' : '20')}px;
					position: absolute;
					left: ${(props) => props.theme.spacings.smallMedium};
					top: 10px;
					padding: 1px;
				}
			}
		}
	`
};

export default S;
