import React, { FunctionComponent, useState } from 'react';

import S from './ProcedureSendEmailOrSmsAdvice.styled';
import SA from '../Activity/Activity.styled';
import SP from '../Procedure/Procedure.styled';
import { InputText } from '../FormComponents/FormComponents';
import Button from '../Button';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMessage } from '@fortawesome/free-regular-svg-icons';
import { CallPostSendEmailSmsAdvice } from '../../helpers/services';

const matchPhoneNumber = /^(?:\+31|0)(?:\d\s?){9,10}$/;
const matchEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ProcedureSendEmailOrSmsAdvice: FunctionComponent = () => {
	const [{ session, settings }] = useStateContext();
	const [isEmail, setIsEmail] = useState(true);
	const [inputValue, setInputValue] = useState('');
	const [valid, setValid] = useState(true);
	const [isSending, setIsSending] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const changeType = (newType: boolean) => () => {
		if (newType !== isEmail) {
			setInputValue('');
			setError(false);
			setSuccess(false);
			setValid(true);
		}
		setIsEmail(newType);
	};

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleSendData = async () => {
		setError(false);
		setSuccess(false);

		if ((isEmail && !inputValue.match(matchEmail)) || (!isEmail && !inputValue.match(matchPhoneNumber))) {
			setValid(false);
			return;
		}
		setValid(true);
		setIsSending(true);

		const result = await CallPostSendEmailSmsAdvice(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			languageCode: settings.selectedLanguage.code,
			...(isEmail ? { email: inputValue } : { phone: inputValue })
		});
		setIsSending(false);

		if (result && 'message' in result && result.message === 'success') {
			setSuccess(true);
		} else {
			setError(true);
		}
	};

	return (
		<S.Wrapper>
			<SA.ChipsWrapper style={{ marginTop: 0 }}>
				<SA.Chip $square onClick={changeType(true)} $active={isEmail}>
					<FontAwesomeIcon icon={faEnvelope} />
					<span>{getLabel('ProcedureSendEmailOrSmsAdviceShareViaEmail', settings.applicationTexts)}</span>
				</SA.Chip>
				<SA.Chip $square onClick={changeType(false)} $active={!isEmail}>
					<FontAwesomeIcon icon={faMessage} />
					<span>{getLabel('ProcedureSendEmailOrSmsAdviceShareViaSms', settings.applicationTexts)}</span>
				</SA.Chip>
			</SA.ChipsWrapper>

			<div>
				<SP.Row $fullWidth style={{ position: 'relative' }}>
					<InputText
						onChange={handleFormChange}
						name="input"
						$label={<S.InputLabel>{getLabel(isEmail ? 'WidgetFormLabelEmail' : 'WidgetFormLabelTel', settings.applicationTexts)}</S.InputLabel>}
						$valid={valid}
						value={inputValue}
						$controlled
						required
						autoComplete="off"
						type={isEmail ? 'email' : 'tel'}
					/>
				</SP.Row>
			</div>

			{(error || success) && (
				<S.Feedback className={error ? 'error' : 'success'}>
					{getLabel(error ? 'ProcedureSendEmailOrSmsAdviceError' : 'ProcedureSendEmailOrSmsAdviceSuccess', settings.applicationTexts)}
				</S.Feedback>
			)}

			<div>
				<SP.Row $fullWidth>
					<SA.ActivityActions>
						<Button onClick={() => void handleSendData()} $state disabled={isSending}>
							{isSending
								? getLabel('WidgetSendingButton', settings.applicationTexts, true)
								: getLabel('WidgetSendButton', settings.applicationTexts, true)}
						</Button>
					</SA.ActivityActions>
				</SP.Row>
			</div>
		</S.Wrapper>
	);
};

export default ProcedureSendEmailOrSmsAdvice;
