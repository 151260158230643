import styled from 'styled-components';

const Container = styled.div`
	width: 100%;

	@media ${(props) => props.theme.screens.medium} {
		width: auto;
	}

	.language-selector__control--is-focused {
		outline: solid 1px ${(props) => props.theme.colors.accent} !important;
	}

	.language-selector__control {
		width: 100%;
		@media ${(props) => props.theme.screens.medium} {
			width: auto;
		}
	}

	.language-selector__option--is-focused,
	.language-selector__option:hover {
		background: ${(props) => props.theme.colors.accent200};
	}

	// added to reduce height of language selector at hbdr.nl
	.language-selector__control input {
		padding: 0 !important;
	}
`;

const Option = styled.div`
	display: flex;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;
`;

export default {
	Container,
	Option
};
