import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { default as SA } from '../Activity/Activity.styled';

import AgeYearInput from './AgeYearInput';
import BirthdayInput from './BirthdayInput';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { PostTriageProgressInputAge } from '../../helpers/services/CallPostTriageProgress';
import { createDateForDob } from '../../helpers/support/createDateForDob';
import { getLabel } from '../../helpers/constants/getLabels';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import type { ActivityAnswerAgeSelector, ActivityStepAgeSelector } from '../../models';
import { ActivityProps } from '../Activity';
import StartButton from '../StartButton/StartButton';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { useTheme } from 'styled-components';
import { useExecutionLock } from '../../helpers/hooks/useExecutionLock';

interface ActivityAgeProps extends ActivityProps {
	currentStep: ActivityStepAgeSelector;
	answer?: ActivityAnswerAgeSelector;
	title: string | JSX.Element;
	info?: string | JSX.Element;
	step?: number;
}

const ActivityAge: FunctionComponent<ActivityAgeProps> = ({
	handleActivityResponse: baseHandleActivityResponse,
	handleNext,
	setHandleNext,
	setDisableNext,
	...props
}) => {
	const [{ settings }, dispatch] = useStateContext();
	const [answer, setAnswer] = useState<ActivityAnswerAgeSelector>();
	const [resetKey, setResetKey] = useState(0);
	const stepNumber = useCurrentStepNumber(props.currentStep.id);
	const theme = useTheme();
	const [, widgetIsSmall] = useWidgetIsNarrow();
	const { executeWithLock, isExecuting } = useExecutionLock(setDisableNext);

	const confirmButtonClassName = props.modalOpen ? null : 'mindd-widget-startbutton';

	const handleActivityResponse = useCallback(async () => {
		await executeWithLock(async () => {
			if (answer) {
				const triageProgress = {
					updateAge: true,
					age: parseInt(answer.age.age, 10),
					birthdate: createDateForDob(answer.dob) as Date
				} as PostTriageProgressInputAge;

				const next = await baseHandleActivityResponse(answer, triageProgress);

				if (next !== false) {
					dispatch({
						type: 'updateProfile/Age',
						dob: answer.dob,
						age: answer.age.age
					});
					handleNext();
				} else {
					// Answer change canceled by user
					// Force rerender of Age/DobInput to reset the input to props.answer
					setResetKey((prev) => prev + 1);
				}
			} else if (!props.modalOpen) {
				// Start session and open modal
				await baseHandleActivityResponse(undefined);
			}
		});
	}, [answer, props.modalOpen, dispatch, executeWithLock, baseHandleActivityResponse, handleNext]);

	useEffect(() => {
		if (answer) {
			setHandleNext(() => async () => {
				await handleActivityResponse();
			});
		}
	}, [answer, setHandleNext, handleActivityResponse]);

	const handleAnswerChange = (newAnswer: ActivityAnswerAgeSelector) => {
		// Only proceed if the new answer is different from the current one
		if (JSON.stringify(newAnswer) !== JSON.stringify(answer)) {
			if (props.isLastActivity) {
				// Set initial answer to enable next
				dispatch({
					type: 'conversation/setStepAnswer',
					stepId: props.currentStep.id,
					answer: newAnswer
				});
			}
			// Set next disabled when answer is invalid, only valid answers have a value
			setDisableNext(!newAnswer);
			setAnswer(newAnswer);
		}
	};

	const [widgetIsNarrow] = useWidgetIsNarrow();

	return (
		<SA.ActivityGroup>
			<SA.ActivityBubble $modalOpen={props.modalOpen ?? false}>
				<ActivityBubbleTitle
					title={settings.askForBirthday ? props.title : getLabel('QuestionWhatAgeInYears', settings.applicationTexts, true)}
					info={settings.askForBirthday ? props.info : getLabel('QuestionWhatAgeInYearsInfo', settings.applicationTexts, true, true)}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity}
					modalOpen={props.modalOpen}
					disabled={props.disabled}
					limitHeight={!props.modalOpen}
					titleAriaHidden={true}
					widgetIsNarrow={widgetIsNarrow}
					stepNumber={stepNumber}
				/>
				{settings.askForBirthday ? (
					<BirthdayInput
						key={resetKey}
						confirmButtonClassName={confirmButtonClassName ?? ''}
						disabled={props.disabled}
						step={props.step}
						title={props.title}
						answer={props.answer}
						onChange={(a) => handleAnswerChange(a as ActivityAnswerAgeSelector)}
					/>
				) : (
					<AgeYearInput
						key={resetKey}
						confirmButtonClassName={confirmButtonClassName ?? ''}
						disabled={props.disabled}
						step={props.step}
						title={props.title}
						answer={props.answer}
						onChange={(a) => handleAnswerChange(a as ActivityAnswerAgeSelector)}
					/>
				)}
			</SA.ActivityBubble>

			{!props.modalOpen && props.isFirstActivity && <StartButton onStart={() => void handleActivityResponse()} disabled={isExecuting} />}
		</SA.ActivityGroup>
	);
};

export default ActivityAge;
