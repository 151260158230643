import styled from 'styled-components';

const ErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${(props) => `${props.theme.spacings.small}`};
	font-size: 14px;
	line-height: 21px;
	color: ${(props) => `${props.theme.colors.error}`};
	margin: ${(props) => `${props.theme.spacings.small} 0 `};
`;

const Placeholder = styled.div`
	height: ${(props) => `${props.theme.spacings.small}`};

	@media ${(props) => props.theme.screens.medium} {
		height: 21px;
	}
`;

const S = {
	ErrorMessage,
	Placeholder
};

export default S;
