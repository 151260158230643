import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { Gender, Question } from '../../models';

type StartQuestionType = 'StartTriage' | 'TriageRedirect' | 'AfterAbcdTriage';

export type GetStartQuestionInput = SessionInput &
	LanguageInput & {
		triageId: number;
		gender?: Gender | null;
		age: number | string; // Endpoint converts string to number
		birthdate?: Date;
		bodyAreaId?: number | null;
		requestType: StartQuestionType;
	};

export type GetStartQuestionOutput = {
	startQuestion: Question;
	empathy?: string;
};

async function CallGetStartQuestion(apiKey: string, input: GetStartQuestionInput): Promise<GetStartQuestionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<GetStartQuestionOutput>('GetStartQuestion?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetStartQuestion');
	}

	return undefined;
}

export { CallGetStartQuestion };
