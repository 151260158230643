import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';

const ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

const ActivityComplaintWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${(props) => props.theme.spacings.medium};
`;

const SvgLoader = styled.div`
	/* max-width: 250px; */

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ComplaintsTitle = styled.h3`
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: 600;
	line-height: 1.5;
	width: 100%;
	color: ${(props) => props.theme.colors.text};
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	margin-bottom: 0;
`;

const Complaints = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.medium};
	margin-top: ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.small} {
		margin-top: 0;
	}
`;

const SearchField = styled.div`
	position: relative;
	width: 100%;

	& input {
		border-color: transparent !important;
		background-color: ${(props) => props.theme.colors.accent100} !important;

		&::placeholder {
			color: ${(props) => props.theme.colors.text900};
		}

		&:focus {
			outline: solid 2px ${(props) => props.theme.colors.accent} !important;
		}
	}
`;

const SearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: ${(props) => props.theme.spacings.medium};
	top: ${(props) => props.theme.spacings.small};
	height: 50%;
`;

const ComplaintsEmpty = styled.div`
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	font-style: italic;
`;

const ComplaintsList = styled(SA.ComplaintsList)`
	position: relative;
`;

const Complaint = styled(SA.Complaint)``;

const ComplaintButton = styled(SA.ComplaintButton)``;
const NewComplaintButton = styled(SA.NewComplaintButton)``;

const TriageHighlight = styled.strong`
	color: ${(props) => props.theme.colors.text800};
`;

export default {
	ActivityBubble,
	ActivityComplaintWrapper,
	SvgLoader,
	ComplaintsTitle,
	Complaints,
	ComplaintsEmpty,
	ComplaintsList,
	Complaint,
	SearchField,
	SearchIcon,
	ComplaintButton,
	NewComplaintButton,
	TriageHighlight
};
