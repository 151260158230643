import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { ReactComponent as MinddLogo } from '../../assets/mindd-logo.svg';

interface LogoElementProps {
	$height: number;
}

const LogoElement = styled.div<LogoElementProps>`
	display: flex;
	align-items: center;
	height: ${(props) => `${props.$height}px`};

	svg {
		vertical-align: top;
		height: ${(props) => `${props.$height}px`};
		width: auto;
	}
`;

const Link = styled.a`
	&&& {
		background-color: transparent;
		margin: 0;
		border: 0;
		padding: 0;

		:focus-visible {
			outline: 2px auto ${(props) => props.theme.colors.focus};
		}
	}
`;

interface LogoProps {
	size?: number;
}

const Logo: FunctionComponent<LogoProps> = (props) => {
	const [{ settings }] = useStateContext();
	const [linkTitle, setLinkTitle] = useState('');

	useEffect(() => {
		const tmpLinkTitle = getLabel('WidgetLogoLinkLabel', settings.applicationTexts, true);
		setLinkTitle(tmpLinkTitle);
	}, [settings.applicationTexts]);

	return (
		<LogoElement $height={props.size || 24} data-minddclass="minddlogo">
			<Link
				href="https://www.moetiknaardedokter.nl/?utm_source=widget&utm_medium=referral&utm_campaign=logo"
				target="_blank"
				rel="noopener noreferrer"
				title={linkTitle}
			>
				<MinddLogo />
			</Link>
		</LogoElement>
	);
};

export default Logo;
