import React, { FunctionComponent, ReactNode } from 'react';
import S from './ErrorMessage.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

interface ErrorMessageProps {
	showError: boolean;
	children: ReactNode;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ showError, children }) => {
	return (
		<S.ErrorMessage>
			{showError ? (
				<>
					<FontAwesomeIcon icon={faCircleInfo} />
					{children}
				</>
			) : (
				<S.Placeholder />
			)}
		</S.ErrorMessage>
	);
};

export default ErrorMessage;
