import styled from 'styled-components';
import { default as SABA } from '../SectionBodyArea/SectionBodyArea.styled';

const ActivityBodyAreaWrapper = styled(SABA.ActivityComplaintWrapper)``;

const Body = styled(SABA.Body)``;

const SvgLoader = styled(SABA.SvgLoader)``;

const Switch = styled(SABA.Switch)``;

export default {
	ActivityBodyAreaWrapper,
	Body,
	SvgLoader,
	Switch
};
