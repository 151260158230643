import React, { useState, useEffect, FunctionComponent, useCallback, useId, useMemo } from 'react';
import { DateTime } from 'luxon';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import {
	InputText,
	InputDate,
	InputNumber,
	InputFile,
	InputGender,
	InputHapLocation,
	InputCallbackLocation,
	InputCheckBox,
	InputZipCode,
	TextArea,
	InputPhone,
	parseInputPhoneNumber
} from '../FormComponents/FormComponents';

import ActivityEndActions from '../ActivityEndActions/ActivityEndActions';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallBackEmailOutput, CallPostCallBackEmail, CallPostSessionLogProperties } from '../../helpers/services';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { getLabel } from '../../helpers/constants/getLabels';
import { createDateForDob } from '../../helpers/support/createDateForDob';
import { GetCallbackTimeSlots } from '../../helpers/services/CallGetCallbackTimeslots';
/* eslint-disable @typescript-eslint/no-unused-vars -- We import the various hard-coded schedules we can use for testing */
import {
	scheduleNotRounded,
	scheduleToday,
	scheduleOneDay,
	scheduleTwoDays,
	scheduleWithGaps,
	scheduleNoSchedule,
	scheduleWithManyTimeSlots,
	scheduleWithManyDays
} from '../CallbackRequestScheduler/schedules';
/* eslint-enable */
import type { ActivityAnswerRequestCallback, Gender } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { calculateAgeFromDob } from '../../helpers/support/calculateAgeFromDob';
import { ActivityProps } from '../Activity';
import { useTheme } from 'styled-components';
import { useExecutionLock } from '../../helpers/hooks/useExecutionLock';

const scheduleUseApi = process.env.REACT_APP_PROCEDURECONTACTREQUEST_SCHEDULE_USE_API !== 'false'; // note: used for development to have more control over the schedule
const developmentSchedule = scheduleNotRounded;

export function validateEmail(value: string | null, mandatory: boolean) {
	if (value === null || value.length === 0) {
		if (mandatory) {
			return false;
		} else {
			return true;
		}
	}

	const emailParts = value.split('@', 3);
	if (emailParts.length !== 2) {
		return false;
	}

	const [localPart, domainPart] = emailParts;

	if (localPart.length > 0) {
		if (
			domainPart.length > 0 &&
			domainPart.startsWith('.') === false &&
			domainPart.endsWith('.') === false &&
			domainPart.indexOf('..') === -1 &&
			domainPart.includes('.')
		) {
			return true;
		}
	}

	return false;
}

// prettier-ignore
const validateBsn = (bsn?: string) => {
	if (!bsn) return false;
	if (bsn.length < 8 || bsn.length > 9) return false;

	const checkBsn = bsn.length === 8
		? `0${bsn}`
		: bsn;
	const bsnSum = Array.from(checkBsn).reduce((total, current, index) => (
		total + (
			parseInt(current, 10)
			* (index === 8 ? -1 : 9 - index)
		)
	), 0);

	return bsnSum % 11 === 0;
};

function checkValidFields(validFields: ValidFormFields, session: unknown) {
	let valid = session !== null;
	valid = valid && !!validFields.Name;
	valid = valid && !!validFields.GivenName;
	valid = valid && !!validFields.Surname;
	valid = valid && !!validFields.Dob;
	valid = valid && !!validFields.Gender;
	valid = valid && !!validFields.Tel;
	valid = valid && !!validFields.Bsn;
	valid = valid && !!validFields.Email;
	valid = valid && !!validFields.EmailOrBsn;
	valid = valid && !!validFields.Address;
	valid = valid && !!validFields.HouseNumber;
	valid = valid && !!validFields.ZipCode;
	valid = valid && !!validFields.Doctor;
	valid = valid && !!validFields.HapLocation;
	valid = valid && !!validFields.Consent;
	valid = valid && !!validFields.CallbackLocation;
	valid = valid && !!validFields.Comment;

	return valid;
}

interface ProcedureContactRequestProps extends ActivityProps {
	urgence: string;
	showImageField: boolean;
	name?: string;
	visited?: boolean;
}

interface FormFields {
	Name?: string;
	GivenName?: string;
	NameParticle?: string;
	Surname?: string;
	Dob?: string;
	Gender?: Gender;
	Tel?: string;
	Bsn?: string;
	Email?: string;
	Address?: string;
	HouseNumber?: string;
	ZipCode?: string;
	Doctor?: string;
	HapLocation?: string;
	Consent?: boolean;
	ImageData?: string;
	ImageContentType?: string;
	Comment?: string;
	CallbackLocation?: string;
}

type ValidFormFields = Omit<{ [field in keyof FormFields]?: boolean }, 'NameParticle' | 'ImageData' | 'ImageContentType'> & {
	EmailOrBsn?: boolean;
};

const ProcedureContactRequest: FunctionComponent<ProcedureContactRequestProps> = (props) => {
	const { handleActivityResponse, handleNext, setHandleNext, setDisableNext, setIsLoading } = props;
	const [{ profile, settings, session, conversation }, dispatch] = useStateContext();
	const [showGenderField] = useState(!profile.gender || profile.gender.length === 0);
	const [isDisabled, setIsDisabled] = useState(props.disabled);
	const [isError, setIsError] = useState(false);
	const [hasSendDataBeenCalled, setHasSendDataBeenCalled] = useState(false);
	const [checkAutoFocusInvalidField, setCheckAutoFocusInvalidField] = useState(false);
	const theme = useTheme();
	const { executeWithLock, isExecuting } = useExecutionLock();

	const profileDobDate = createDateForDob(profile.dob);
	const profileDobDateTime = profileDobDate ? DateTime.fromJSDate(profileDobDate, { zone: 'utc' }) : undefined;

	const initializeFields = (): FormFields => {
		let dobFieldValue: string | undefined;
		if (settings.contactRequest.clientDateOfBirthRequired && profileDobDateTime) {
			dobFieldValue = profileDobDateTime.toFormat('dd-MM-yyyy');
		}

		let genderFieldValue: Gender | undefined;
		if (settings.contactRequest.clientGenderRequired) {
			genderFieldValue = profile.gender ?? undefined;
		}

		const tel = parseInputPhoneNumber(profile.phoneNumber);

		// If there are multiple callbackLocations and advice page was not skipped profile.callbackLocation should have a value.
		const callbackLocationFieldValue =
			profile.callbackLocation ??
			((settings.contactRequest.locationsForCallbackConfigs?.length === 1 && settings.contactRequest.locationsForCallbackConfigs[0].title) || undefined);

		return {
			Dob: dobFieldValue,
			Gender: genderFieldValue,
			Tel: tel,
			Consent: false,
			CallbackLocation: callbackLocationFieldValue
		};
	};

	const [fields, setFields] = useState<FormFields>(initializeFields);
	const [validFields, setValidFields] = useState<ValidFormFields>({});

	const telId = useId();

	// Only show validation status after send data has been called once
	const validationStatus = useMemo(
		() => (hasSendDataBeenCalled ? checkValidFields(validFields, session) : true),
		[hasSendDataBeenCalled, validFields, session, checkValidFields]
	);

	const conversationHasConsultationPreparation = conversation.some((c) => c.type === 'consultationPreparation');

	const focusLastActivity = useCallback((node: HTMLDivElement) => {
		node?.scrollIntoView();
	}, []);

	useEffect(() => {
		setDisableNext(isExecuting);
		setIsLoading(isExecuting);
	}, [isExecuting, setDisableNext, setIsLoading]);

	const handleChangeFormField = (field: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: (field.target as HTMLInputElement).checked ? true : false
			});
		} else if (field.target.type === 'date') {
			setFields({
				...fields,
				[field.target.name]:
					field.target.value === '' || field.target.value.trim() === '' ? '' : DateTime.fromISO(field.target.value).toFormat('dd-MM-yyyy')
			});
		} else if (field.target.type === 'file' && field.target.name === 'Image') {
			const files = (field.target as HTMLInputElement).files;
			if (files && files.length > 0) {
				const file = (field.target as HTMLInputElement).files?.[0] as File;
				if (file.size <= 10485760) {
					// Max size set to 10MB
					const reader = new FileReader();
					reader.onload = function (evt) {
						const readerResult = evt.target?.result as string;
						const base64Index = readerResult?.indexOf(';base64,');
						if (base64Index !== -1) {
							const imageData = readerResult?.substring(base64Index + ';base64,'.length);
							setFields({
								...fields,
								ImageData: imageData,
								ImageContentType: file.type
							});
						}
					};
					reader.readAsDataURL(file);
				} else {
					// We reset the input value because the file is to large
					field.target.value = '';
					setFields({
						...fields,
						ImageData: undefined,
						ImageContentType: undefined
					});
				}
			} else {
				setFields({
					...fields,
					ImageData: undefined,
					ImageContentType: undefined
				});
			}
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? undefined : field.target.value
			});
		}
	};

	const handleChangePhoneField = (value?: string) => {
		setFields({
			...fields,
			Tel: value
		});
	};

	useEffect(() => {
		const newValidFields: ValidFormFields = {
			Name: !settings.contactRequest.clientNameRequired || !!fields.Name,
			GivenName: !settings.contactRequest.clientExtensiveNameRequired || !!fields.GivenName,
			Surname: !settings.contactRequest.clientExtensiveNameRequired || !!fields.Surname,
			Gender: !settings.contactRequest.clientGenderRequired || !!fields.Gender,
			Tel: !settings.contactRequest.clientPhonenumberRequired || !!fields.Tel,
			Address: !settings.contactRequest.clientAddressRequired || !!fields.Address,
			HouseNumber: !settings.contactRequest.clientHouseNumberRequired || !!fields.HouseNumber,
			ZipCode: !settings.contactRequest.clientZipCodeRequired || (!!fields.ZipCode && isNumberOfChars(fields.ZipCode) >= 4),
			Doctor: !settings.contactRequest.clientDoctorRequired || !!fields.Doctor,
			HapLocation:
				!settings.contactRequest.clientHapLocationRequired || !!fields.HapLocation || settings.contactRequest.locationsForCallbackConfigs.length > 0,
			Consent: !!fields.Consent,
			CallbackLocation: settings.contactRequest.locationsForCallbackConfigs.length < 1 || !!fields.CallbackLocation,
			Comment: !settings.contactRequest.clientCommentRequired || !!fields.Comment
		};

		if (settings.contactRequest.clientDateOfBirthRequired) {
			if (fields.Dob) {
				const today = DateTime.now();
				const dobDateTime = DateTime.fromFormat(fields.Dob || '', 'dd-MM-yyyy');
				const calculatedAge =
					calculateAgeFromDob(
						{
							year: `${dobDateTime.year}`,
							month: `${dobDateTime.month}`,
							day: `${dobDateTime.day}`
						},
						settings.applicationTexts
					)?.ageInYears ?? 0;
				const maxAge = settings.maxAge ?? Infinity;
				if (dobDateTime >= today || calculatedAge > maxAge) {
					newValidFields.Dob = false;
				} else {
					newValidFields.Dob = true;
				}
			} else {
				newValidFields.Dob = false;
			}
		} else {
			newValidFields.Dob = true;
		}

		const bsnIsEmpty = !fields.Bsn || fields.Bsn.length === 0;
		const bsnIsValid = validateBsn(fields.Bsn);

		const emailIsEmpty = !fields.Email || fields.Email.length === 0;
		const emailIsValid = validateEmail(
			fields.Email || null,
			settings.contactRequest.clientEmailRequired || settings.contactRequest.clientEmailOrBsnNumberRequired
		);

		if (settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.EmailOrBsn = !bsnIsEmpty || !emailIsEmpty;
		} else {
			newValidFields.EmailOrBsn = true;
		}

		if (settings.contactRequest.clientBsnNumberRequired) {
			newValidFields.Bsn = bsnIsValid;
		} else if (settings.contactRequest.clientBsnNumberOptional || settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.Bsn = bsnIsEmpty || bsnIsValid;
		} else {
			newValidFields.Bsn = true;
		}

		if (settings.contactRequest.clientEmailRequired) {
			newValidFields.Email = emailIsValid;
		} else if (settings.contactRequest.clientEmailOptional || settings.contactRequest.clientEmailOrBsnNumberRequired) {
			newValidFields.Email = emailIsEmpty || emailIsValid;
		} else {
			newValidFields.Email = true;
		}

		setValidFields(newValidFields);
	}, [fields, settings.contactRequest, settings.applicationTexts, settings.maxAge]);

	const hasCallbackRequestTypeWithTimeSchedule = settings.contactRequest.active && settings.contactRequest.hasCallbackRequestTypeWithTimeSchedule;

	const handleSendData = useCallback(async () => {
		setIsDisabled(true);
		setHasSendDataBeenCalled(true);
		const valid = checkValidFields(validFields, session);

		if (valid) {
			const formData = {
				sessionId: session.id,
				sessionToken: session.token,
				userAgreedToPrivacyStatement: fields.Consent || false,
				user: {
					name: fields.Name,
					givenName: fields.GivenName,
					nameParticle: fields.NameParticle,
					surname: fields.Surname,
					dateOfBirth: fields.Dob,
					gender: fields.Gender,
					phoneNumber: fields.Tel,
					address: fields.Address,
					houseNumber: fields.HouseNumber,
					zipCode: fields.ZipCode,
					doctor: fields.Doctor,
					hapLocation: fields.HapLocation,
					bsn: fields.Bsn,
					email: fields.Email,
					imageData: fields.ImageData,
					imageContentType: fields.ImageContentType,
					comment: fields.Comment,
					callbackLocation: fields.CallbackLocation
				},
				languageCode: settings.selectedLanguage.code,
				chosenTimeslot: null
			};

			let schedule = null;
			if (hasCallbackRequestTypeWithTimeSchedule) {
				if (scheduleUseApi) {
					schedule = await GetCallbackTimeSlots(settings.ApiKey, {
						sessionId: session.id,
						sessionToken: session.token,
						callbackLocation: formData.user.callbackLocation
					});
				} else {
					schedule = developmentSchedule;
				}
			}

			if (schedule && (schedule.firstAvailable || (schedule.timeSlots && schedule.timeSlots.length > 0))) {
				if (schedule.firstAvailable) {
					const result = await CallPostCallBackEmail(settings.ApiKey, formData);
					// @ts-expect-error 'message' does not exist on type 'ProblemDetails',
					// But that is fine, since it will just be undefined
					if (result?.message === 'success') {
						dispatch({
							type: 'conversation/addStep',
							step: {
								id: 'requestCallbackEnd',
								type: 'requestCallbackEnd',
								callbackTime: (result as CallBackEmailOutput).callbackTime,
								urgence: props.urgence,
								disableBackButton: true,
								useFirstAvailableTimeSlot: true,
								phoneNumber: fields.Tel
							}
						});
						void handleActivityResponse({
							message: (result as CallBackEmailOutput).message,
							callbackTime: (result as CallBackEmailOutput).callbackTime
						} as ActivityAnswerRequestCallback);
						handleNext();
					} else {
						setIsError(true);
						setIsDisabled(true);
						void handleActivityResponse({ message: 'error' });
					}
				} else if (schedule.timeSlots && schedule.timeSlots.length > 0) {
					dispatch({
						type: 'conversation/addStep',
						step: {
							id: 'callbackRequestScheduler',
							type: 'callbackRequestScheduler',
							schedule: schedule,
							fields: fields,
							urgence: props.urgence
						}
					});
					void handleActivityResponse({ message: 'scheduler', schedule: schedule });
					handleNext();
				}
			} else {
				const result = await CallPostCallBackEmail(settings.ApiKey, formData);
				// @ts-expect-error 'message' does not exist on type 'ProblemDetails',
				// But that is fine, since it will just be undefined
				if (result?.message === 'success') {
					dispatch({
						type: 'conversation/addStep',
						step: {
							id: 'requestCallbackEnd',
							type: 'requestCallbackEnd',
							callbackTime: (result as CallBackEmailOutput).callbackTime,
							urgence: props.urgence,
							disableBackButton: true
						}
					});
					void handleActivityResponse({
						message: (result as CallBackEmailOutput).message,
						callbackTime: (result as CallBackEmailOutput).callbackTime
					} as ActivityAnswerRequestCallback);
					handleNext();
				} else {
					setIsError(true);
					setIsDisabled(true);
					void handleActivityResponse({ message: 'error' });
				}
			}
		} else {
			setIsDisabled(false);
			setCheckAutoFocusInvalidField(true);
		}
	}, [
		validFields,
		session,
		fields,
		settings.selectedLanguage.code,
		hasCallbackRequestTypeWithTimeSchedule,
		settings.ApiKey,
		dispatch,
		props.urgence,
		handleNext,
		handleActivityResponse
	]);

	useEffect(() => {
		setHandleNext(() => async () => {
			await executeWithLock(async () => {
				!props.disabled ? await handleSendData() : handleNext();
			});
		});
	}, [props.disabled, setHandleNext, executeWithLock, handleSendData, handleNext]);

	useEffect(() => {
		if (checkAutoFocusInvalidField) {
			const firstInvalidNode = document.querySelector('[data-valid="false"]');
			firstInvalidNode?.scrollIntoView();
			if (firstInvalidNode && 'focus' in firstInvalidNode && typeof firstInvalidNode.focus === 'function') {
				firstInvalidNode.focus();
			}
			setCheckAutoFocusInvalidField(false);
		}
	}, [checkAutoFocusInvalidField]);

	const isNumberOfChars = (input?: string | null) => {
		if (!input) return 0;

		return input.replace(/( )/g, '').replace(/(\.)/g, '').length;
	};

	// Post session property that the contact form was shown to the user
	useEffect(() => {
		if (!props.disabled) {
			void CallPostSessionLogProperties(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				sessionLogProperties: [
					{
						sessionLogPropertyName: 'ContactFormShown',
						sessionLogPropertyValue: true
					}
				]
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let buttonLabel = '';
	if (isExecuting) {
		buttonLabel = getLabel('WidgetSendingButton', settings.applicationTexts, true);
	} else {
		buttonLabel = settings.contactRequest.labels.sendButton
			? settings.contactRequest.labels.sendButton
			: getLabel('WidgetSendButton', settings.applicationTexts, true);
	}

	const errorMessageLabel = getLabel('callbackRequestErrorMessage', settings.applicationTexts, true);

	const hasOnlyZorgMailContactRequest = conversation.some(
		(item) => item.type === 'advice' && item.advice.startZorgMailRequestProcedure === true && !item.advice.startContactRequestProcedure
	);

	return (
		<SA.ActivityBubble ref={focusLastActivity}>
			{
				// prettier-ignore
				!isError &&
				(hasOnlyZorgMailContactRequest
					? renderMarkdown(settings.contactRequest.labels.introForZorgMail || '')
					: (conversationHasConsultationPreparation
					? renderMarkdown(settings.contactRequest.labels.intro || '')
					: renderMarkdown(settings.contactRequest.labels.introWithoutConsultationPreparation || '')))
			}

			<div style={{ marginTop: theme.spacings.medium }} />

			{!isError && (
				<SP.Form>
					<SP.Row>
						<SP.Col>
							<SP.FormWarningMessage>{getLabel('WarningFillingFormOnBehalfOfAnotherPerson', settings.applicationTexts)}</SP.FormWarningMessage>
						</SP.Col>
					</SP.Row>

					{settings.contactRequest.clientNameRequired && (
						<SP.Row>
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Name"
									id="Name"
									$label={getLabel('WidgetFormLabelName', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.Name}
									data-valid={validationStatus || validFields.Name}
									disabled={isDisabled}
									required
									value={fields.Name}
									autoComplete="name"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{settings.contactRequest.clientExtensiveNameRequired && (
						<>
							<SP.Row>
								<SP.Col $md={4}>
									<InputText
										onChange={handleChangeFormField}
										name="GivenName"
										id="GivenName"
										$label={getLabel('WidgetFormLabelGivenName', settings.applicationTexts, true)}
										$valid={validationStatus || validFields.GivenName}
										data-valid={validationStatus || validFields.GivenName}
										disabled={isDisabled}
										required
										value={fields.GivenName}
										autoComplete="given-name"
									/>
								</SP.Col>
								<SP.Col $md={4}>
									<InputText
										onChange={handleChangeFormField}
										name="NameParticle"
										id="NameParticle"
										$label={getLabel('WidgetFormLabelNameParticle', settings.applicationTexts, true)}
										$valid={true}
									/>
								</SP.Col>
								<SP.Col $md={4}>
									<InputText
										onChange={handleChangeFormField}
										name="Surname"
										id="Surname"
										$label={getLabel('WidgetFormLabelSurname', settings.applicationTexts, true)}
										$valid={validationStatus || validFields.Surname}
										data-valid={validationStatus || validFields.Surname}
										disabled={isDisabled}
										required
										value={fields.Surname}
										autoComplete="family-name"
									/>
								</SP.Col>
							</SP.Row>
						</>
					)}

					{settings.contactRequest.clientDateOfBirthRequired && (
						<SP.Row>
							<SP.Col>
								<InputDate
									onChange={handleChangeFormField}
									name="Dob"
									id="Dob"
									$label={getLabel('WidgetFormLabelDob', settings.applicationTexts, true)}
									max={DateTime.now().toISODate()}
									$valid={validationStatus || validFields.Dob}
									data-valid={validationStatus || validFields.Dob}
									$invalidError={getLabel('ContactRequestErrorInvalidDobLabel', settings.applicationTexts, true)}
									disabled={isDisabled}
									required
									defaultValue={profileDobDateTime?.toFormat('yyyy-MM-dd')}
									value={fields.Dob ? DateTime.fromFormat(fields.Dob, 'dd-MM-yyyy').toFormat('yyyy-MM-dd') : ''}
									autoComplete="bday"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{showGenderField && settings.contactRequest.clientGenderRequired && (
						<SP.Row>
							<SP.Col>
								<InputGender
									onChange={handleChangeFormField}
									name="Gender"
									id="Gender"
									$label={getLabel('WidgetFormLabelGender', settings.applicationTexts, true)}
									defaultValue={profile.gender || undefined}
									$valid={validationStatus || validFields.Gender}
									data-valid={validationStatus || validFields.Gender}
									disabled={isDisabled}
									required
									value={fields.Gender}
									autoComplete="sex"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{settings.contactRequest.clientPhonenumberRequired && (
						<SP.Row>
							<SP.Col>
								<InputPhone
									onChange={handleChangePhoneField}
									name="Tel"
									id={telId}
									$label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
									defaultValue={fields.Tel}
									$valid={validationStatus || validFields.Tel}
									$invalidError={getLabel('callBackRequestErrorTel', settings.applicationTexts, true)}
									disabled={isDisabled}
									required
									autoComplete="tel-national"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{(settings.contactRequest.clientBsnNumberRequired ||
						settings.contactRequest.clientBsnNumberOptional ||
						settings.contactRequest.clientEmailOrBsnNumberRequired) && (
						<SP.Row>
							<SP.Col>
								<InputNumber
									onBlur={handleChangeFormField}
									name="Bsn"
									id="Bsn"
									$label={getLabel('WidgetFormLabelBsn', settings.applicationTexts, true)}
									$valid={(validationStatus && !fields.Bsn) || validFields.Bsn}
									data-valid={(validationStatus && !fields.Bsn) || validFields.Bsn}
									$invalidError={getLabel(
										fields.Bsn ? 'CallbackRequestErrorBsnInvalid' : 'callBackRequestErrorBsn',
										settings.applicationTexts,
										true
									)}
									disabled={isDisabled}
									required={settings.contactRequest.clientBsnNumberRequired}
									value={fields.Bsn ?? ''}
								/>
							</SP.Col>
						</SP.Row>
					)}

					{(settings.contactRequest.clientEmailRequired ||
						settings.contactRequest.clientEmailOptional ||
						settings.contactRequest.clientEmailOrBsnNumberRequired) && (
						<SP.Row>
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Email"
									id="Email"
									$label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.Email}
									data-valid={validationStatus || validFields.Email}
									$invalidError={getLabel('callBackRequestErrorEmail', settings.applicationTexts, true)}
									disabled={isDisabled}
									required={settings.contactRequest.clientEmailRequired}
									value={fields.Email}
									autoComplete="email"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{!validationStatus && !validFields.EmailOrBsn && (
						<SP.Row>
							<SP.Error>
								<FontAwesomeIcon icon={faExclamationCircle} />
								{getLabel('callBackRequestErrorEmailOrBsn', settings.applicationTexts, true)}
							</SP.Error>
						</SP.Row>
					)}

					{settings.contactRequest.clientAddressRequired && (
						<SP.Row>
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Address"
									id="Address"
									$label={getLabel('WidgetFormLabelAddress', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.Address}
									data-valid={validationStatus || validFields.Address}
									disabled={isDisabled}
									required
									value={fields.Address}
									autoComplete="street-address"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{settings.contactRequest.clientHouseNumberRequired && (
						<SP.Row>
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="HouseNumber"
									id="HouseNumber"
									$label={getLabel('WidgetFormLabelHouseNumber', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.HouseNumber}
									data-valid={validationStatus || validFields.HouseNumber}
									disabled={isDisabled}
									required
									value={fields.HouseNumber}
								/>
							</SP.Col>
						</SP.Row>
					)}

					{settings.contactRequest.clientZipCodeRequired && (
						<SP.Row>
							<SP.Col>
								<InputZipCode
									onChange={handleChangeFormField}
									name="ZipCode"
									id="ZipCode"
									$label={getLabel('WidgetFormLabelZipCode', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.ZipCode}
									data-valid={validationStatus || validFields.ZipCode}
									$invalidError={getLabel('callBackRequestErrorZipCode', settings.applicationTexts, true)}
									disabled={isDisabled}
									required
									value={fields.ZipCode}
									autoComplete="postal-code"
								/>
							</SP.Col>
						</SP.Row>
					)}

					{(settings.contactRequest.clientDoctorRequired || settings.contactRequest.clientDoctorOptional) && (
						<SP.Row>
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="Doctor"
									id="Doctor"
									$label={getLabel('WidgetFormLabelDoctor', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.Doctor}
									data-valid={validationStatus || validFields.Doctor}
									disabled={isDisabled}
									required={settings.contactRequest.clientDoctorRequired}
									value={fields.Doctor}
								/>
							</SP.Col>
						</SP.Row>
					)}

					{/* If callbackLocation is required only show InputCallbackLocation when advice was skipped
					    otherwise fields.CallbackLocation should've been set in initializeFields */}
					{settings.contactRequest.locationsForCallbackConfigs.length > 1 && !fields.CallbackLocation && (
						<SP.Row>
							<SP.Col>
								<InputCallbackLocation
									onChange={handleChangeFormField}
									name="CallbackLocation"
									$label={getLabel('WidgetFormLabelSelectCallbackLocation', settings.applicationTexts, true)}
									$locationsForCallbackConfigs={settings.contactRequest.locationsForCallbackConfigs}
									$valid={validationStatus || validFields.CallbackLocation}
									data-valid={validationStatus || validFields.CallbackLocation}
									disabled={isDisabled}
									required
									value={fields.CallbackLocation}
								/>
							</SP.Col>
						</SP.Row>
					)}

					{settings.contactRequest.locationsForCallbackConfigs.length === 0 &&
						settings.contactRequest.clientHapLocationRequired &&
						settings.contactRequest.hapLocations &&
						settings.contactRequest.hapLocations.some((i) => i.title && i.title.length > 0) && (
							<SP.Row>
								<SP.Col>
									<InputHapLocation
										onChange={handleChangeFormField}
										name="HapLocation"
										$label={getLabel('WidgetFormLabelHapLocation', settings.applicationTexts, true)}
										$hapLocations={settings.contactRequest.hapLocations}
										$valid={validationStatus || validFields.HapLocation}
										data-valid={validationStatus || validFields.HapLocation}
										disabled={isDisabled}
										required
										value={fields.HapLocation}
									/>
								</SP.Col>
							</SP.Row>
						)}

					{settings.contactRequest.clientPhotoOptional && props.showImageField && (
						<SP.Row>
							<SP.Col>
								<InputFile
									onChange={handleChangeFormField}
									name="Image"
									id="Image"
									$label={getLabel('WidgetFormLabelImage', settings.applicationTexts, true)}
									accept=".png,.jpg,image/jpeg,image/png"
									disabled={isDisabled}
									$valid
								/>
							</SP.Col>
						</SP.Row>
					)}

					{(settings.contactRequest.clientCommentOptional || settings.contactRequest.clientCommentRequired) && (
						<SP.Row>
							<SP.Col>
								<TextArea
									name="Comment"
									id="Comment"
									onChange={handleChangeFormField}
									$valid={validationStatus || validFields.Comment}
									$invalidError={getLabel('callBackRequestErrorComment', settings.applicationTexts, true)}
									disabled={isDisabled}
									$label={renderMarkdown(settings.contactRequest.labels.formLabelComment || ' ') as JSX.Element}
									required={settings.contactRequest.clientCommentRequired}
									info={getLabel('WarningNoPersonalData', settings.applicationTexts, true)}
								/>
							</SP.Col>
						</SP.Row>
					)}

					{/* note: possibly dont't make whole label clickable to give consent, so consent is more explicit */}
					<SP.Row>
						<InputCheckBox
							onChange={handleChangeFormField}
							name="Consent"
							id="Consent"
							$label={renderMarkdown(settings.contactRequest.labels.consent || '') || ''}
							type="checkbox"
							disabled={isDisabled}
							$valid={validationStatus || validFields.Consent}
							data-valid={validationStatus || validFields.Consent}
							$invalidError={getLabel('callBackRequestErrorConsent', settings.applicationTexts, true)}
							checked={fields.Consent}
						/>
					</SP.Row>
				</SP.Form>
			)}
			{isError && <SP.Error>{renderMarkdown(errorMessageLabel)}</SP.Error>}
			{isError && (
				<ActivityEndActions
					showFeedbackAction={true}
					hideDownloadButton={settings.downloadConversationDisabled}
					handleActivityResponse={handleActivityResponse}
				/>
			)}
		</SA.ActivityBubble>
	);
};

export default ProcedureContactRequest;
