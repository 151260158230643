import React, { FunctionComponent, useMemo } from 'react';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useWidgetIsDisabled } from '../../helpers/hooks/useWidgetIsDisabled';
import { getLabel } from '../../helpers/constants/getLabels';
import S from './WidgetDisabledAlert.styled';
import { faCircleCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

const WidgetDisabledAlert: FunctionComponent = () => {
	const [{ currentSection, conversation, settings: { applicationTexts } }, dispatch] = useStateContext();
	const {
		sectionIsDisabled,
		triageIsDisabled: hasStartedCallbackRequest,
		contactRequestIsDisabled: hasSeenScheduler,
		schedulerIsDisabled: hasFinishedCallback
	} = useWidgetIsDisabled();

	const [warningMessage, icon, linkToScheduler] = useMemo(() => {
		// Assume section is disabled
		// If user has seen scheduler -> WidgetDisabledCallbackScheduler
		// If user has seen callbackRequest -> WidgetDisabledCallback
		// Else -> WidgetDisabledDefault

		if (hasFinishedCallback)
			return [getLabel('WidgetDisabledFinished', applicationTexts), faCircleCheck];
		if (hasSeenScheduler)
			return [getLabel('WidgetDisabledCallbackScheduler', applicationTexts), faCalendar, true];
		if (hasStartedCallbackRequest)
			return [getLabel('WidgetDisabledCallback', applicationTexts), faEye];
		return [getLabel('WidgetDisabledDefault', applicationTexts)];
	}, [currentSection?.type, sectionIsDisabled, applicationTexts]);

	const handleLinkToScheduler = () => {
		dispatch({ type: 'currentSectionIndex/set', sectionIndex: conversation.length - 1 });
	};

	return (
		<S.WidgetDisabledAlert>
			<>
				{icon && <FontAwesomeIcon icon={icon} />}
				<S.WidgetDisabledAlertContent>
					{warningMessage}
					{linkToScheduler && (
						<S.LinkToScheduler onClick={handleLinkToScheduler}>
							{getLabel('WidgetDisabledLinkToScheduler', applicationTexts, true)}
						</S.LinkToScheduler>
					)}
				</S.WidgetDisabledAlertContent>
			</>
		</S.WidgetDisabledAlert>
	);
};

export default WidgetDisabledAlert;
