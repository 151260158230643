import * as UserAgentParser from 'ua-parser-js';

const defaultUAString = window.navigator.userAgent;
const userAgentParserInstance = new UserAgentParser.UAParser(defaultUAString);

function getUA(): UserAgentParser.IResult | null {
	try {
		return {
			ua: defaultUAString,
			os: userAgentParserInstance.getOS(),
			browser: userAgentParserInstance.getBrowser(),
			cpu: userAgentParserInstance.getCPU(),
			device: userAgentParserInstance.getDevice(),
			engine: userAgentParserInstance.getEngine()
		};
	} catch (err) {
		return null;
	}
}

function getDevice(): UserAgentParser.IDevice | null {
	try {
		return userAgentParserInstance.getDevice();
	} catch (err) {
		return null;
	}
}

function getBrowser(): UserAgentParser.IBrowser | null {
	try {
		return userAgentParserInstance.getBrowser();
	} catch (err) {
		return null;
	}
}

function getCPU(): UserAgentParser.ICPU | null {
	try {
		return userAgentParserInstance.getCPU();
	} catch (err) {
		return null;
	}
}

function getEngine(): UserAgentParser.IEngine | null {
	try {
		return userAgentParserInstance.getEngine();
	} catch (err) {
		return null;
	}
}

function isMobile(): boolean {
	// First check if userAgentData is available on this client and then use it, else fallback to user agent check
	if (window.navigator.userAgentData && typeof window.navigator.userAgentData.mobile === 'boolean') {
		return window.navigator.userAgentData.mobile;
	}

	return getDevice()?.type === 'mobile';
}

function isTouchDevice(): boolean {
	return ['mobile', 'tablet', 'wearable'].includes(getDevice()?.type || '');
}

export { getUA, getBrowser, getCPU, getDevice, getEngine, isMobile, isTouchDevice };
