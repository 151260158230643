import { useCallback } from 'react';

import { StateContextType, useStateContext } from '../useStateContext';
import { CallAbcdTriageGetNext, CallAbcdTriageStartQuestion } from '../../services';
import { getLabel } from '../../constants/getLabels';
import { createDateForDob } from '../../support/createDateForDob';
import type { ActivityStep, ActivityStepStartWithAbcdTriage, Gender } from '../../../models';
import { useNextStepCallbackRequest } from './useNextStepCallbackRequest';

export type UseNextStepAbcdTriageResultType = (currentStep?: ActivityStepStartWithAbcdTriage) => Promise<ActivityStep>;

export const useNextStepAbcdTriage: () => UseNextStepAbcdTriageResultType = () => {
	const [{ profile, session, settings }]: StateContextType = useStateContext();

	const handleNextStepCallbackRequest = useNextStepCallbackRequest();

	const getStartQuestion = useCallback(
		async (gender: Gender | undefined | null, age: string, birthdate: Date | undefined): Promise<ActivityStep> => {
			const startQuestion = await CallAbcdTriageStartQuestion(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				gender: gender,
				age: age,
				birthdate: birthdate,
				languageCode: settings.selectedLanguage.code
			});

			if (startQuestion) {
				return {
					step: 0,
					id: `abcdQuestion-${startQuestion.startQuestion.id}`,
					type: 'abcdTriage',
					questions: [
						{
							question: startQuestion.startQuestion,
							empathy: startQuestion.empathy
						}
					]
				};
			}

			return {
				step: 0,
				id: 'abcdStartQuestion',
				type: 'noNextStepFound'
			};
		},
		[session, settings.ApiKey, settings.selectedLanguage.code]
	);

	const getNext = useCallback(
		async (currentStep: ActivityStepStartWithAbcdTriage): Promise<ActivityStep> => {
			const question = currentStep.questions.at(-1);
			if (!question || !question.answer) {
				return {
					step: 0,
					id: `getNext-unknown`,
					type: 'noNextStepFound'
				};
			}

			const nextResult = await CallAbcdTriageGetNext(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				questionId: question.question.id,
				answer: question.answer,
				languageCode: settings.selectedLanguage.code
			});

			if (!nextResult) {
				return {
					step: 0,
					id: `abcdGetNext-${question.question.id}`,
					type: 'noNextStepFound'
				};
			}

			if (nextResult.question) {
				return {
					step: currentStep.step,
					id: currentStep.id,
					type: currentStep.type,
					questions: [
						...currentStep.questions,
						{
							question: nextResult.question,
							empathy: nextResult.empathy
						}
					]
				};
			} else if (nextResult.advice) {
				if (nextResult.advice.skipAdvicePage && nextResult.advice.startContactRequestProcedure) {
					return handleNextStepCallbackRequest(nextResult.advice);
				}

				return {
					step: 0,
					id: 'advice',
					type: 'advice',
					advice: nextResult.advice,
					callButtonPhoneNumbers: nextResult.callButtonPhoneNumbers
				};
			}

			return {
				step: 0,
				id: 'initialBodyAreaSelector',
				type: 'bodyAreaSelector',
				title: getLabel('QuestionWhatBodyPart', settings.applicationTexts),
				info: getLabel('QuestionWhatBodyPartInfo', settings.applicationTexts, false, true),
				profile: 'bodypart'
			};
		},
		[session, settings.ApiKey, settings.applicationTexts, settings.selectedLanguage.code, handleNextStepCallbackRequest]
	);

	return useCallback(
		(currentStep?: ActivityStepStartWithAbcdTriage): Promise<ActivityStep> => {
			if (currentStep) {
				return getNext(currentStep);
			}

			const birthdate = createDateForDob(profile.dob);
			return getStartQuestion(profile.gender, profile.age ?? '0', birthdate);
		},
		[profile.dob, profile.age, profile.gender, getStartQuestion, getNext]
	);
};
