import { useCallback, useState } from 'react';

/**
 * A custom hook to manage the execution of asynchronous functions with a lock mechanism.
 * It ensures that the provided function does not execute if another function is already in progress.
 *
 * The hook also provides an optional callback, `onExecutingChange`, which is triggered
 * whenever the execution state changes, allowing external components to react (e.g., setDisableNext or setIsLoading).
 *
 * @param {Function} [onExecutingChange] - Optional callback invoked with the current `isExecuting` state
 *                                         whenever it changes (true when execution starts, false when it ends).
 *
 * @returns An object with the following properties:
 * - `executeWithLock`: A function that takes an async function as input and ensures it's executed with a lock.
 *                      If another function is in progress, it returns early without executing the provided function.
 * - `isExecuting`: A boolean state indicating whether a function is currently being executed.
 *
 * @example
 * const { executeWithLock, isExecuting } = useExecutionLock(setDisableNext);
 *
 *	const handleActivityResponse = useCallback(async () => {
 *		await executeWithLock(async () => {
 *			await baseHandleActivityResponse();
 *		});
 * 	}, [executeWithLock, baseHandleActivityResponse]);
 *
 * // Optionally, use `isExecuting` to control UI state:
 * <StartButton onStart={() => void handleActivityResponse()} disabled={isExecuting} />
 */
export const useExecutionLock = (onExecutingChange?: (isExecuting: boolean) => void) => {
	const [isExecuting, setIsExecuting] = useState(false);

	const executeWithLock = useCallback(
		async (fn: () => Promise<void>) => {
			if (isExecuting) {
				return undefined;
			}
			setIsExecuting(true);
			onExecutingChange?.(true);
			try {
				await fn();
			} finally {
				setIsExecuting(false);
				onExecutingChange?.(false);
			}
		},
		[isExecuting, onExecutingChange]
	);

	return { executeWithLock, isExecuting };
};
