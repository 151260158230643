import React, { FunctionComponent, useCallback } from 'react';

import { default as SA } from '../Activity/Activity.styled';

import Button from '../Button';
import { useResetWidget } from '../../helpers/hooks/useResetWidget';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import type { ActivityProps } from '../Activity';

const ActivityNoNextStepFound: FunctionComponent<ActivityProps> = (props) => {
	const [{ settings }] = useStateContext();
	const resetWidget = useResetWidget();

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleRestartAction = () => {
		void resetWidget(true);
	};

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
				{getLabel('WidgetNoNextStepFoundTitle', settings.applicationTexts)}
			</SA.BubbleTitle>
			<p>{getLabel('WidgetNoNextStepFoundDescription', settings.applicationTexts)}</p>

			<SA.ActivityActions>
				<Button disabled={props.disabled} onClick={handleRestartAction}>
					{getLabel('AdviceButtonLabelRestart', settings.applicationTexts)}
				</Button>
			</SA.ActivityActions>
		</SA.ActivityBubble>
	);
};

export default ActivityNoNextStepFound;
