import styled from 'styled-components';

const SectionContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.large};
`;

const SectionHeader = styled.div`
	display: flex;
	flex-direction: row;
	padding: 12px;
	gap: 16px;
	border-bottom: 1px solid;
`;

const SectionTitle = styled.div``;

const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	scroll-behavior: smooth;
`;

const S = { SectionContainer, SectionHeader, SectionTitle, Section };

export default S;
