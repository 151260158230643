import React, { useState, useEffect, useRef, useCallback, FunctionComponent, useMemo } from 'react';

import { useTheme } from 'styled-components';

import SVG from 'react-inlinesvg';
import ReactTooltip from 'react-tooltip';

import S from './SectionBodyArea.styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import Loader from '../Loader/Loader';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetBodyAreas } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';

import type { ActivityAnswerBodyArea, ActivityStepBodyAreaSelector } from '../../models';

import { ActivityProps } from '../Activity';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { useExecutionLock } from '../../helpers/hooks/useExecutionLock';

interface SectionBodyAreaProps extends ActivityProps {
	currentStep: ActivityStepBodyAreaSelector;
}

const SectionBodyArea: FunctionComponent<SectionBodyAreaProps> = ({
	handleActivityResponse: baseHandleActivityResponse,
	handleNext,
	setHandleNext,
	setDisableNext,
	...props
}) => {
	const [{ settings, profile, refs }, dispatch] = useStateContext();
	const themeContext = useTheme();
	const svgRef = useRef<SVGElement>() as React.MutableRefObject<SVGElement>;
	const stepNumber = useCurrentStepNumber(props.currentStep.id);
	const [rippleAnimationAnchor, setRippleAnimationAnchor] = useState<SVGPathElement>();
	const [showAnimation, setShowAnimation] = useState(true);

	const rippleAnimationBounds = useMemo(
		() => ({
			x: (rippleAnimationAnchor?.getBoundingClientRect().x ?? 0) - (svgRef.current?.getBoundingClientRect().x ?? 0),
			y: (rippleAnimationAnchor?.getBoundingClientRect().y ?? 0) - (svgRef.current?.getBoundingClientRect().y ?? 0)
		}),
		[rippleAnimationAnchor]
	);

	const [bodySide, setBodySide] = useState(true);
	const [svg, setSvg] = useState<{ front?: string; back?: string }>({
		front: undefined,
		back: undefined
	});
	const bodypart = props.currentStep.answer?.bodyAreaId;

	const { executeWithLock, isExecuting } = useExecutionLock(setDisableNext);

	const handleActivityResponse = useCallback(
		async (bodyAreaId: number, bodyAreaName: string | null) => {
			const response = await baseHandleActivityResponse({ bodyAreaId, bodyAreaName } as ActivityAnswerBodyArea);

			if (response === false) {
				return false;
			}

			dispatch({
				type: 'updateProfile',
				profile: {
					...profile,
					bodypart: bodyAreaId,
					bodypartName: bodyAreaName ?? ''
				}
			});

			handleNext();
		},
		[profile, baseHandleActivityResponse, dispatch, handleNext]
	);

	// We load the body areas when the control loads for the first time
	useEffect(() => {
		const fetchBodyareas = async () => {
			const bodyAreas = await CallGetBodyAreas(settings.ApiKey, {
				gender: profile.gender || undefined,
				age: Number.parseFloat(profile.age || ''),
				bodyAreaIds: [],
				languageCode: settings.selectedLanguage.code
			});

			const svg = {
				front: bodyAreas?.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Voor';
				})[0].svg,
				back: bodyAreas?.bodyImageSVGs.filter((svg) => {
					return svg.perspective === 'Achter';
				})[0].svg
			};

			setSvg(svg);

			if (bodypart !== undefined) {
				const svgElement = document.createElement('svg');
				svgElement.innerHTML = svg.front as string;
				let element = svgElement.querySelector(`[data-bodyareaid="${bodypart}"`);

				if (!element) {
					svgElement.innerHTML = svg.back as string;
					element = svgElement.querySelector(`[data-bodyareaid="${bodypart}"`);

					if (element) {
						setBodySide(false);
					}
				}
			}

			ReactTooltip.rebuild();
		};
		void fetchBodyareas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const processBodyAreaSelect = useCallback(
		async (bodyAreaElement: Element, svgElement: Element) => {
			await executeWithLock(async () => {
				const bodyAreaIdString = bodyAreaElement.getAttribute('data-bodyareaid');
				if (!bodyAreaIdString) {
					return;
				}

				const bodyAreaId = parseInt(bodyAreaIdString);
				if (bodyAreaId) {
					try {
						svgElement.querySelector('[data-selected]')?.removeAttribute('data-selected');
						const ariaPressedelements = svgElement.querySelectorAll('[aria-pressed="true"]');
						ariaPressedelements.forEach((el) => el.setAttribute('aria-pressed', 'false'));
					} catch (e) {
						// We ignore the error
					}

					bodyAreaElement.setAttribute('data-selected', 'true');
					bodyAreaElement.setAttribute('aria-pressed', 'true');

					const bodyAreaName = bodyAreaElement.getAttribute('data-tip') || null;

					if ((await handleActivityResponse(bodyAreaId, bodyAreaName)) === false) {
						bodyAreaElement.removeAttribute('data-selected');
						bodyAreaElement.setAttribute('aria-pressed', 'false');
					}
				}
			});
		},
		[executeWithLock, handleActivityResponse]
	);

	const handleBodyAreaSelect = (event: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>) => {
		if (!props.disabled) {
			event.preventDefault();

			const bodyAreaElement = (event.target as HTMLElement).closest('[data-bodyareaid]');
			const svgElement = (event.target as HTMLElement).closest('svg');
			if (bodyAreaElement && svgElement) {
				void processBodyAreaSelect(bodyAreaElement, svgElement);
			}
		}
	};

	// When control enabled make sure the tabindex on the SVG is 0, else -1 to disable tabbing
	// When control enabled attach a document event listener to keydown so we can select the body area using keyboard
	// Also use event listener on keydown to hide the tooltip on esc
	useEffect(() => {
		if (svgRef?.current) {
			const tabIndexElements = svgRef?.current.querySelectorAll('svg [tabindex]');
			if (tabIndexElements && tabIndexElements.length > 0) {
				const tabIndex = props.disabled ? '-1' : '0';
				tabIndexElements.forEach((el) => el.setAttribute('tabindex', tabIndex));
			}
		}

		if (!props.disabled) {
			const documentKeyDown = (event: KeyboardEvent) => {
				if (event.key === 'Escape' || event.key === 'Esc') {
					ReactTooltip.hide();

					event.preventDefault();
				} else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
					const bodyAreaElement = (event.target as HTMLElement)?.closest('[data-bodyareaid]');
					const svgElement = (event.target as HTMLElement)?.closest('svg');

					if (bodyAreaElement && svgElement) {
						void processBodyAreaSelect(bodyAreaElement, svgElement);
						event.preventDefault();
					} else if (isExecuting) {
						// Prevent KeyBoardEvent in SectionContainer to call _handleNext while executing processBodyAreaSelect
						event.preventDefault();
						event.stopImmediatePropagation();
					}
				}
			};

			document.addEventListener('keydown', documentKeyDown);

			return () => {
				document.removeEventListener('keydown', documentKeyDown);
			};
		}
	}, [props.disabled, isExecuting, processBodyAreaSelect]);

	useEffect(() => {
		setShowAnimation(true);

		setTimeout(() => {
			if (bodySide) {
				setRippleAnimationAnchor(document.getElementById('rechterarm') as unknown as SVGPathElement);
			} else {
				setRippleAnimationAnchor(document.getElementById('linkerarm') as unknown as SVGPathElement);
			}
		}, 1);
	}, [showAnimation]);

	const handleBodySideChange = () => {
		setShowAnimation(false);
		setBodySide(!bodySide);
	};

	const preProcesSvg = (code: string) => {
		code = code.replace(/fill="#00b2ff"/g, `fill="${themeContext.colors.text900}"`);
		code = code.replace(/data-bodyareaid="(\d+)"/gm, '$& tabindex="0" role="button" aria-pressed="false"');
		code = code.replace(/data-tip="(.*?)"/gm, '$& aria-label="$1"');
		return code;
	};

	return (
		<S.ActivityBubble>
			<ActivityBubbleTitle
				title={props.currentStep.title}
				info={props.currentStep.info}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
				stepNumber={stepNumber}
			/>
			<S.ActivityComplaintWrapper>
				<S.Switch>
					<button
						className={bodySide ? 'active' : ''}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onClick={bodySide ? () => {} : handleBodySideChange}
						title={getLabel('BodySwitchFrontLabel', settings.applicationTexts, true)}
						tabIndex={0}
					>
						{getLabel('BodySwitchFrontLabel', settings.applicationTexts, true)}
					</button>
					<button
						className={!bodySide ? 'active' : ''}
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onClick={!bodySide ? () => {} : handleBodySideChange}
						title={getLabel('BodySwitchBackLabel', settings.applicationTexts, true)}
						tabIndex={0}
					>
						{getLabel('BodySwitchBackLabel', settings.applicationTexts, true)}
					</button>
				</S.Switch>

				{svg.front && svg.back && showAnimation ? (
					<S.Body
						disabled={props.disabled}
						$highlight={props.currentStep.answer?.bodyAreaId}
						$anchor={rippleAnimationBounds}
						$useChildSvg={parseInt(profile.age ?? '42') <= 12}
						$hasAnswer={!!bodypart}
						$bodySide={bodySide}
					>
						<SVG
							innerRef={svgRef}
							onError={(error) => console.log(error.message)}
							onLoad={() => {
								ReactTooltip.rebuild();
								setRippleAnimationAnchor(document.getElementById('rechterarm') as unknown as SVGPathElement);
							}}
							preProcessor={preProcesSvg}
							src={bodySide === true ? svg.front : svg.back}
							onClick={handleBodyAreaSelect}
							onTouchEnd={handleBodyAreaSelect}
						/>

						{/* TODO: bodyArea list */}
					</S.Body>
				) : (
					<S.SvgLoader>
						<Loader />
					</S.SvgLoader>
				)}

				{!props.disabled && (
					<ReactTooltip globalEventOff="click" disableInternalStyle={true}>
						{}
					</ReactTooltip>
				)}
			</S.ActivityComplaintWrapper>
		</S.ActivityBubble>
	);
};

export default SectionBodyArea;
