import React, { FunctionComponent, useState } from 'react';

import { Rail, Handles, Ticks, SliderItem } from 'react-compound-slider';
import Button from '../Button';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';

import S from './PainSlider.styled';

const tickColor = [
	//
	'#89eece',
	'#89eece',
	'#f2ecbb',
	'#f2ecbb',
	'#e6c36b',
	'#e6c36b',
	'#efb3b3',
	'#efb3b3',
	'#e66b6b',
	'#e66b6b',
	'#ea4f4f'
];

interface PainSliderProps {
	handleActivityResponse: (answer: string) => Promise<void>;
	disabled?: boolean;
}

const PainSlider: FunctionComponent<PainSliderProps> = (props) => {
	const [pain, setPain] = useState(0);
	const [{ settings }] = useStateContext();

	const tickLabels = [
		//
		getLabel('QuestionSliderIndex0', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex1', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex2', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex3', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex4', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex5', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex6', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex7', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex8', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex9', settings.applicationTexts, true),
		getLabel('QuestionSliderIndex10', settings.applicationTexts, true)
	];

	const handlePainSelector = (value: number) => {
		setPain(value);
	};

	const handleActivityResponse = (answer: number) => {
		void props.handleActivityResponse(`${answer}`);
	};

	const sliderRootProps = {
		role: 'slider',
		'aria-valuemin': '0',
		'aria-valuemax': '10',
		'aria-valuenow': pain,
		'aria-valuetext': `${pain} - ${tickLabels[pain]}`
	};

	return (
		<S.SliderWrapper>
			<S.Slider
				disabled={props.disabled}
				domain={[0, 10]}
				values={[0]}
				step={1}
				mode={2}
				onUpdate={handlePainSelector}
				rootProps={sliderRootProps}
				reversed={settings.selectedLanguage.isRtl}
			>
				<Rail>
					{({ getRailProps }) => (
						<S.Rail {...getRailProps()}>
							<S.InnerRail />
						</S.Rail>
					)}
				</Rail>

				<Handles>
					{({ handles, getHandleProps }) => (
						<>
							{handles.map((handle) => (
								<Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} disabled={props.disabled} />
							))}
						</>
					)}
				</Handles>

				<Ticks count={10}>
					{({ ticks }) => (
						<S.Ticks>
							{ticks.map((tick) => (
								<Tick key={tick.id} tick={tick} />
							))}
						</S.Ticks>
					)}
				</Ticks>
			</S.Slider>
			{tickLabels && (
				<Button disabled={props.disabled} onClick={() => handleActivityResponse(pain)}>
					{pain} - {tickLabels[pain]}
				</Button>
			)}
		</S.SliderWrapper>
	);
};

export default PainSlider;

interface HandleProps {
	handle: {
		percent: number;
		id: string;
		value: number;
	}
	getHandleProps: (id: string) => {
		onKeyDown: (e: React.KeyboardEvent<Element>) => void;
		onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => void;
		onTouchStart: (e: React.TouchEvent<Element>) => void;
	};
	disabled?: boolean;
}

const Handle: FunctionComponent<HandleProps> = (props) => {
	return (
		<S.HandleController
			$percent={props.handle.percent}
			{...props.getHandleProps(props.handle.id)}
			$color={tickColor[props.handle.value]}
			tabIndex={props.disabled ? -1 : 0}
		></S.HandleController>
	);
};

interface TickProps {
	tick: SliderItem;
}

const Tick: FunctionComponent<TickProps> = ({ tick }) => {
	return <S.TickLine value={tick.value} $color={tickColor[tick.value]} />;
};
