import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { SurveyQuestionOutput } from '../../models';

export type SurveyGetNextInput = SessionInput &
	LanguageInput & {
		questionId: number;
		answerId: number;
		answerText?: string;
	};

async function CallSurveyGetNext(apiKey: string, input: SurveyGetNextInput): Promise<SurveyQuestionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<SurveyQuestionOutput>('SurveyGetNext?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallSurveyGetNext');
	}

	return undefined;
}

export { CallSurveyGetNext };
