import { compiler } from 'markdown-to-jsx';
import type { ApplicationText } from '../../models';
import { markdownCompilerOverrides } from '../support/renderMarkdown';

export function getLabel(label: string, labels: ApplicationText[]): JSX.Element;
export function getLabel(label: string, labels: ApplicationText[], textOnly: true): string;
export function getLabel(label: string, labels: ApplicationText[], textOnly: boolean): JSX.Element | string;
export function getLabel(label: string, labels: ApplicationText[], textOnly: true, allowEmpty: boolean): string;
export function getLabel(label: string, labels: ApplicationText[], textOnly: boolean, allowEmpty: boolean): JSX.Element | string;

/**
 *
 * @param {string} label Desired label to get from set
 * @param {ApplicationText[]} labels The set of labels
 * @param {boolean} textOnly When true the string won't be compiled from markdown to HTML. Default is false
 * @param {boolean} allowEmpty When true the string is allowed to be empty, an empty string will never be compiled from markdown. When false an empty string is replaced with an missing label message. Default is false
 *
 * @returns Returns a JSX.Element or string when textOnly is false and a string when textOnly is true
 */
export function getLabel(label: string, labels: ApplicationText[], textOnly = false, allowEmpty = false): JSX.Element | string {
	let correctedLabel = getCorrectedLabelText(label, labels);
	if (correctedLabel.length === 0) {
		if (allowEmpty) {
			return correctedLabel;
		} else {
			correctedLabel = `geen label voor ${label}.`;
		}
	}

	if (textOnly) return correctedLabel;

	return compiler(correctedLabel, {
		overrides: markdownCompilerOverrides
	});
}

function getCorrectedLabelText(label: string, labels: ApplicationText[]): string {
	const labelItem = labels.find((l) => l.label === label);
	if (labelItem && labelItem.text && labelItem.text.length > 0) {
		const labelText = labelItem.text.replace(/\\n/g, '\n');
		return labelText;
	}

	return '';
}
