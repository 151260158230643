import React, { FunctionComponent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ThemeProvider } from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { initInitialConversation } from '../../helpers/constants/initInitialConversation';
import { getLabel } from '../../helpers/constants/getLabels';
import {
	CallGetApplicationTexts,
	CallGetChannelSettings,
	CallGetLanguages,
	CallPostSessionLogProperties,
	CallStartSession,
	GetApplicationTextsOutput,
	GetChannelSettingsOutput
} from '../../helpers/services';
import { Theme } from '../../helpers/styled/Theme';
import { GlobalStyle } from '../../helpers/styled/GlobalStyle';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';

import Widget from '../Widget/Widget';
import Loader from '../Loader/Loader';

import S from './styled';
import Modal from '../Modal/Modal';
import type { ActivityStep, AssetsContext, LanguageApplicationTexts, Settings, SettingsWidgetType, WidgetConfig } from '../../models';
import CompactWidget from '../CompactWidget/CompactWidget';
import { initInitialProfile } from '../../helpers/constants/initInitialProfile';
import background from '../../assets/background.png';

interface AppProps extends React.PropsWithChildren {
	config: WidgetConfig;
}

const App: FunctionComponent<AppProps> = (props) => {
	const [{ profile, settings, modal }, dispatch] = useStateContext();
	const handleToggleModal = useToggleModal();
	const localizedTheme = Theme(settings);
	const valid = settings.apiKeyIsValid;
	const appRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const initialConversationRef = useRef<ActivityStep[] | null>(null);

	useEffect(() => {
		/**
		 * Fetch & consolidate settings
		 */
		const fetchConfig = async () => {
			const languages = await CallGetLanguages(props.config.ApiKey);
			if (Array.isArray(languages) && languages.length > 0) {
				let selectedLanguage = languages.find((l) => l.code === props.config.defaultLanguageCode);
				if (!selectedLanguage) {
					selectedLanguage = languages.find((l) => l.isDefault);
					if (!selectedLanguage) {
						selectedLanguage = settings.selectedLanguage;
					}
				}

				const channelSettingsPromise = CallGetChannelSettings(props.config.ApiKey, {
					languageCode: selectedLanguage.code
				});
				const applicationTextsPromise = CallGetApplicationTexts(props.config.ApiKey, {
					languageCode: selectedLanguage.code
				});

				const result = await Promise.allSettled([channelSettingsPromise, applicationTextsPromise]);

				if (result[0].status === 'rejected') {
					throw new Error('Error getting channel settings');
				}
				if (result[1].status === 'rejected') {
					throw new Error('Error getting application texts');
				}

				const channelSettings = result[0].value as GetChannelSettingsOutput;
				const applicationTexts = result[1].value as GetApplicationTextsOutput;

				const startWithTriageSearch = props.config.startWithTriageSearch === 'forceTrue' ? true : false;

				let widgetType: SettingsWidgetType = 'Widget';
				if (startWithTriageSearch) {
					widgetType = 'StartWithTriageSearch';
				} else if (props.config.startWithAbcdTriage) {
					widgetType = 'StartWithAbcdTriage';
				} else if (props.config.skipGenderAndAge) {
					widgetType = 'SkipGenderAndAge';
				} else if (props.config.type === 'button') {
					widgetType = 'Button';
				} else if (props.config.type === 'compact') {
					widgetType = 'Compact';
				}

				// use welcomeText from channel settings, otherwise from widget config
				const welcome_text: LanguageApplicationTexts = (props.config.welcome_text as LanguageApplicationTexts) ?? {};
				if (channelSettings?.welcomeText) {
					welcome_text[selectedLanguage.code] = channelSettings.welcomeText;
				}

				// use questionWhatGender from channel settings, otherwise from widget config
				const questionWhatGender: LanguageApplicationTexts = (props.config.labels?.QuestionWhatGender as LanguageApplicationTexts) ?? {};
				if (channelSettings?.questionWhatGender) {
					questionWhatGender[selectedLanguage.code] = channelSettings.questionWhatGender;
				}

				const allConfigSettings = {
					showLanguageSelector: true,
					...props.config,
					...channelSettings,
					...applicationTexts,
					startWithTriageSearch: startWithTriageSearch,
					widgetType: widgetType,
					selectedLanguage: selectedLanguage,
					accentColor: channelSettings?.clientColor ?? props.config.accent_color, // reconcile two different settings into new one
					apiKeyIsValid: true,
					welcome_text: welcome_text,
					labels: {
						...props.config.labels,
						QuestionWhatGender: questionWhatGender
					},
					branding: {
						...props.config.branding,
						name: channelSettings?.brandingName ?? props.config.branding?.name
					} // use brandingName from channel settings, otherwise from widget config
				} as Settings;

				// clean up to prevent inappropriate use
				delete allConfigSettings.clientColor;
				delete allConfigSettings.accent_color;
				delete allConfigSettings.welcomeText;
				delete allConfigSettings.questionWhatGender;
				delete allConfigSettings.brandingName;

				const initialProfile = initInitialProfile(profile, allConfigSettings);
				const initialConversation = initInitialConversation(allConfigSettings, initialProfile);
				initialConversationRef.current = initialConversation;
				const startConversation = initialConversation.slice(0, 1);

				// update
				dispatch({ type: 'updateLanguages', languages: languages });
				dispatch({ type: 'updateSettings', settings: allConfigSettings });
				dispatch({ type: 'updateProfile', profile: initialProfile });
				dispatch({ type: 'updateConversationActivities', conversation: startConversation });
			} else {
				// Get application texts with default language because we need label WidgetApiKeyInvalid
				const applicationTexts = await CallGetApplicationTexts(props.config.ApiKey, {});

				const allConfigSettings = {
					...settings,
					...applicationTexts,
					accentColor: props.config.accent_color,
					apiKeyIsValid: false
				};

				// clean up to prevent inappropriate use
				delete allConfigSettings.clientColor;
				delete allConfigSettings.accent_color;

				dispatch({ type: 'updateSettings', settings: allConfigSettings });
			}
		};
		if (settings.apiKeyIsValid === null) void fetchConfig();

		/**
		 * Create & Setup modal elements
		 */
		const initModal = async () => {
			if (modal.id === null) {
				const mindd_modal = document.createElement('div');
				const mindd_modal_id = 'mindd_modal_' + Math.random().toString(36).substring(2) + new Date().getTime().toString(36);

				if (props.config.open) {
					await StartSession();
				}

				dispatch({
					type: 'updateModal',
					modal: {
						...modal,
						id: mindd_modal_id,
						target: mindd_modal,
						open: props.config.open || null
					}
				});

				mindd_modal.setAttribute('id', mindd_modal_id);
				document.body.appendChild(mindd_modal);
			}
		};
		void initModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const preloadAssets = async () => {
			const fetchBackgroundBlobUrl = async () => {
				const response = await fetch(background);
				const blob = await response.blob();
				return URL.createObjectURL(blob);
			};
			const assets: AssetsContext = {
				backgroundBlobUrl: await fetchBackgroundBlobUrl()
			};
			dispatch({ type: 'updateAssets', assets: assets });
		};
		void preloadAssets();
	}, [dispatch]);

	const StartSession = () => {
		return CallStartSession(settings.ApiKey, {
			widgetType: settings.widgetType,
			restart: false,
			externalId: profile.externalId,
			phoneNumber: profile.phoneNumber,
			languageCode: settings.selectedLanguage.code
		}).then((data) => {
			if (data && data.sessionId) {
				dispatch({
					type: 'updateSession',
					session: {
						id: data.sessionId,
						token: data.sessionToken
					}
				});

				if (settings.skipGenderAndAge) {
					const initialConversation = initialConversationRef.current ?? [];

					void CallPostSessionLogProperties(settings.ApiKey, {
						sessionId: data.sessionId,
						sessionToken: data.sessionToken,
						sessionLogProperties: [
							{
								sessionLogPropertyName: 'GenderSkipped',
								sessionLogPropertyValue: !initialConversation.some((step) => step.type === 'genderSelector')
							},
							{
								sessionLogPropertyName: 'AgeSkipped',
								sessionLogPropertyValue: !initialConversation.some((step) => step.type === 'ageSelector')
							}
						]
					});
				}

				void handleToggleModal();
			} else {
				console.error('There was an error starting a session');
			}
		});
	};

	const startButtonClicked = () => {
		void StartSession();
	};

	const loaderFragment = (
		<S.Loader>
			<Loader />
		</S.Loader>
	);

	const inValidFragment = (
		<div>
			<div>{getLabel('WidgetApiKeyInvalid', settings.applicationTexts)}</div>
		</div>
	);

	let content;

	if (props.config.type === 'button') {
		content = (
			<>
				{valid === true &&
					(!modal.open ? (
						<S.StartButton onClick={startButtonClicked} className={'mindd-widget-startbutton'}>
							{props.children}
						</S.StartButton>
					) : (
						<Widget appRef={appRef} />
					))}
			</>
		);
	} else if (props.config.type === 'compact' || props.config.skipGenderAndAge) {
		content = (
			<>
				{valid === null && loaderFragment}
				{valid === true && (!modal.open ? <CompactWidget onStart={startButtonClicked} appRef={appRef} /> : <Widget appRef={appRef} />)}
				{valid === false && inValidFragment}
			</>
		);
	} else {
		content = (
			<>
				{valid === null && loaderFragment}
				{valid === true && <Widget appRef={appRef} />}
				{valid === false && inValidFragment}
			</>
		);
	}

	return createPortal(
		<>
			<GlobalStyle settings={settings} modal={modal} />
			<ThemeProvider theme={localizedTheme}>
				<Modal>
					<S.App ref={appRef} lang={settings.selectedLanguage.locale}>
						{content}
					</S.App>
				</Modal>
			</ThemeProvider>
		</>,
		(modal.open ? modal.target : props.config.target) as Element
	);
};

export default App;
