import styled from 'styled-components';
import BaseButton from '../Button';

const Form = styled.form`
	display: grid;
	margin: 0;
	@media ${(props) => props.theme.screens.small} {
		width: 100%;
		flex-direction: column;
		padding-inline-start: 0px;
	}
`;

const Button = styled(BaseButton)`
	&&& {
		margin: ${(props) => props.theme.spacings.extraSmall} 0 ${(props) => props.theme.spacings.extraSmall} 0;
		@media ${(props) => props.theme.screens.medium} {
			margin: ${(props) => props.theme.spacings.extraSmall} ${(props) => props.theme.spacings.small} ${(props) => props.theme.spacings.extraSmall} 0;
		}
	}
`;

const Alert = styled.button`
	color: ${(props) => props.theme.colors.accent} !important;
	background-color: transparent;
	padding: 0 0 ${(props) => props.theme.spacings.medium} 0;
	border-radius: 0;
	font-weight: 400;
	display: block;

	&:focus {
		outline: 0;
	}
`;

export default {
	Form,
	Button,
	Alert
};
