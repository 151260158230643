import React from 'react';
import styled from 'styled-components';
import type { DivProps, TextAreaProps } from '../../models/widget/Styled';

const Stars = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin: 1em 0 2em 0;
`;

const Star = styled.div`
	border: 0;
	background: none;
	cursor: pointer;
	margin-inline-end: 20px;
	padding: 10px;
	
	&:focus,
	&:hover {
		outline: none;
		box-shadow: unset;
	}

	path {
		transition: fill 0.15s;
		stroke: ${(props) => props.theme.colors.accent};
	}

	&:hover,
	&:hover ~ & {
		path {
			fill: ${(props) => props.theme.colors.accent};
		}
	}

	@media ${(props) => props.theme.screens.medium} {
		padding: 0;
	}
`;

const Comment = styled.textarea`
	display: block;
	border-radius: ${(props) => props.theme.radii.default};
	width: 100%;
	max-width: 400px;
	height: 200px;
	padding: 10px;
	margin-bottom: 1em;
	&:focus {
		outline: none;
	}
`;

export default {
	Star: (props: DivProps) => <Star {...props}>{props.children}</Star>,
	Stars: (props: DivProps) => <Stars {...props}>{props.children}</Stars>,
	Comment: (props: TextAreaProps) => <Comment {...props}>{props.children}</Comment>
};
