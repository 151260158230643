import styled from 'styled-components';

const CompactWidget = styled.div`
	height: 100%;
	width: 100%;
	max-width: ${(props) => `${props.theme.sizes.maxWidth}px`};
	margin: 0 auto;
`;

// used for mobile
const LanguageSelectorWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacings.medium};
	padding-left: ${(props) => props.theme.spacings.medium};
	padding-right: ${(props) => props.theme.spacings.medium};
`;

interface WelcomeTextStartButtonWrapperProps {
	$isSmall?: boolean;
	$showWelcomeText?: boolean;
}

const WelcomeTextStartButtonWrapper = styled.div<WelcomeTextStartButtonWrapperProps>`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacings.medium};
	gap: ${(props) => props.theme.spacings.medium};

	${(props) =>
		!props.$isSmall &&
		`
		flex-direction: row;
		padding: ${props.theme.spacings.medium} ${props.theme.spacings.extraExtraLarge};
	`}
`;

const StartButtonWrapper = styled.div`
	flex: 1;
`;

interface WelcomeTextWrapperProps {
	$isSmall?: boolean;
}

const WelcomeTextWrapper = styled.div<WelcomeTextWrapperProps>`
	${(props) =>
		!props.$isSmall &&
		`
		margin-top: 0;
		flex: 2;
		display: flex;
		align-items: center;
	`}
`;

interface CompactWidgetFooterProps {
	$isSmall?: boolean;
}

const CompactWidgetFooter = styled.div<CompactWidgetFooterProps>`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${(props) => props.theme.colors.borderLight};
	padding: ${(props) => props.theme.spacings.medium} ${(props) => props.theme.spacings.medium};

	${(props) =>
		!props.$isSmall &&
		`
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: ${props.theme.spacings.medium} ${props.theme.spacings.extraExtraLarge};	
	`}
`;

export default {
	CompactWidget,
	LanguageSelectorWrapper,
	WelcomeTextStartButtonWrapper,
	StartButtonWrapper,
	WelcomeTextWrapper,
	CompactWidgetFooter
};
