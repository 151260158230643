import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type SessionLogAdviceBackInput = SessionInput & {
	sessionLogAdviceBack: SessionLogAdviceBack;
};

export type SessionLogAdviceBack = {
	adviceBackClicked: boolean;
};

async function CallPostSessionLogAdviceBack(apiKey: string, input: SessionLogAdviceBackInput): Promise<boolean> {
	try {
		const { status } = await minddApiAxios.post('PostSessionLogAdviceBack?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostSessionLogAdviceBack');
	}

	return false;
}

export { CallPostSessionLogAdviceBack };
