// TeleQ test schedules used for development, to be less dependent on arbitrary api results

export const scheduleFirstAvailable = {
	firstAvailable: '2024-02-26T09:35:00Z',
	timeSlots: null
};

export const scheduleNotRounded = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-03-30T00:00:00Z',
			slots: [
				{
					start: '2024-03-30T10:09:16.15Z',
					end: '2024-03-30T10:30:00Z'
				},
				{
					start: '2024-03-30T10:39:16.15Z',
					end: '2024-03-30T11:00:00Z'
				},
				{
					start: '2024-03-30T14:59:59.15Z',
					end: '2024-03-30T15:00:00Z'
				},
				{
					start: '2024-03-30T15:00:00Z',
					end: '2024-03-30T15:30:00Z'
				}
			]
		}
	]
};

export const scheduleToday = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-02-10T00:00:00Z',
			slots: [
				{
					start: '2024-02-10T14:00:00Z',
					end: '2024-02-10T14:30:00Z'
				},
				{
					start: '2024-02-10T15:00:00Z',
					end: '2024-02-10T15:30:00Z'
				}
			]
		}
	]
};

export const scheduleOneDay = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-02-08T00:00:00Z',
			slots: [
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				}
			]
		}
	]
};

export const scheduleTwoDays = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-02-26T00:00:00Z',
			slots: [
				{
					start: '2024-02-26T11:00:00Z',
					end: '2024-02-26T11:30:00Z'
				},
				{
					start: '2024-02-26T11:30:00Z',
					end: '2024-02-26T12:00:00Z'
				},
				{
					start: '2024-02-26T12:00:00Z',
					end: '2024-02-26T12:30:00Z'
				}
			]
		},
		{
			date: '2024-02-27T00:00:00Z',
			slots: [
				{
					start: '2024-02-27T11:00:00Z',
					end: '2024-02-27T11:30:00Z'
				},
				{
					start: '2024-02-27T11:30:00Z',
					end: '2024-02-27T12:00:00Z'
				}
			]
		}
	]
};

export const scheduleWithGaps = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-02-26T00:00:00Z',
			slots: [
				{
					start: '2024-02-26T11:00:00Z',
					end: '2024-02-26T11:30:00Z'
				},
				{
					start: '2024-02-26T11:30:00Z',
					end: '2024-02-26T12:00:00Z'
				},
				{
					start: '2024-02-26T12:00:00Z',
					end: '2024-02-26T12:30:00Z'
				}
			]
		},
		{
			date: '2024-02-28T00:00:00Z',
			slots: [
				{
					start: '2024-02-28T11:00:00Z',
					end: '2024-02-28T11:30:00Z'
				},
				{
					start: '2024-02-28T11:30:00Z',
					end: '2024-02-28T12:00:00Z'
				}
			]
		}
	]
};

export const scheduleNoSchedule = {
	firstAvailable: null,
	timeSlots: null
};

export const scheduleWithManyTimeSlots = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-03-01T00:00:00Z',
			slots: [
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				},
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				},
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				},
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				},
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				},
				{
					start: '2024-02-08T11:00:00Z',
					end: '2024-02-08T11:30:00Z'
				},
				{
					start: '2024-02-08T11:30:00Z',
					end: '2024-02-08T12:00:00Z'
				},
				{
					start: '2024-02-08T12:00:00Z',
					end: '2024-02-08T12:30:00Z'
				}
			]
		}
	]
};

export const scheduleWithManyDays = {
	firstAvailable: null,
	timeSlots: [
		{
			date: '2024-02-26T00:00:00Z',
			slots: [
				{ start: '2024-02-26T08:00:00Z', end: '2024-02-26T08:30:00Z' },
				{ start: '2024-02-26T09:00:00Z', end: '2024-02-26T09:30:00Z' }
			]
		},
		{
			date: '2024-02-27T00:00:00Z',
			slots: [
				{ start: '2024-02-27T08:30:00Z', end: '2024-02-27T09:00:00Z' },
				{ start: '2024-02-27T09:30:00Z', end: '2024-02-27T10:00:00Z' },
				{ start: '2024-02-27T10:30:00Z', end: '2024-02-27T11:00:00Z' },
				{ start: '2024-02-27T11:30:00Z', end: '2024-02-27T12:00:00Z' },
				{ start: '2024-02-27T12:30:00Z', end: '2024-02-27T13:00:00Z' }
			]
		},
		{
			date: '2024-02-28T00:00:00Z',
			slots: [
				{ start: '2024-02-28T08:15:00Z', end: '2024-02-28T08:45:00Z' },
				{ start: '2024-02-28T09:15:00Z', end: '2024-02-28T09:45:00Z' },
				{ start: '2024-02-28T10:15:00Z', end: '2024-02-28T10:45:00Z' },
				{ start: '2024-02-28T11:15:00Z', end: '2024-02-28T11:45:00Z' },
				{ start: '2024-02-28T12:15:00Z', end: '2024-02-28T12:45:00Z' },
				{ start: '2024-02-28T13:15:00Z', end: '2024-02-28T13:45:00Z' },
				{ start: '2024-02-28T14:15:00Z', end: '2024-02-28T14:45:00Z' },
				{ start: '2024-02-28T15:15:00Z', end: '2024-02-28T15:45:00Z' },
				{ start: '2024-02-28T16:15:00Z', end: '2024-02-28T16:45:00Z' },
				{ start: '2024-02-28T17:15:00Z', end: '2024-02-28T17:45:00Z' },
				{ start: '2024-02-28T18:15:00Z', end: '2024-02-28T18:45:00Z' },
				{ start: '2024-02-28T19:15:00Z', end: '2024-02-28T19:45:00Z' },
				{ start: '2024-02-28T20:00:00Z', end: '2024-02-28T20:30:00Z' },
				{ start: '2024-02-28T20:30:00Z', end: '2024-02-28T21:00:00Z' },
				{ start: '2024-02-28T21:00:00Z', end: '2024-02-28T21:30:00Z' },
				{ start: '2024-02-28T21:30:00Z', end: '2024-02-28T22:00:00Z' }
			]
		},
		{
			date: '2024-03-01T00:00:00Z',
			slots: [
				{ start: '2024-03-01T08:45:00Z', end: '2024-03-01T09:15:00Z' },
				{ start: '2024-03-01T09:45:00Z', end: '2024-03-01T10:15:00Z' },
				{ start: '2024-03-01T10:45:00Z', end: '2024-03-01T11:15:00Z' },
				{ start: '2024-03-01T11:45:00Z', end: '2024-03-01T12:15:00Z' },
				{ start: '2024-03-01T12:45:00Z', end: '2024-03-01T13:15:00Z' }
			]
		},
		{
			date: '2024-03-04T00:00:00Z',
			slots: [
				{ start: '2024-03-04T08:00:00Z', end: '2024-03-04T08:30:00Z' },
				{ start: '2024-03-04T08:30:00Z', end: '2024-03-04T09:00:00Z' },
				{ start: '2024-03-04T09:00:00Z', end: '2024-03-04T09:30:00Z' },
				{ start: '2024-03-04T09:30:00Z', end: '2024-03-04T10:00:00Z' },
				{ start: '2024-03-04T10:00:00Z', end: '2024-03-04T10:30:00Z' }
			]
		},
		{
			date: '2024-03-05T00:00:00Z',
			slots: [
				{ start: '2024-03-05T08:15:00Z', end: '2024-03-05T08:45:00Z' },
				{ start: '2024-03-05T09:15:00Z', end: '2024-03-05T09:45:00Z' },
				{ start: '2024-03-05T10:15:00Z', end: '2024-03-05T10:45:00Z' },
				{ start: '2024-03-05T11:15:00Z', end: '2024-03-05T11:45:00Z' },
				{ start: '2024-03-05T12:15:00Z', end: '2024-03-05T12:45:00Z' }
			]
		},
		{
			date: '2024-03-06T00:00:00Z',
			slots: [
				{ start: '2024-03-06T08:00:00Z', end: '2024-03-06T08:30:00Z' },
				{ start: '2024-03-06T08:30:00Z', end: '2024-03-06T09:00:00Z' },
				{ start: '2024-03-06T09:00:00Z', end: '2024-03-06T09:30:00Z' },
				{ start: '2024-03-06T09:30:00Z', end: '2024-03-06T10:00:00Z' },
				{ start: '2024-03-06T10:00:00Z', end: '2024-03-06T10:30:00Z' }
			]
		},
		{
			date: '2024-03-07T00:00:00Z',
			slots: [
				{ start: '2024-03-07T08:45:00Z', end: '2024-03-07T09:15:00Z' },
				{ start: '2024-03-07T09:45:00Z', end: '2024-03-07T10:15:00Z' },
				{ start: '2024-03-07T10:45:00Z', end: '2024-03-07T11:15:00Z' },
				{ start: '2024-03-07T11:45:00Z', end: '2024-03-07T12:15:00Z' },
				{ start: '2024-03-07T12:45:00Z', end: '2024-03-07T13:15:00Z' }
			]
		},
		{
			date: '2024-03-08T00:00:00Z',
			slots: [
				{ start: '2024-03-08T08:00:00Z', end: '2024-03-08T08:30:00Z' },
				{ start: '2024-03-08T08:30:00Z', end: '2024-03-08T09:00:00Z' },
				{ start: '2024-03-08T09:00:00Z', end: '2024-03-08T09:30:00Z' },
				{ start: '2024-03-08T09:30:00Z', end: '2024-03-08T10:00:00Z' },
				{ start: '2024-03-08T10:00:00Z', end: '2024-03-08T10:30:00Z' }
			]
		},
		{
			date: '2024-03-11T00:00:00Z',
			slots: [
				{ start: '2024-03-11T08:15:00Z', end: '2024-03-11T08:45:00Z' },
				{ start: '2024-03-11T09:15:00Z', end: '2024-03-11T09:45:00Z' },
				{ start: '2024-03-11T10:15:00Z', end: '2024-03-11T10:45:00Z' },
				{ start: '2024-03-11T11:15:00Z', end: '2024-03-11T11:45:00Z' },
				{ start: '2024-03-11T12:15:00Z', end: '2024-03-11T12:45:00Z' }
			]
		},
		{
			date: '2024-03-12T00:00:00Z',
			slots: [
				{ start: '2024-03-12T08:00:00Z', end: '2024-03-12T08:30:00Z' },
				{ start: '2024-03-12T08:30:00Z', end: '2024-03-12T09:00:00Z' },
				{ start: '2024-03-12T09:00:00Z', end: '2024-03-12T09:30:00Z' },
				{ start: '2024-03-12T09:30:00Z', end: '2024-03-12T10:00:00Z' },
				{ start: '2024-03-12T10:00:00Z', end: '2024-03-12T10:30:00Z' }
			]
		},
		{
			date: '2024-03-13T00:00:00Z',
			slots: [
				{ start: '2024-03-13T08:45:00Z', end: '2024-03-13T09:15:00Z' },
				{ start: '2024-03-13T09:45:00Z', end: '2024-03-13T10:15:00Z' },
				{ start: '2024-03-13T10:45:00Z', end: '2024-03-13T11:15:00Z' },
				{ start: '2024-03-13T11:45:00Z', end: '2024-03-13T12:15:00Z' },
				{ start: '2024-03-13T12:45:00Z', end: '2024-03-13T13:15:00Z' }
			]
		}
	]
};
