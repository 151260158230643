import React from 'react';
import styled from 'styled-components';
import type { DivProps } from '../../models/widget/Styled';
import { default as SA } from '../Activity/Activity.styled';

const Complaints = styled.div``;

const ComplaintsList = styled(SA.ComplaintsList)``;

const Complaint = styled(SA.Complaint)``;

const ComplaintButton = styled(SA.ComplaintButton)``;

export default {
	Complaints: (props: DivProps) => (
		<Complaints {...props} data-minddclass="complaints">
			{props.children}
		</Complaints>
	),
	ComplaintsList,
	Complaint,
	ComplaintButton
};
