import styled, { keyframes } from 'styled-components';
import { LoaderConfig as LOADER } from './LoaderConfig';

interface MindLogoProps {
	$slidingBackgroundUrl: string;
}

const slideBackground = keyframes`
	0% { background-position: 0% top; }
	100% { background-position: 100% top; }
`;

const drawBorder = keyframes`
	0% { 
		stroke-dashoffset: ${LOADER.STROKE_LENGTH};		
		opacity: 0.5; 
	}
	20% { 
		opacity: 1; 
	}
	80% { 
		opacity: 1; 
	}
	100% { 
		stroke-dashoffset: 0; 
		opacity: 0.5;
	}
`;

export default {
	LoaderWrapper: styled.div`
		position: relative;
		width: ${LOADER.SIZE}px;
		height: ${LOADER.SIZE}px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20vh auto 2vh auto;
	`,
	LoaderSquare: styled.div<MindLogoProps>`
		width: ${LOADER.INNER_SIZE}px;
		height: ${LOADER.INNER_SIZE}px;
		border-radius: ${LOADER.BORDER_RADIUS}px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url(${(props) => props.$slidingBackgroundUrl});
		background-size: 280% auto;
		background-position: 0% top;
		animation: ${slideBackground} ${LOADER.ANIMATION_DURATION}s ease-in-out;
		z-index: 2;
	`,
	SVGContainer: styled.div`
		width: 100%;
		height: 100%;
		position: relative;

		> svg {
			position: absolute;
			top: 75%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	`,
	AnimatedBorder: styled.svg`
		position: absolute;
		top: 0;
		left: 0;
		width: ${LOADER.SIZE}px;
		height: ${LOADER.SIZE}px;
		stroke: #5d98a8;
		stroke-width: ${LOADER.STROKE_WIDTH}px;
		fill: none;
		stroke-dasharray: ${LOADER.STROKE_LENGTH};
		stroke-dashoffset: ${LOADER.STROKE_LENGTH};
		animation: ${drawBorder} ${LOADER.ANIMATION_DURATION}s ease-in-out;
		z-index: 1;
	`
};
