import React from 'react';
import ReactDOM from 'react-dom/client';

import { StateProvider } from './helpers/hooks/useStateContext';
import { InitialState } from './helpers/constants/initialState';
import { configureMinddApiAxios } from './helpers/services/AxiosInstance';
import type { WidgetConfig } from './models';

import App from './components/App/App';
import './css-variables.css';
import './assets/fonts/inter.css';

export const init = (config: WidgetConfig) => {
	let el: HTMLElement | null = null;

	if (typeof config.target === 'string') {
		el = document.querySelector(config.target);
	}

	if (!el) {
		el = document.getElementById('MINDD');
	}

	if (el) {
		// Configure our axios instance with a base URL and the supplied API key
		configureMinddApiAxios(process.env.REACT_APP_API ?? 'https://api.moetiknaardedokter.nl/api');

		config.target = el;
		const text = el.innerText; // The element innerText is used when the widget is of type button

		if (typeof config.welcome_text === 'string') {
			config.welcome_text = {
				NL: config.welcome_text
			};
		}

		if (config.labels) {
			if (typeof config.labels.QuestionWhatGender === 'string') {
				config.labels.QuestionWhatGender = {
					NL: config.labels.QuestionWhatGender
				};
			}

			if (typeof config.labels.QuestionWhatSearchTriage === 'string') {
				config.labels.QuestionWhatSearchTriage = {
					NL: config.labels.QuestionWhatSearchTriage
				};
			}
		}

		const initialState = InitialState(config);
		const root = ReactDOM.createRoot(el);

		root.render(
			<React.StrictMode>
				<StateProvider initialState={initialState}>
					<App config={config}>{text}</App>
				</StateProvider>
			</React.StrictMode>
		);
	} else {
		const targetString: string = typeof config.target === 'string' ? config.target : '{unknown target}';
		console.error(`MINDD Widget failed: Could not find an element for config.target value '${targetString}' or with the ID 'MINDD'.`);
	}
};

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
