import React, { FunctionComponent } from 'react';
import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import SP from '../Procedure/Procedure.styled';

interface FieldLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	valid?: boolean | null;
	required?: boolean;
	optional?: boolean;
}

const FieldLabel: FunctionComponent<FieldLabelProps> = (props) => {
	const [{ settings }] = useStateContext();
	return (
		<SP.Label
			htmlFor={props.htmlFor}
			required={props.required}
			data-optional={!props.required}
			$valid={props.valid}
			$requiredLabel={getLabel('FormFieldRequiredLabel', settings.applicationTexts, true)}
		>
			{props.children}
		</SP.Label>
	);
};

export default FieldLabel;
