import styled from 'styled-components';
import { Drawer } from 'vaul';

const DrawerWrapper = styled.div`
	& div[vaul-drawer] {
		z-index: 999999999 !important;
	}
`;

const DrawerOverlay = styled(Drawer.Overlay)`
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 999999999 !important;
`;

const DrawerContent = styled(Drawer.Content)`
	max-height: 80vh;
	background-color: #f3f4f6;
	display: flex;
	flex-direction: column;
	margin-top: calc(2 * ${(props) => props.theme.spacings.extraExtraExtraLarge});
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
`;

const DrawerInnerContent = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: ${(props) => props.theme.spacings.medium};
	background-color: white;
	flex-grow: 1;
`;

const DrawerHandle = styled.div`
	width: ${(props) => props.theme.spacings.extraExtraExtraLarge};
	height: calc(0.375 * ${(props) => props.theme.spacings.medium});
	background-color: #d1d5db;
	border-radius: full;
	margin-left: auto;
	margin-right: auto;
`;

const DrawerChildrenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-width: 100%;
	//margin-left: auto;
	//margin-right: auto;
`;

const S = {
	DrawerWrapper,
	DrawerOverlay,
	DrawerContent,
	DrawerInnerContent,
	DrawerHandle,
	DrawerChildrenWrapper
};

export default S;
