/**
 * Dialog functionality inspired by primereact's confirm dialog
 * https://github.com/primefaces/primereact
 */

import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import S from './Dialog.styled';
import { FocusOn } from 'react-focus-on';

const Dialog: FunctionComponent = () => {
	const [
		{
			settings,
			dialog: {
				open,
				header,
				message,
				onConfirm,
				confirmPromiseResolve,
				onCancel,
				closeOnBackdropClick = true,
				style = 'primary',
				confirmLabel,
				cancelLabel,
				position = 'center',
				buttonLayout
			}
		},
		dispatch
	] = useStateContext();
	const dialogRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const themeContext = useTheme();
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;
	const cancelButton = useRef<HTMLButtonElement>() as React.RefObject<HTMLButtonElement>;

	useEffect(() => {
		if (open) {
			cancelButton.current?.focus();
		}
	}, [open]);

	const handleBackdropClick = (e: React.MouseEvent) => {
		if (closeOnBackdropClick && e.target !== dialogRef.current && !dialogRef.current?.contains(e.target as Node)) {
			handleCancel();
		}
	};

	const closeDialog = () => {
		dispatch({
			type: 'dialog/setState',
			state: {
				open: false
			}
		});
	};

	const handleCancel = () => {
		confirmPromiseResolve?.(false);
		onCancel?.();
		closeDialog();
	};

	const handleConfirm = () => {
		confirmPromiseResolve?.(true);
		onConfirm?.();
		closeDialog();
	};

	const areButtonsHorizontal = useMemo(() => (
		buttonLayout
			? buttonLayout === 'horizontal'
			: !isMobile
	), [buttonLayout, isMobile]);

	return (
		<S.Backdrop onClick={handleBackdropClick} $visible={open}>
			{open && (
				<S.FocusOn $position={position}>
					<S.Dialog $position={position} ref={dialogRef}>
						<S.Header>{header}</S.Header>
						<S.Content>{message}</S.Content>
						<S.Buttons $horizontal={areButtonsHorizontal}>
							<S.Button onClick={handleCancel} $secondary ref={cancelButton} tabIndex={0}>
								{cancelLabel || getLabel('DialogCancel', settings.applicationTexts, true)}
							</S.Button>
							<S.Button onClick={handleConfirm} $danger={style === 'danger'} tabIndex={0}>
								{confirmLabel || getLabel('DialogConfirm', settings.applicationTexts, true)}
							</S.Button>
						</S.Buttons>
					</S.Dialog>
				</S.FocusOn>
			)}
		</S.Backdrop>
	);
};

export default Dialog;
