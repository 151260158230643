import { logAxiosError, minddApiAxios } from './AxiosInstance';
import type { Doctor } from '../../models';

export type GetDoctorsInput = {
	doctorLocation?: string;
	type: 'Huisarts' | 'Huisartsenpost';
	filterUsingGPPostCountryFilter: boolean;
};

export type GetDoctorsOutput = {
	doctors: Doctor[];
};

async function CallGetDoctors(apiKey: string, input: GetDoctorsInput): Promise<Doctor[]> {
	try {
		const { data } = await minddApiAxios.post<GetDoctorsOutput>('GetDoctors?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data.doctors;
	} catch (error) {
		logAxiosError(error, 'CallGetDoctors');
	}

	return [];
}

export { CallGetDoctors };
