import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type PostDoctorInput = SessionInput & {
	doctorId?: number;
};

async function CallPostDoctor(apiKey: string, input: PostDoctorInput) {
	try {
		const { status } = await minddApiAxios.post('PostDoctor?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostDoctor');
	}

	return false;
}

export { CallPostDoctor };
