// **Adjustable Configuration Values**
// These values can be modified to change the appearance and behavior of the loader
const SIZE = 162; // Total size of the loader container (including stroke)
const INNER_SIZE = 154; // Size of the inner square (sliding background & logo)
const BORDER_RADIUS = 33;
const ANIMATION_DURATION = 4; // Duration in seconds

// **Calculated Values**
const STROKE_WIDTH = SIZE - INNER_SIZE; // Note: Only half of the stroke width will be visible
const SIDE_LENGTH = INNER_SIZE - 2 * BORDER_RADIUS; // Straight segment length of the rounded rectangle
const CORNERS_LENGTH = 2 * Math.PI * BORDER_RADIUS; // Combined length of the four rounded corners
const STROKE_LENGTH = 4 * SIDE_LENGTH + CORNERS_LENGTH; // Total stroke path length for animation

export const LoaderConfig = {
	SIZE,
	INNER_SIZE,
	BORDER_RADIUS,
	STROKE_WIDTH,
	STROKE_LENGTH,
	ANIMATION_DURATION
};
