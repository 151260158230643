import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { Gender, Question } from '../../models';

export type AbcdTriageStartQuestionInput = SessionInput &
	LanguageInput & {
		gender?: Gender | null;
		age: number | string;
		birthdate?: Date;
	};

export type AbcdTriageStartQuestionOutput = {
	startQuestion: Question;
	empathy?: string;
};

async function CallAbcdTriageStartQuestion(apiKey: string, input: AbcdTriageStartQuestionInput): Promise<AbcdTriageStartQuestionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<AbcdTriageStartQuestionOutput>('AbcdTriageStartQuestion?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallAbcdTriageStartQuestion');
	}

	return undefined;
}

export { CallAbcdTriageStartQuestion };
