import styled from 'styled-components';

interface RadioWrapperProps {
	checked?: boolean;
}

interface RadioGroupWrapperProps {
	$modalOpen: boolean;
}

const RadioGroupWrapper = styled.div<RadioGroupWrapperProps>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-top: ${(props) => (props.$modalOpen ? props.theme.spacings.medium : props.theme.spacings.extraSmall)};
`;

const RadioWrapper = styled.div<RadioWrapperProps>`
	display: block;
	position: relative;
	padding-left: ${(props) => props.theme.spacings.large};
	cursor: pointer;
	user-select: none;
	background: ${(props) => (props.checked ? props.theme.colors.accent100 : 'transparent')};
	border-style: solid;
	border-width: ${(props) => (props.checked ? '2px' : '1px')};
	border-color: ${(props) => (props.checked ? props.theme.colors.accent : 'rgba(0, 0, 0, 0.1)')};
	border-radius: ${(props) => props.theme.radii.default};
	padding-top: 8px;
	padding-bottom: 8px;

	label {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 0;
	}

	.labeltext {
		margin-left: calc(1.25 * ${(props) => props.theme.spacings.medium});
		padding-left: calc(0.625 * ${(props) => props.theme.spacings.medium});
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
	.checkmark {
		position: absolute;
		height: 20px;
		width: 20px;
		background-color: #fff;
		border-radius: 50%;
		border: 3px solid #333333;
		cursor: pointer;
	}
	input:checked ~ .checkmark {
		border-width: 5px;
	}
`;

const S = {
	RadioWrapper,
	RadioGroupWrapper
};

export default S;
