import React, { useState, useCallback, FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import S from './styled';

import Button from '../Button';
import { useResetWidget } from '../../helpers/hooks/useResetWidget';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { CallPostFeedback } from '../../helpers/services';
import { validateSession } from '../../helpers/support/validateSession';
import type { ActivityProps } from '../Activity';

type ActivityAction = 'close' | 'restart';

const ActivityFeedback: FunctionComponent<ActivityProps> = (props) => {
	const [{ settings, session }] = useStateContext();
	const resetWidget = useResetWidget();

	const [star, setStar] = useState(0);
	const [comment, setComment] = useState('');
	const [callStatus, setCallStatus] = useState<boolean | null>(null);
	const [validationStatus, setvalidationStatus] = useState<boolean | null>(null);
	const themeContext = useTheme();

	const handleToggleModal = useToggleModal();

	const handleFeedback = async () => {
		if (star && comment) {
			const result = await CallPostFeedback(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				feedbackValue: star,
				feedbackComment: comment
			});

			if (result) {
				setCallStatus(true);
				setvalidationStatus(true);
			} else {
				setvalidationStatus(false);
			}
		} else {
			setvalidationStatus(false);
		}
	};

	const handleDefaultAction = (type: ActivityAction) => {
		if (type === 'close') {
			void handleToggleModal();
		} else if (type === 'restart') {
			void resetWidget(true);
		}
	};

	const handleSetComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	};

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	return (
		<SA.ActivityBubble>
			{!callStatus && (
				<React.Fragment>
					<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
						{getLabel('FeedbackScore', settings.applicationTexts)}
					</SA.BubbleTitle>

					<S.Stars>
						{[5, 4, 3, 2, 1].map((value, index) => {
							return (
								<S.Star key={index} onClick={() => setStar(value)}>
									<svg style={{ width: 32, height: 32 }}>
										<g id="icon-star">
											<path
												d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
														l11.547-1.2L16.026,0.6L20.388,10.918z"
												stroke="2"
												fill={value <= star ? themeContext.colors.accent : '#fff'}
											/>
										</g>
									</svg>
								</S.Star>
							);
						})}
					</S.Stars>

					<SA.BubbleTitle>{getLabel('FeedbackComment', settings.applicationTexts)}</SA.BubbleTitle>
					<p>{getLabel('FeedbackCommentSubTitle', settings.applicationTexts)}</p>

					<S.Comment onChange={(e) => handleSetComment(e)}></S.Comment>

					{validationStatus === false && <SP.Error>{getLabel('FeedbackRequestError', settings.applicationTexts)}</SP.Error>}
					<SA.ActivityActions>
						<Button disabled={callStatus || !validateSession(session)} onClick={() => void handleFeedback()}>
							{getLabel('FeedbackSendLabel', settings.applicationTexts)}
						</Button>
						<Button onClick={() => handleDefaultAction('close')}>{getLabel('FeedbackLabelClose', settings.applicationTexts)}</Button>
					</SA.ActivityActions>
				</React.Fragment>
			)}
			{callStatus && (
				<React.Fragment>
					<SA.BubbleTitle>{getLabel('FeedbackThankYou', settings.applicationTexts)}</SA.BubbleTitle>
					<SA.ActivityActions>
						<Button onClick={() => handleDefaultAction('restart')}>{getLabel('FeedbackLabelAgain', settings.applicationTexts)}</Button>
						<Button onClick={() => handleDefaultAction('close')}>{getLabel('FeedbackLabelClose', settings.applicationTexts)}</Button>
					</SA.ActivityActions>
				</React.Fragment>
			)}
		</SA.ActivityBubble>
	);
};

export default ActivityFeedback;
