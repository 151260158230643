import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import S from './FormComponents.styled';
import SP from '../Procedure/Procedure.styled';

import FieldLabel from './FieldLabel';
import InputPhone, { parseInputPhoneNumber } from './InputPhone';

type InputOldValue = { oldValue?: string; oldSelectionStart?: number | null; oldSelectionEnd?: number | null };

export const setInputFilter = (textbox: React.RefObject<HTMLInputElement | undefined>, inputFilter: (value: string) => boolean) => {
	const eventListener: (this: HTMLInputElement & InputOldValue) => void = function () {
		if (inputFilter(this.value)) {
			this.oldValue = this.value;
			this.oldSelectionStart = this.selectionStart;
			this.oldSelectionEnd = this.selectionEnd;
		} else if (this.oldValue !== undefined && this.oldSelectionStart !== undefined && this.oldSelectionEnd !== undefined) {
			this.value = this.oldValue;
			this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
		} else {
			this.value = '';
		}
	};

	['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
		textbox.current?.addEventListener(event, eventListener);
	});
};

interface InputProps<T> extends React.InputHTMLAttributes<T> {
	name: string;
	$label: string | JSX.Element;
	$valid?: boolean | null;
	$invalidError?: string;
	required?: boolean;
	$controlled?: boolean;
	info?: string;
}

interface InputHapLocationProps extends InputProps<HTMLSelectElement> {
	$hapLocations: { title?: string }[];
}

interface InputCallbackLocationProps extends InputProps<HTMLSelectElement> {
	$locationsForCallbackConfigs: { title?: string }[];
	$placeholder?: string;
}

interface FieldLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	valid?: boolean | null;
	required?: boolean;
	optional?: boolean;
}

interface TextAreaProps extends InputProps<HTMLTextAreaElement> {
	maxLength?: number;
}

const InputCheckBox: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && !!props.$invalidError;
	return (
		<React.Fragment>
			<SP.RowConsent>
				<SP.Field id={props.name} {...{ ...props, value: undefined }} />
				<SP.LabelInline htmlFor={props.name}>{props.$label}</SP.LabelInline>
			</SP.RowConsent>
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const InputText: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	return (
		<React.Fragment>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.Field type="text" {...{ ...props, value: props.$controlled ? props.value : undefined }} autoComplete="off" />
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const InputDate: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && props.value !== '' && !!props.$invalidError;
	return (
		<React.Fragment>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<S.DateField type="date" {...{ ...props, value: undefined }} autoComplete="off" />
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const InputFile: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	return (
		<React.Fragment>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.Field type="file" {...{ ...props, value: undefined }} />
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const InputNumber: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	const inputNumberField = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setInputFilter(inputNumberField, function (value) {
			return /^[0-9]*$/.test(value);
			//return /^[\d*(.?)\s\d]*$/.test(value); // Allow digits and '.' only, using a RegExp
		});
	}, []);

	return (
		<React.Fragment>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.Field ref={inputNumberField} type="text" pattern="[0-9]*" {...{ ...props, value: undefined }} autoComplete="off" />
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const InputGender: FunctionComponent<InputProps<HTMLSelectElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	const [{ settings }] = useStateContext();

	return (
		<>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.FieldSelect {...{ ...props, value: undefined }}>
				<option value=""></option>
				<option value="Male">{getLabel('GenderSelectorMaleLabel', settings.applicationTexts, true)}</option>
				<option value="Female">{getLabel('GenderSelectorFemaleLabel', settings.applicationTexts, true)}</option>
			</SP.FieldSelect>
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</>
	);
};

const InputHapLocation: FunctionComponent<InputHapLocationProps> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	return (
		<>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.FieldSelect {...{ ...props, value: undefined }}>
				<option value=""></option>
				{props.$hapLocations
					.filter((i) => i.title && i.title.length > 0)
					.map((i) => (
						<option key={i.title} value={i.title}>
							{i.title}
						</option>
					))}
			</SP.FieldSelect>
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</>
	);
};

const InputCallbackLocation: FunctionComponent<InputCallbackLocationProps> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	return (
		<>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.FieldSelect {...{ ...props, value: props.$controlled ? props.value : undefined }}>
				{/* If a placeholder is provided use disabled so it can't be re-selected, 
				    also make sure that the initial value is not undefined 
					otherwise disabled option is skipped and the next option is selected */}
				<option value="" disabled={!!props.$placeholder}>
					{props.$placeholder}
				</option>
				{props.$locationsForCallbackConfigs
					.filter((i) => i.title && i.title.length > 0)
					.map((i) => (
						<option key={i.title} value={i.title}>
							{i.title}
						</option>
					))}
			</SP.FieldSelect>
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</>
	);
};

const InputZipCode: FunctionComponent<InputProps<HTMLInputElement>> = (props) => {
	const showError = props.$valid === false && props.value !== undefined && !!props.$invalidError;
	const inputZipCodeField = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setInputFilter(inputZipCodeField, function (value) {
			return /^$|^[1-9][0-9]{0,3}$|^[1-9][0-9]{3}\s?[A-Za-z]{0,2}$/.test(value);
		});
	}, []);

	return (
		<React.Fragment>
			<FieldLabel htmlFor={props.name} required={props.required} data-optional={!props.required} valid={props.$valid}>
				{props.$label}
			</FieldLabel>
			<SP.Field ref={inputZipCodeField} type="text" pattern="[1-9][0-9]{3}\s?[A-Za-z]{0,2}" {...{ ...props, value: undefined }} autoComplete="off" />
			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{props.$invalidError}
				</SP.Error>
			)}
		</React.Fragment>
	);
};

const TextArea: FunctionComponent<InputProps<HTMLTextAreaElement>> = ({
	$label: label,
	name,
	disabled,
	onChange,
	maxLength = 300,
	value = '',
	style,
	$valid: valid,
	$invalidError: invalidError,
	required,
	info
}) => {
	const [length, setLength] = useState(Array.isArray(value) ? value.join('').length : `${value as string | number}`.length);
	const [_value, setValue] = useState(value);

	const showError = valid === false && value !== undefined && !!invalidError;

	const handleChangeFormField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
		setLength(e.target.value.length);
		onChange?.(e);
	};

	useEffect(() => {
		setValue(value);
	}, [value]);

	return (
		<div style={style}>
			{label && (
				<FieldLabel htmlFor={name} required={required} data-optional={!required} valid={valid}>
					{label}
				</FieldLabel>
			)}
			<SP.FieldTextArea
				value={_value}
				id={name}
				onChange={handleChangeFormField}
				name={name}
				autoComplete="off"
				disabled={disabled}
				maxLength={maxLength}
				required={required}
				$valid={valid}
			/>

			{info ? (
				<div className="textarea-info-character-counter">
					<span>
						<FontAwesomeIcon icon={faExclamationTriangle} />
						<span>{info}</span>
					</span>
					<span>
						{length || 0}/{maxLength}
					</span>
				</div>
			) : (
				<div className="textarea-character-counter">
					<span>
						{length || 0}/{maxLength}
					</span>
				</div>
			)}

			{showError && (
				<SP.Error>
					<FontAwesomeIcon icon={faExclamationCircle} />
					{invalidError}
				</SP.Error>
			)}
		</div>
	);
};

export {
	FieldLabel,
	InputText,
	InputNumber,
	InputCheckBox,
	InputDate,
	InputFile,
	InputGender,
	InputHapLocation,
	InputCallbackLocation,
	InputZipCode,
	TextArea,
	InputPhone,
	parseInputPhoneNumber
};
