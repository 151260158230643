import styled from 'styled-components';

export const TRANSITION_TIMING = 200;

interface ExpandableTextBoxProps {
	$expanded: boolean;
	$dark: boolean;
}

interface ExpandableTextBoxHeaderProps {
	$disabled?: boolean;
}

const ExpandableTextBoxHeader = styled.div<ExpandableTextBoxHeaderProps>`
	height: 30px;
	padding: calc((30px - ${(props) => props.theme.spacings.medium}) / 2) ${(props) => props.theme.spacings.extraSmall};
	cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
	position: relative;
`;

const ExpandableTextBoxContent = styled.div`
	overflow: hidden;
	transition: max-height ${TRANSITION_TIMING}ms ease-in-out;
	padding-right: ${(props) => props.theme.spacings.medium};
`;

const ExpandableTextBoxButton = styled.button.attrs({})`
	&&& {
		inset-inline-end: 0;
		background: none;
		border: none;
		position: absolute;
		top: calc(-25px + ${(props) => props.theme.spacings.medium});
		right: 18px;
		color: black;
		padding: ${(props) => props.theme.spacings.medium} 0;
		svg {
			width: 20px;
			height: 20px;
		}

		&:focus {
			outline-color: transparent;
		}
		&:focus-visible {
			outline-color: black;
		}
	}
`;

const ExpandableTextBox = styled.div<ExpandableTextBoxProps>`
	&&& {
		background: #fff; // TODO: color 'elevated surface'?
		height: auto;
		/* padding: 0; */
		position: relative;
		overflow: hidden;
		button:not(.expandableTextButton) {
			display: ${(props) => (props.$expanded ? null : 'none !important')};
		}
		button.expandableTextButton {
			outline: none !important;
		}

		& svg {
			transition: transform 0.2s ease-in-out;
		}
		${(props) =>
			props.$expanded &&
			`
				& svg {
					transform: rotate(180deg);
				}
		`}

		@media ${(props) => props.theme.screens.medium} {
			border: none !important;
			background: ${(props) => (props.$dark ? props.theme.colors.text200 : 'none')};
			&:not(.no-border) {
				border-radius: ${(props) => props.theme.radii.default};
				/* border: solid 1px ${(props) => props.theme.colors.text200}; */
			}

			& ${ExpandableTextBoxButton} {
				display: none;
			}
		}
	}
`;

const components = {
	ExpandableTextBox,
	ExpandableTextBoxButton,
	ExpandableTextBoxHeader,
	ExpandableTextBoxContent
};

export default components;
