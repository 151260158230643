import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import S from './styled';

const Modal: FunctionComponent<React.PropsWithChildren> = (props) => {
	const [
		{
			modal,
			settings,
			conversation,
			dialog: { confirm }
		}
	] = useStateContext();
	const handleToggleModal = useToggleModal();
	const modalRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const nonModalRef = useRef(null);

	useEffect(() => {
		const windowResized = () => {
			if (modalRef.current) {
				modalRef.current.style.setProperty('--document-height', `${window.innerHeight}px`);
			}
		};

		if (modal.open) {
			window.addEventListener('resize', windowResized);
			windowResized();

			return () => {
				window.removeEventListener('resize', windowResized);
			};
		}
	}, [modal.open]);

	useEffect(() => {
		const handleEscKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				void handleToggleModal(false);
			}
		};

		if (modal.open) {
			window.addEventListener('keydown', handleEscKeyPress);

			return () => {
				window.removeEventListener('keydown', handleEscKeyPress);
			};
		}

		// Without handleToggleModal in dependencies it doesn't correctly capture the conversation and can't determine
		// if the converstation is finished when closing the modal
	}, [modal.open, handleToggleModal]);

	const onClickOutsideHandler = () => {
		if (settings.clickOutsideModalClosesModal) {
			void handleToggleModal();
		}
	};

	if (modal.open) {
		return (
			<Transition nodeRef={modalRef} in={true} timeout={0} appear={true}>
				{(state) => (
					<S.Modal ref={modalRef} $state={state} $mode={settings.modalDisplayMode}>
						<S.FocusOn $mode={settings.modalDisplayMode} onClickOutside={onClickOutsideHandler}>
							<S.ModalInner $mode={settings.modalDisplayMode}>{props.children}</S.ModalInner>
						</S.FocusOn>
					</S.Modal>
				)}
			</Transition>
		);
	} else {
		return (
			<Transition nodeRef={nonModalRef} in={true} timeout={0} appear={true}>
				{(state) => (
					<S.NonModal ref={nonModalRef} $state={state} $widgetTypeButton={settings.widgetType === 'Button'}>
						{props.children}
					</S.NonModal>
				)}
			</Transition>
		);
	}
};

export default Modal;
