import { useCallback } from 'react';
import { getLabel } from '../constants/getLabels';

import { useResetWidget } from './useResetWidget';
import { useStateContext } from './useStateContext';
import { ActivityStepAdvice } from '../../models';

export const useToggleModal = () => {
	const [
		{
			modal,
			conversation,
			session,
			settings,
			dialog: { confirm }
		},
		dispatch
	] = useStateContext();
	const resetWidget = useResetWidget();

	return useCallback(
		async (immediate = true) => {
			const adviceHasBeenShown = conversation.some((step) => step.type === 'advice' && step.visited);
			const contactFormActive = (conversation.find((step) => step.type === 'advice') as ActivityStepAdvice | undefined)?.advice
				.startContactRequestProcedure;
			const contactRequestStarted = conversation.some((step) => ['requestCallback', 'consultationPreparation'].includes(step.type));
			const contactFormHasBeenCompleted = conversation.some((step) => step.type === 'requestCallbackEnd');
			const adviceWillBeSkipped = settings.skipAdvicePageActive;
			const adviceUrgence = (conversation.find((step) => step.type === 'advice') as ActivityStepAdvice | undefined)?.advice?.urgence ?? null;

			const getDialogLabels = () => {
				if (!adviceHasBeenShown && !contactRequestStarted) {
					// We are still in the middle of a triage
					return adviceWillBeSkipped
						? {
								message: getLabel('CloseWidgetUnfinishedTriageWithoutAdviceMessage', settings.applicationTexts, true)
						  }
						: {
								message: getLabel('CloseWidgetUnfinishedTriageWithAdviceMessage', settings.applicationTexts, true)
						  };
				}

				if (adviceHasBeenShown && !contactFormActive) {
					// We finished the triage, there is no callback form
					return undefined;
				}

				if (adviceHasBeenShown && contactFormActive && !contactRequestStarted) {
					// We finished the triage, a callback option was provided, but the user did not enter callback form
					return undefined;
				}

				if (adviceHasBeenShown && contactRequestStarted && !contactFormHasBeenCompleted) {
					// We finished the triage, opened the callback form, but did not finish the callback procedure
					return {
						message: getLabel('CloseWidgetUnfinishedCallbackProcedureMessage', settings.applicationTexts, true)
					};
				}

				// We finished the callback procedure
				return undefined;
			};

			const previousOpenValue = modal.open;
			const newOpenValue = !modal.open;
			const showDialogWarning = !adviceHasBeenShown || (adviceHasBeenShown && contactRequestStarted && !contactFormHasBeenCompleted);

			if (!newOpenValue && showDialogWarning && !immediate) {
				const labels = getDialogLabels();
				if (
					!(await confirm({
						header: getLabel('CloseWidgetUnfinishedWarningHeader', settings.applicationTexts, true),
						message: getLabel('CloseWidgetUnfinishedWarningMessage', settings.applicationTexts, true),
						cancelLabel: getLabel('CloseWidgetCancel', settings.applicationTexts, true),
						confirmLabel: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
						...labels
					}))
				) {
					return false;
				}
			}

			dispatch({
				type: 'updateModal',
				modal: {
					...modal,
					open: newOpenValue
				}
			});

			// We reset the profile when closing the model, so previous body area etc. is not remembered
			if (previousOpenValue) {
				// Is open now, so is closing
				await resetWidget(false);
			}

			if (newOpenValue) {
				if (settings.target instanceof Element) {
					settings.target.dispatchEvent(new CustomEvent('minddWidgetOpened', { bubbles: true }));
				}
				if (typeof settings.onWidgetOpened === 'function') {
					settings.onWidgetOpened.call(null);
				}
			} else {
				const finished = adviceHasBeenShown || contactRequestStarted;

				if (settings.target instanceof Element) {
					settings.target.dispatchEvent(
						new CustomEvent('minddWidgetClosed', { bubbles: true, detail: { finished: finished, sessionId: session.id, urgence: adviceUrgence } })
					);
				}
				if (typeof settings.onWidgetClosed === 'function') {
					settings.onWidgetClosed.call(null, finished, session.id, adviceUrgence);
				}
			}

			return true;
		},
		[
			modal,
			session.id,
			conversation,
			conversation.length,
			dispatch,
			confirm,
			resetWidget,
			settings.onWidgetOpened,
			settings.onWidgetClosed,
			settings.applicationTexts,
			settings.target
		]
	);
};
