import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { SessionInput } from './BaseInput';

export type PostFeedbackInput = SessionInput & {
	feedbackValue: number;
	feedbackComment?: string;
};

async function CallPostFeedback(apiKey: string, input: PostFeedbackInput) {
	try {
		const { status } = await minddApiAxios.post('PostFeedback?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return status === 200;
	} catch (error) {
		logAxiosError(error, 'CallPostFeedback');
	}

	return false;
}

export { CallPostFeedback };
