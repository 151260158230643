import React, { FunctionComponent, MutableRefObject } from 'react';
import S from './CompactWidget.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { WelcomeText } from '../ActivityWelcome/ActivityWelcome';
import LanguageSelector from '../LanguageSelector';
import StartButton from '../StartButton/StartButton';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import { useTheme } from 'styled-components';
import useWidgetContainerSize from '../../helpers/hooks/useWidgetContainerSize';

interface CompactWidgetProps {
	onStart: () => void;
	appRef: MutableRefObject<HTMLDivElement>;
}

const CompactWidget: FunctionComponent<CompactWidgetProps> = (props) => {
	const [{ settings }] = useStateContext();
	const showWelcomeText = settings.widget_showWelcomeText;
	const showLanguageSelector = settings.showLanguageSelector && settings.useLanguageSelector;
	const themeContext = useTheme();
	const isSmall = useWidgetContainerSize(props.appRef).width < themeContext.breakpoints.medium;

	return (
		<S.CompactWidget>
			{showLanguageSelector && isSmall && (
				<S.LanguageSelectorWrapper>
					<LanguageSelector />
				</S.LanguageSelectorWrapper>
			)}
			<S.WelcomeTextStartButtonWrapper $isSmall={isSmall} $showWelcomeText={showWelcomeText}>
				{showWelcomeText && (
					<S.WelcomeTextWrapper $isSmall={isSmall}>
						<WelcomeText />
					</S.WelcomeTextWrapper>
				)}
				<S.StartButtonWrapper>{<StartButton onStart={() => void props.onStart()} />}</S.StartButtonWrapper>
			</S.WelcomeTextStartButtonWrapper>
			<S.CompactWidgetFooter $isSmall={isSmall}>
				{showLanguageSelector && !isSmall && <LanguageSelector />}
				{/* {!settings.hide_footer && <Logo />} */} {/* TODO: not clear yet what should be done with the mindd logo */}
				{<TermsOfUse />}
			</S.CompactWidgetFooter>
		</S.CompactWidget>
	);
};

export default CompactWidget;
