import React, { useState, useCallback, useEffect, FunctionComponent } from 'react';

import { useTheme } from 'styled-components';

import Select, { SingleValue } from 'react-select';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallGetDoctors, CallPostDoctor } from '../../helpers/services';
import { getLabel } from '../../helpers/constants/getLabels';
import useSelectStyles from '../../helpers/styled/Select';
import Preloader from '../Preloader/Preloader';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';
import { ActivityProps } from '../Activity';
import StateManagedSelect from 'react-select';

interface DoctorOption {
	value: number;
	label: string;
}

const ActivityGPPost: FunctionComponent<ActivityProps> = (props) => {
	const [{ settings, session }, dispatch] = useStateContext();
	const themeContext = useTheme();

	const [doctorOptions, setDoctorOptions] = useState<DoctorOption[]>([]);
	const [doctorId, setDoctorId] = useState(0);
	const [isSending, setIsSending] = useState(false);
	const [validationStatus, setvalidationStatus] = useState<boolean | null>(null);
	const selectStyles = useSelectStyles<DoctorOption>(themeContext);
	const stepNumber = useCurrentStepNumber(props.id);

	useEffect(() => {
		const fetchDoctors = async () => {
			const doctors = await CallGetDoctors(settings.ApiKey, {
				type: 'Huisartsenpost',
				filterUsingGPPostCountryFilter: true
			});

			if (doctors) {
				const mappedDoctors = doctors.map((d) => ({
					value: d.id,
					label: d.title ?? ''
				}));
				setDoctorOptions(mappedDoctors);
			}
		};
		void fetchDoctors();
	}, [settings.ApiKey]);

	const handleSelectOnChange = (newValue: SingleValue<DoctorOption>) => {
		setDoctorId(newValue?.value ?? 0);
	};

	const handleSendData = async () => {
		setIsSending(true);
		props.setDisableNext(true);
		const valid = doctorId !== 0;

		if (valid) {
			setvalidationStatus(true);

			const result = await CallPostDoctor(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				doctorId: doctorId
			});

			if (result) {
				await props.handleActivityResponse({ status: 'answered', doctorId: doctorId });
				setIsSending(false);
				return true;
			} else {
				setvalidationStatus(false);
				setIsSending(false);
			}
		} else {
			setvalidationStatus(false);
			setIsSending(false);
		}

		props.setDisableNext(false);
		return false;
	};

	useEffect(() => {
		props.setHandleNext(() => () => {
			handleSendData()
				.then((success) => {
					if (success) {
						props.handleNext();
					}
				})
				.catch(() => {
					setvalidationStatus(false);
				});
		});
	}, [doctorId]);

	useEffect(() => {
		if (doctorId !== 0) {
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: { status: 'answered', doctorId }
			});
		} else {
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: undefined
			});
		}
	}, [doctorId]);

	return (
		<SA.ActivityBubble>
			<ActivityBubbleTitle
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
				stepNumber={stepNumber}
				title={getLabel('WidgetAskGPPostRequestLabel', settings.applicationTexts)}
			/>

			<SP.Form>
				<SP.Row>
					<Select<DoctorOption>
						autoFocus
						options={doctorOptions}
						styles={selectStyles}
						placeholder={getLabel('WidgetAskGPPostPlaceholder', settings.applicationTexts, true)}
						isDisabled={isSending || props.disabled}
						onChange={handleSelectOnChange}
					/>
				</SP.Row>
			</SP.Form>

			{isSending && <Preloader style={{ marginTop: themeContext.spacings.medium }} />}

			{validationStatus === false && <SP.Error>{getLabel('WidgetAskGPPostRequestError', settings.applicationTexts)}</SP.Error>}
		</SA.ActivityBubble>
	);
};

export default ActivityGPPost;
