import React from 'react';
import styled, { css } from 'styled-components';
import type { ButtonProps, DivProps } from '../../models/widget/Styled';
import { default as SA } from '../Activity/Activity.styled';

interface WidgetProps {
	$modalOpen?: boolean;
	$mode?: string;
	$hasBranding?: boolean;
	$hasHeader?: boolean;
	$small?: boolean;
	$scrollbarWidth: number;
}

interface MainHeaderProps {
	$scrollbarWidth: number;
}

const WidgetModalOpenStyles = (mode?: string, scrollbarWidth = 0) => css`
	/* Apply padding for larger screen sizes and ensure content stays centered when scrollbar appears */
	@media ${(props) => props.theme.screens.medium} {
		/* First check if the browser supports 'scrollbar-gutter: stable' */
		@supports (scrollbar-gutter: stable) {
			/* If scrollbar-gutter is supported, use it to prevent layout shifts when a scrollbar appears */
			scrollbar-gutter: stable;
			/* Adjust padding for scrollbar width if scrollbar-gutter is supported */
			/* This is necessary to ensure there is no content shift when a scrollbar is displayed */
			${WidgetPadding(scrollbarWidth)}
		}

		/* Fallback when scrollbar-gutter is not supported in the browser */
		@supports not (scrollbar-gutter: stable) {
			/* Apply default padding without considering scrollbar width if the browser doesn't support scrollbar-gutter */
			${WidgetPadding()}
			/* Apply a content centering fallback when modal is not in 'dialog' mode */	
			${mode !== 'dialog' && CenterContentFallback}
		}
	}
`;

const WidgetModalClosedStyles = (smallContainer?: boolean) => css`
	/* Only apply padding when the modal is closed and the widget is not placed in a small container */
	${!smallContainer && WidgetPadding()}
`;

const CenterContentFallback = () => css`
	/* This is a fallback for centering the widgets body */
	/* It ensures content is aligned properly and scrollbar behavior isn't causing shifts */
	/* This only works when the modal is open and the widget is not in dialogMode */

	/* Disable justify-content: center */
	justify-content: flex-start;

	/* Adjust margin to center the widget body */
	${Body} {
		${(props) =>
			props.theme.isRtl
				? `margin-right: max(calc(50vw - (${props.theme.sizes.maxWidth}px / 2) - 28px), 0px)`
				: `margin-left: max(calc(50vw - (${props.theme.sizes.maxWidth}px / 2) - 28px), 0px)`}
	}
`;

const WidgetPadding = (scrollbarWidth = 0) => css`
	/* Calculate padding based on RTL or LTR direction */
	/* If scrollbar-gutter is supported we need to subtract scrollbarWidth from the padding */
	padding-left: ${(props) => (props.theme.isRtl ? `calc(${props.theme.spacings.large} - ${scrollbarWidth}px)` : props.theme.spacings.large)};
	padding-right: ${(props) => (props.theme.isRtl ? props.theme.spacings.large : `calc(${props.theme.spacings.large} - ${scrollbarWidth}px)`)};
`;

const Widget = styled.div.attrs((props) => ({ dir: props.theme.isRtl ? 'rtl' : 'ltr' }))<WidgetProps>`
	&&& {
		color: #000;
		border: 0;
		width: 100%;
		background-color: ${(props) => (props.$modalOpen ? '#fff' : 'transparent')};
		scroll-behavior: smooth;
		line-height: 1.5;

		display: flex;
		flex-direction: row;
		justify-content: center;
		flex: 1;

		${(props) => props.$modalOpen && 'overflow-y: auto;'}

		/* Apply widget padding for different scenarios and ensure widget body stays centered */
		${(props) => (props.$modalOpen ? WidgetModalOpenStyles(props.$mode, props.$scrollbarWidth) : WidgetModalClosedStyles(props.$small))}
	}
`;

const WidgetWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

const WidgetHeader = styled(SA.VisuallyHidden).attrs({ as: 'h1' })``;

const Header = styled.div`
	z-index: 1000;
	background: ${(props) => props.theme.colors.accent100};
	width: 100%;
	margin: auto;
	box-shadow: inset 0px -8px 16px -16px rgba(0, 0, 0, 0.2);
	padding: ${(props) => props.theme.spacings.medium} 0;
	@media ${(props) => props.theme.screens.medium} {
		padding-left: ${(props) => props.theme.spacings.large};
		padding-right: ${(props) => props.theme.spacings.large};
		border-top-left-radius: ${(props) => props.theme.radii.default};
		border-top-right-radius: ${(props) => props.theme.radii.default};
	}
`;

const MainHeader = styled.div<MainHeaderProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media ${(props) => props.theme.screens.medium} {
		padding-top: ${(props) => props.theme.radii.default};
	}
`;

const MainHeaderInner = styled.div`
	width: 100%;
	max-width: min(${(props) => props.theme.sizes.maxWidth}px, 100%);
	display: flex;
	flex-direction: row-reverse;
	position: relative;
	padding: 0 ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.medium} {
		padding: 0 ${(props) => props.theme.spacings.smallMedium};
	}
`;

const SectionHeaderWrapper = styled.div`
	z-index: 1000;
	width: 100%;
	margin: auto;
	background-color: #fff;
	box-shadow: inset 0px -8px 16px -16px rgba(0, 0, 0, 0.2);
	border-bottom: ${(props) => props.theme.colors.text400} 1px solid;
`;

const SectionHeader = styled.div<MainHeaderProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 40px;
	width: 100%;
	align-items: center;
`;

const SectionHeaderInner = styled.div`
	width: 100%;
	max-width: calc(${(props) => props.theme.sizes.maxWidth}px + ${(props) => props.theme.spacings.extraExtraExtraLarge});
	display: flex;
	flex-direction: column;
	padding: 0 ${(props) => props.theme.spacings.small};

	@media ${(props) => props.theme.screens.medium} {
		padding: 0 ${(props) => props.theme.spacings.extraExtraLarge};
	}
`;

const ModalCloseButton = styled.button`
	&&& {
		display: flex;
		margin-left: ${(props) => props.theme.spacings.medium};
		align-self: center;
		width: 28px;
		height: 28px;
		z-index: 1050;
		background: transparent;
		padding: 0;
		border: none;
		right: ${(props) => props.theme.spacings.small};
		top: ${(props) => props.theme.spacings.medium};
		cursor: pointer;
		border-radius: ${(props) => props.theme.radii.default};
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&:focus,
		&:hover {
			svg path {
				fill: ${(props) => props.theme.colors.accent};
			}
		}

		&:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}

		svg path {
			fill: ${(props) => props.theme.colors.accent};
		}
		svg {
			width: 28px;
			height: 28px;
		}

		@media ${(props) => props.theme.screens.medium} {
			right: 0;
		}
	}
`;

const HeaderTitleWrapper = styled.div`
	display: flex;
	margin-top: ${(props) => props.theme.spacings.medium};
	font-size: ${(props) => props.theme.fontSizes.base};
	height: 40px;
	flex-direction: row;
`;

const HeaderPractice = styled.div`
	padding: ${(props) => props.theme.spacings.small};
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => props.theme.colors.text300};
	height: 56px;
`;

const SectionTitle = styled.div`
	font-size: ${(props) => props.theme.fontSizes.lg};
	font-weight: 600;
	color: ${(props) => props.theme.colors.text900};
	padding-left: ${(props) => props.theme.spacings.small};
	@media ${(props) => props.theme.screens.medium} {
		padding-left: 0;
		padding-right: 0;
	}
`;

const HeaderInnerLogo = styled.div`
	height: 40px;
	border-radius: ${(props) => props.theme.radii.default};
	background-color: #fff;
	position: relative;
	justify-content: center;
	align-items: center;

	& img {
		height: 100%;
	}
`;

const PracticeInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${(props) => props.theme.spacings.extraSmall};
	padding-left: ${(props) => props.theme.spacings.small};
	color: ${(props) => props.theme.colors.text600};
	line-height: 1.3;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PracticeName = styled.div`
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: bold;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const PracticeTimes = styled.div`
	display: flex;
	font-size: ${(props) => props.theme.fontSizes.sm};
`;

const Body = styled.div`
	&&& {
		height: 100%;
		width: 100%;
		padding-top: 16px;
		max-width: ${(props) => `${props.theme.sizes.maxWidth}px`};
		color: ${(props) => props.theme.colors.accent};
		padding: ${(props) => props.theme.spacings.medium} ${(props) => props.theme.spacings.medium} 0;

		@media ${(props) => props.theme.screens.medium} {
			padding-top: ${(props) => props.theme.spacings.large};
		}
	}
`;

export default {
	Widget: React.forwardRef((props: DivProps<WidgetProps>, ref) => (
		// @ts-expect-error prop types are correct
		<Widget {...props} ref={ref} data-minddclass="widget">
			{props.children}
		</Widget>
	)),
	WidgetHeader,
	Header,
	MainHeader,
	MainHeaderInner,
	SectionHeaderWrapper,
	SectionHeader,
	ModalCloseButton: (props: ButtonProps) => (
		<ModalCloseButton {...props} data-minddclass="modalclosebutton">
			{props.children}
		</ModalCloseButton>
	),
	SectionHeaderInner,
	HeaderTitleWrapper,
	HeaderPractice,
	SectionTitle,
	HeaderInnerLogo: (props: DivProps) => (
		<HeaderInnerLogo {...props} data-minddclass="headerinnerlogo">
			{props.children}
		</HeaderInnerLogo>
	),
	PracticeInfo,
	PracticeName,
	PracticeTimes,
	Body: (props: DivProps) => (
		<Body {...props} data-minddclass="widgetbody">
			{props.children}
		</Body>
	),
	WidgetWrapper
};
