import styled from 'styled-components';

import { Slider as ReactCompoundSlider } from 'react-compound-slider';

interface HandleControllerProps {
	$percent: number;
	$color: string;
}

interface TickLineProps {
	value: number;
	$color: string;
}

const SliderWrapper = styled.div`
	> div {
		display: flex;
		align-items: center;
		position: relative;
	}
`;

// @ts-expect-error Extending Slider from react-compound-slider should work
const Slider = styled(ReactCompoundSlider)``;

const SliderHandle = styled.div``;

const Rail = styled.div`
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: ${(props) => props.theme.radii.default};
	background-color: transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

const InnerRail = styled.div`
	height: 4px;
	width: 100%;
	background-color: #8b9cb6;
	background: linear-gradient(to ${(props) => (props.theme.isRtl ? 'left' : 'right')}, #89eece, #f2ecbb, #e6c36b, #efb3b3, #e66b6b);
`;

const HandleController = styled.div<HandleControllerProps>`
	left: ${(props) => props.$percent}%;
	position: absolute;
	margin-left: -15px;
	z-index: 2;
	width: 30px;
	height: 30px;
	text-align: center;
	cursor: pointer;
	border-radius: 50%;
	background-color: #fff;
	border: 2px solid ${(props) => props.theme.colors.accent};
	border-color: ${(props) => props.$color};
	color: #333;

	&:focus-visible {
		outline: 2px solid ${(props) => props.theme.colors.accent};
		background-color: ${(props) => props.$color};
	}
`;

const Ticks = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const TickLine = styled.div<TickLineProps>`
	width: 4px;
	height: ${(props) => props.value * 16 + 4}px;
	background-color: ${(props) => props.$color};
	border-radius: 2px;
`;

const TickLabel = styled.div``;

export default {
	SliderWrapper,
	Slider,
	SliderHandle,
	Rail,
	InnerRail,
	HandleController,
	Ticks,
	TickLine,
	TickLabel
};
