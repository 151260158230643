import React, { FunctionComponent, useCallback } from 'react';

import { default as SA } from '../Activity/Activity.styled';

import Button from '../Button';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import type { ActivityProps } from '../Activity';

const ActivityNoTriageFound: FunctionComponent<ActivityProps> = (props) => {
	const [{ settings, conversation }, dispatch] = useStateContext();

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleBackAction = () => {
		let newConversation = null;

		const indexComplaintSelector = conversation.findIndex((s) => s.type === 'searchComplaintSelector');
		if (indexComplaintSelector !== -1) {
			newConversation = conversation.slice(0, indexComplaintSelector + 1);
		} else {
			const indexSearchTriage = conversation.findIndex((s) => s.type === 'searchTriage');
			if (indexSearchTriage !== -1) {
				newConversation = conversation.slice(0, indexSearchTriage + 1);
			}
		}

		if (newConversation) {
			// Reset answer on last activity so it is not disabled
			const lastActivity = newConversation.slice(-1)[0];
			delete lastActivity.answer;

			dispatch({
				type: 'updateConversationActivities',
				conversation: newConversation
			});
		}
	};

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
				{getLabel('WidgetNoTriageFoundTitle', settings.applicationTexts)}
			</SA.BubbleTitle>
			<p>{getLabel('WidgetNoTriageFoundDescription', settings.applicationTexts)}</p>

			<SA.ActivityActions>
				<Button disabled={props.disabled} onClick={handleBackAction}>
					{getLabel('NativeBack', settings.applicationTexts)}
				</Button>
			</SA.ActivityActions>
		</SA.ActivityBubble>
	);
};

export default ActivityNoTriageFound;
