import React, { useState, useCallback, useEffect, FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostSearchTriageProgress, CallPostSessionLogProperties } from '../../helpers/services';
import type { ActivityProps } from '../Activity';
import type { SearchTriageGroup } from '../../models';

interface ActivitySearchComplaintSelectorProps extends ActivityProps {
	title?: string | JSX.Element;
}

const ActivitySearchComplaintSelector: FunctionComponent<ActivitySearchComplaintSelectorProps> = (props) => {
	const [{ profile, session, settings }, dispatch] = useStateContext();
	const [selectedTriageGroup, setSelectedTriageGroup] = useState<SearchTriageGroup>();

	const triageGroups = profile.selectedSearchTriageHit?.groups ?? [];

	const focusLastActivity = useCallback(
		(node: HTMLHeadingElement) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleActivityResponse = async (triageGroup: SearchTriageGroup) => {
		setSelectedTriageGroup(triageGroup);

		dispatch({
			type: 'updateProfile',
			profile: {
				...profile,
				selectedSearchTriageGroup: triageGroup
			}
		});

		await CallPostSearchTriageProgress(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			hitChoosen: profile.selectedSearchTriageHit?.searchHitText,
			triageChoosen: triageGroup.groupName,
			askForBodyPart: triageGroup.askForBodyPart ?? false,
			askForGender: triageGroup.askForGender ?? false,
			askForAge: triageGroup.askForAge ?? false
		});

		await props.handleActivityResponse({ triageGroup: triageGroup });
	};

	useEffect(() => {
		void CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [
				{
					sessionLogPropertyName: 'SearchTriageTriageSelectorShown',
					sessionLogPropertyValue: true
				}
			]
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SA.ActivityBubble>
			<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
				{props.title}
			</SA.BubbleTitle>

			<S.Complaints>
				<S.ComplaintsList>
					{triageGroups &&
						triageGroups.map((item, index) => {
							return (
								<S.Complaint key={index}>
									<S.ComplaintButton
										disabled={props.disabled}
										onClick={() => !props.disabled && void handleActivityResponse(item)}
										$selected={item.groupName === selectedTriageGroup?.groupName}
									>
										{item.groupName}
									</S.ComplaintButton>
								</S.Complaint>
							);
						})}
				</S.ComplaintsList>
			</S.Complaints>
		</SA.ActivityBubble>
	);
};

export default ActivitySearchComplaintSelector;
