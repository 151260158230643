import React, { useEffect, useMemo, useRef, useState } from 'react';

import ActivityTriage from '../ActivityTriage/ActivityTriage';
import type { ActivityStep, ActivityStepAbcdTriage, ActivityStepNormalTriage, ActivityStepStartWithAbcdTriage } from '../../models';
import S from './SectionTriage.styled';
import SA from '../Activity/Activity.styled';
import SAT from '../ActivityTriage/ActivityTriage.styled';
import { ActivityProps } from '../Activity';
import { useDetermineNextStep } from '../../helpers/hooks/nextStep/useDetermineNextStep';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import Preloader from '../Preloader/Preloader';
import { CallPostSessionLogAdviceBack } from '../../helpers/services';
import { useTheme } from 'styled-components';
import { useExecutionLock } from '../../helpers/hooks/useExecutionLock';

type TriageSectionProps = ActivityProps & {
	currentStep: ActivityStepNormalTriage | ActivityStepAbcdTriage | ActivityStepStartWithAbcdTriage;
};

const SectionTriage = (props: TriageSectionProps) => {
	const [
		{
			dialog: { confirm },
			settings,
			conversation,
			sectionIndex,
			session
		},
		dispatch
	] = useStateContext();
	const { currentStep } = props;
	const determineNextStep = useDetermineNextStep();
	const [loading, setLoading] = useState(false);
	const triageCompleteEmpathyRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const themeContext = useTheme();
	const { executeWithLock } = useExecutionLock(props.setDisableNext);

	const showTriageCompleteEmpathy = useMemo(
		() => conversation.at(-1) !== props.currentStep && conversation[sectionIndex + 1].type === 'advice',
		[conversation.at(-1), conversation[sectionIndex + 1], sectionIndex]
	);

	useEffect(() => {
		if (showTriageCompleteEmpathy) {
			triageCompleteEmpathyRef.current?.scrollIntoView();
		}
	}, [showTriageCompleteEmpathy, triageCompleteEmpathyRef.current]);

	const handleQuestionAnswer = async (questionId: number, answer: string | number) => {
		await executeWithLock(async () => {
			const questionIndex = currentStep.questions.findIndex((q) => q.question.id === questionId);

			if (questionIndex === -1) return;

			const isPreviousQuestion = questionIndex < currentStep.questions.length - 1;
			const isPreviousSection = currentStep?.id !== conversation.at(-1)?.id && conversation.at(sectionIndex + 1)?.visited;

			if (isPreviousQuestion || isPreviousSection) {
				const existingAnswer = currentStep.questions[questionIndex].answer;
				const answerChanged = existingAnswer !== undefined && existingAnswer !== answer;

				if (!answerChanged) return;

				const isLastAnsweredQuestion = !currentStep.questions[questionIndex + 1]?.answer;

				// Ask confirmation to remove all subsequent answered questions
				if (!isLastAnsweredQuestion || isPreviousSection) {
					const confirmChange = await handleConfirmation();
					if (!confirmChange) return;
				}
			}

			const questions = isPreviousQuestion ? currentStep.questions.slice(0, questionIndex + 1) : [...currentStep.questions];
			questions[questions.length - 1].answer = answer;

			const updatedStep = {
				...currentStep,
				questions
			};

			dispatch({
				type: 'conversation/updateStep',
				step: updatedStep
			});

			if (isPreviousSection && conversation.some((c) => c.type === 'advice' && c.visited === true)) {
				// LogAdviceBack when user has seen an advice and goes back to change an answer that will remove the advice from the conversation
				await CallPostSessionLogAdviceBack(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					sessionLogAdviceBack: {
						adviceBackClicked: true
					}
				});
			}

			// If we change any answer, but the next section is already loaded, remove the next section
			dispatch({
				type: 'updateConversationActivities',
				conversation: conversation.filter((step, index) => index <= conversation.findIndex((s) => s === currentStep))
			});
			setLoading(true);

			await handleNextStep(updatedStep);
		});
	};

	const handleConfirmation = () => {
		return confirm({
			header: getLabel('TriageChangePreviousQuestionAnswerHeader', settings.applicationTexts),
			message: getLabel('TriageChangePreviousQuestionAnswerMessage', settings.applicationTexts),
			cancelLabel: getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true),
			confirmLabel: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
			buttonLayout: 'horizontal'
		});
	};

	const handleNextStep = async (currentStep: ActivityStep) => {
		const nextStep = await determineNextStep(currentStep);
		setLoading(false);

		if (!nextStep) return;

		if (nextStep.id === currentStep.id) {
			dispatch({
				type: 'conversation/updateStep',
				step: nextStep
			});
		} else {
			currentStep.answer = { message: 'completed' };
			dispatch({
				type: 'conversation/updateStep',
				step: currentStep
			});

			dispatch({
				type: 'conversation/setNextStep',
				currentStepId: currentStep.id,
				step: nextStep
			});
		}
	};

	return (
		<S.SectionTriage>
			<SA.ActivityBubble>
				{currentStep.questions.map((item, index) => (
					<ActivityTriage
						key={item.question.id}
						isLastQuestion={index === props.currentStep.questions.length - 1}
						isFirstQuestion={index === 0}
						{...{ ...props, ...item, disabled: props.disabled || loading }}
						handleQuestionAnswer={handleQuestionAnswer}
					/>
				))}

				{loading ? (
					<Preloader style={{ marginTop: themeContext.spacings.large }} />
				) : (
					<div style={{ height: `calc(${themeContext.spacings.large} + ${themeContext.spacings.small})` }} />
				)}

				{showTriageCompleteEmpathy && (
					<SA.EmpathyBubble ref={triageCompleteEmpathyRef} $style="success" data-testid="triage-complete-empathy">
						<S.TriageCompleteEmpathy>
							<S.TriageCompleteCheck />
							<SA.EmpathyBubbleTitle $style="success">{getLabel('TriageCompleteEmpathy', settings.applicationTexts)}</SA.EmpathyBubbleTitle>
						</S.TriageCompleteEmpathy>
					</SA.EmpathyBubble>
				)}
			</SA.ActivityBubble>
		</S.SectionTriage>
	);
};

export default SectionTriage;
