import styled from 'styled-components';

export default {
	Wrapper: styled.div`
		padding: ${(props) => `0 ${props.theme.spacings.medium}`};
	`,
	InputLabel: styled.span`
		color: ${(props) => props.theme.colors.text900};
	`,
	Feedback: styled.div`
		margin-top: ${(props) => props.theme.spacings.small};
		margin-left: -${(props) => props.theme.spacings.medium};

		&.success {
			color: ${(props) => props.theme.colors.success};
		}
		&.error {
			color: ${(props) => props.theme.colors.error};
		}
	`
};
