import styled from 'styled-components';

interface InputSectionProps {
	$valid?: boolean;
	disabled?: boolean;
}

const CallToActionBanner = styled.div`
	&& {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1;
		padding: ${(props) => props.theme.spacings.medium};
		gap: 10px;
		background: ${(props) => props.theme.colors.text800};
		border: 1px solid rgba(255, 255, 255, 0.05);
		border-radius: ${(props) => props.theme.radii.default};
		z-index: 1000;

		&:not(:first-child) {
			margin-top: 1px;
		}
	}
`;

const CallToActionRow = styled.div`
	&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: ${(props) => props.theme.spacings.medium};
		padding: 0;
	}
`;

const CallToActionWrapper = styled.div`
	&& {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 0;
		gap: ${(props) => props.theme.spacings.smallMedium};
	}
`;

const ActionTitle = styled.h4`
	&& {
		font-size: ${(props) => props.theme.fontSizes.sm};
		font-weight: 600;
		color: ${(props) => props.theme.colors.text200};
		line-height: 20px;
		margin: 0;
	}
`;

const ActionSubtitle = styled.h5`
	&& {
		font-size: ${(props) => props.theme.fontSizes.sm};
		font-weight: 400;
		color: ${(props) => props.theme.colors.text300};
		line-height: 20px;
		margin: 0;
	}
`;

const ActionTextWrapper = styled.div`
	&& {
		display: flex;
		flex-direction: column;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		white-space: normal;

		/* If there is no ActionSubtitle, increase ActionTitle font size */
		&:not(:has(${ActionSubtitle})) ${ActionTitle} {
			font-size: ${(props) => props.theme.fontSizes.base};
		}
	}
`;

const Icon = styled.div`
	&& {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		gap: 10px;
		min-width: 28px;
		height: 28px;
	}
`;

const ActionIcon = styled(Icon)`
	&& {
		background: #f0f4f4;
		color: ${(props) => props.theme.colors.text900};
		border-radius: ${(props) => props.theme.radii.default};
	}
`;

const ChevronIcon = styled(Icon)`
	&& {
		color: ${(props) => props.theme.colors.textinverted};
	}
`;

const InputSectionRow = styled(CallToActionRow)`
	&& {
		gap: ${(props) => props.theme.spacings.small};
		flex-wrap: wrap;
	}
`;

const InputComponentWrapper = styled.div<InputSectionProps>`
	&& {
		flex: 1 1 60%;

		label {
			display: none;
		}

		select {
			height: 40px;
			border: 1px solid ${(props) => (props.$valid === false ? props.theme.colors.error : props.theme.colors.text600)};
			background: ${(props) => props.theme.colors.text800};
			font-size: ${(props) => props.theme.fontSizes.sm};
			padding: ${(props) => props.theme.spacings.small} ${(props) => props.theme.spacings.smallMedium};
			color: ${(props) => props.theme.colors.text100};
		}
	}
`;

const SubmitButton = styled.button`
	&& {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: ${(props) => props.theme.spacings.smallMedium} ${(props) => props.theme.spacings.medium};
		gap: ${(props) => props.theme.spacings.small};
		background: ${(props) => props.theme.colors.text100};
		border-radius: ${(props) => props.theme.radii.default};
		height: 40px;
		font-size: ${(props) => props.theme.fontSizes.sm};
		font-weight: 600;
		line-height: 17px;
		color: ${(props) => props.theme.colors.text800};

		flex: 1 0 auto;
	}
`;

export default {
	CallToActionBanner,
	CallToActionRow,
	CallToActionWrapper,
	ActionIcon,
	ActionTitle,
	ActionSubtitle,
	ActionTextWrapper,
	ChevronIcon,
	InputSectionRow,
	InputComponentWrapper,
	SubmitButton
};
