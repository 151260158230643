import React, { FunctionComponent } from 'react';
import S from './StartButton.styled';
import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StartButtonProps {
	onStart?: () => void;
	disabled?: boolean;
}

const StartButton: FunctionComponent<StartButtonProps> = ({ onStart, disabled }) => {
	const [{ settings }] = useStateContext();

	return (
		<S.Buttons>
			<S.Button onClick={onStart} disabled={disabled}>
				{getLabel('StartButtonTitle', settings.applicationTexts, true)}
			</S.Button>
			<S.StartIntroLabel data-minddclass="startintrotext">
				<FontAwesomeIcon icon={faClock} />
				<span>{getLabel('StartButtonIntro', settings.applicationTexts, true)}</span>
			</S.StartIntroLabel>
		</S.Buttons>
	);
};

export default StartButton;
