import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { SurveyQuestionOutput } from '../../models';

export type SurveyStartQuestionInput = SessionInput & LanguageInput;

async function CallSurveyStartQuestion(apiKey: string, input: SurveyStartQuestionInput): Promise<SurveyQuestionOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<SurveyQuestionOutput>('SurveyStartQuestion?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallSurveyStartQuestion');
	}

	return undefined;
}

export { CallSurveyStartQuestion };
