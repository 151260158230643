import { useCallback } from 'react';

import { StateContextType, useStateContext } from '../useStateContext';
import { getLabel } from '../../constants/getLabels';
import { CallGetStartQuestion } from '../../services';
import type { ActivityStep, Gender, NextTriageGroup } from '../../../models';

export type UseNextStepTriageRedirectResultType = (
	nextTriageGroup: NextTriageGroup,
	bodyAreaId?: number | null,
	gender?: Gender | null,
	age?: string,
	birthdate?: Date
) => Promise<ActivityStep>;

export const useNextStepTriageRedirect: () => UseNextStepTriageRedirectResultType = () => {
	const [{ session, settings }]: StateContextType = useStateContext();

	const getNextTriageStartQuestion = useCallback(
		async (
			triageId: number,
			bodyAreaId: number | null | undefined,
			gender: Gender | null | undefined,
			age: string,
			birthdate: Date | undefined
		): Promise<ActivityStep> => {
			const startQuestion = await CallGetStartQuestion(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				triageId: triageId,
				gender: gender,
				age: age,
				birthdate: birthdate,
				bodyAreaId: bodyAreaId,
				requestType: 'TriageRedirect',
				languageCode: settings.selectedLanguage.code
			});

			if (startQuestion) {
				return {
					step: 0,
					id: `question-${startQuestion.startQuestion.id}`,
					type: `triage${startQuestion.startQuestion.triageType}`,
					questions: [
						{
							question: startQuestion.startQuestion,
							empathy: startQuestion.empathy
						}
					]
				};
			}

			return {
				step: 0,
				id: `nextTriageStartQuestion-${triageId}`,
				type: 'noNextStepFound'
			};
		},
		[session, settings.ApiKey, settings.selectedLanguage.code]
	);

	return useCallback(
		(nextTriageGroup: NextTriageGroup, bodyAreaId?: number | null, gender?: Gender | null, age?: string, birthdate?: Date): Promise<ActivityStep> => {
			let triages = nextTriageGroup.triages;
			if (nextTriageGroup.askForBodyPart) {
				if (bodyAreaId) {
					triages = triages.filter((t) => t.bodyAreaIds.some((ba) => ba === bodyAreaId));
				} else {
					return Promise.resolve({
						step: 0,
						id: `nextTriageBodyAreaSelector-${nextTriageGroup.groupId}`,
						type: 'triageGroupBodyAreaSelector',
						title: getLabel('QuestionWhatBodyPart', settings.applicationTexts),
						info: getLabel('QuestionWhatBodyPartInfo', settings.applicationTexts, false, true),
						profile: 'bodypart',
						triageGroup: nextTriageGroup
					});
				}
			}

			if (triages.length === 0) {
				return Promise.resolve({
					step: 0,
					id: `nextStepTriageRedirect-${nextTriageGroup.groupId}`,
					type: 'noNextStepFound'
				});
			}

			if (nextTriageGroup.askForGender) {
				if (gender) {
					const genderLowerCase = gender.toLowerCase();
					if (genderLowerCase === 'male') {
						triages = triages.filter((t) => t.male);
					} else if (genderLowerCase === 'female') {
						triages = triages.filter((t) => t.female);
					} else {
						console.log('Invalid value for gender');
						throw Error('Invalid value for gender');
					}
				} else {
					return Promise.resolve({
						step: 0,
						id: `nextTriageGenderSelector-${nextTriageGroup.groupId}`,
						type: 'genderSelector',
						title: getLabel('QuestionWhatGender', settings.applicationTexts),
						info: getLabel('QuestionWhatGenderInfo', settings.applicationTexts, false, true),
						profile: 'gender',
						triageGroup: nextTriageGroup
					});
				}
			}

			if (triages.length === 0) {
				return Promise.resolve({
					step: 0,
					id: `nextStepTriageRedirect-${nextTriageGroup.groupId}`,
					type: 'noNextStepFound'
				});
			}

			if (nextTriageGroup.askForAge) {
				if (age) {
					const ageFloat = parseFloat(age);
					triages = triages.filter((t) => (t.ageMin <= ageFloat || t.ageMin === 0) && (t.ageMax > ageFloat || t.ageMax === 0));
				} else {
					return Promise.resolve({
						step: 0,
						id: `nextTriageAgeSelector-${nextTriageGroup.groupId}`,
						type: 'ageSelector',
						title: getLabel('QuestionWhatAge', settings.applicationTexts),
						info: getLabel('QuestionWhatAgeInfo', settings.applicationTexts, false, true),
						profile: 'age',
						triageGroup: nextTriageGroup
					});
				}
			}

			if (triages.length === 0) {
				return Promise.resolve({
					step: 0,
					id: `nextStepTriageRedirect-${nextTriageGroup.groupId}`,
					type: 'noNextStepFound'
				});
			}

			return getNextTriageStartQuestion(triages[0].id, bodyAreaId, gender, age ?? '0', birthdate);
		},
		[settings.applicationTexts, getNextTriageStartQuestion]
	);
};
