/**
 *  SET DeviceID
 */

const S4 = (): string => {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export const setDeviceId = (): string => {
	let guid = null;
	const MINDDDeviceID = localStorage.getItem('MINDDDeviceID');
	if (MINDDDeviceID == null) {
		const deviceType = 'WB';
		guid = (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase().replace(/^.{2}/g, deviceType);
		localStorage.setItem('MINDDDeviceID', guid);
	} else {
		guid = MINDDDeviceID;
	}
	return guid;
};
