import styled from 'styled-components';
import { default as SP } from '../Procedure/Procedure.styled';

export default {
	DateField: styled(SP.Field)`
		min-height: 38px;

		&:focus {
			border-color: ${(props) => (props.$valid === false ? props.theme.colors.error : '#cccccc')};
		}
	`,
	PhoneInputWrapper: styled.div`
		& :has(input):first-child {
			margin-left: 0;
		}
		input {
			height: auto;
		}
	`,
	PhoneCountryOptionWrapper: styled.div`
		overflow: hidden;
		text-overflow: ellipsis;
	`,
	PhoneCountryOptionImageWrapper: styled.div`
		width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
		height: var(--PhoneInputCountryFlag-height);
		display: inline-flex;
		position: relative;
	`,
	PhoneCountryOptionImage: styled.img`
		width: 100%;
		height: auto;
		position: absolute;
		top: 2px;
		left: 0;
	`,
	PhoneCountryOptionLabel: styled.span`
		margin-left: 4px;
	`
};
