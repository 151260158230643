import React, { createContext, useContext, useReducer, PropsWithChildren, Dispatch, useEffect } from 'react';
import type { DialogContext, RootContext } from '../../models';
import { rootReducer } from '../reducer/rootReducer';
import { RootReducerAction } from '../reducer/rootReducerActions';
import { InitialState } from '../constants/initialState';

// We need default context value and dispatch function to call createContext with
// Even though the values shouldn't be used since we use StateProvider with useReducer

const defaultContextValue = InitialState({
	ApiKey: '',
	open: false,
	widget_showWelcomeText: true
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultContextDispatch: Dispatch<RootReducerAction> = (value: RootReducerAction) => {};

export type StateContextType = [RootContext, Dispatch<RootReducerAction>];

const StateContext = createContext<StateContextType>([defaultContextValue, defaultContextDispatch]);

export const StateProvider = ({ initialState, children }: PropsWithChildren<{ initialState: RootContext }>) => {
	const [state, dispatch] = useReducer(rootReducer, initialState);

	useEffect(() => {
		dispatch({
			type: 'dialog/setConfirm',
			confirm: (state: Omit<DialogContext, 'open' | 'confirm' | 'confirmPromiseResolve'>) => {
				return new Promise<boolean>((resolve) => {
					dispatch({
						type: 'dialog/setState',
						state: {
							...state,
							open: true,
							confirmPromiseResolve: resolve,
						},
					});
				})
			},
		});
	}, []);

	return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>;
};

export const useStateContext: () => StateContextType = () => useContext<StateContextType>(StateContext);
