import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { Advice, AdvicePhoneNumber, NextTriageGroup, Question } from '../../models';

export type GetNextInput = SessionInput &
	LanguageInput & {
		questionId: number;
		answer: string | number;
	};

export type GetNextOutput = {
	nextQuestion?: Question;
	nextTriageGroup?: NextTriageGroup;
	advice?: Advice;
	callButtonPhoneNumbers: AdvicePhoneNumber[];
	empathy?: string;
};

async function CallGetNext(apiKey: string, input: GetNextInput): Promise<GetNextOutput | undefined> {
	try {
		const { data } = await minddApiAxios.post<GetNextOutput>('GetNext?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallGetNext');
	}

	return undefined;
}

export { CallGetNext };
