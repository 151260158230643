import React, { useState, useCallback, FunctionComponent, useEffect } from 'react';
import { compiler } from 'markdown-to-jsx';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import S from './SurveyQuestion.styled';
import { default as SCPQ } from '../ConsultationPreparationQuestion/ConsultationPreparationQuestion.styled';
import SIC from '../ConsultationPreparationQuestion/InputChoices.styled';
import type { ActivityProps } from '../Activity';
import type { ActivityAnswerSurveyQuestion, SurveyQuestion as Question } from '../../models';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useTheme } from 'styled-components';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import animateQuestion from '../../helpers/styled/AnimateQuestionSlide';
import { getLabel } from '../../helpers/constants/getLabels';
import { TextArea } from '../FormComponents/FormComponents';

interface SurveyQuestionProps extends ActivityProps {
	question: Question;
	answer?: ActivityAnswerSurveyQuestion;
	isFirstQuestion: boolean;
	isLastQuestion: boolean;
	stepNumber: number;
	handleQuestionAnswer: (answer: ActivityAnswerSurveyQuestion) => Promise<void>;
	scrollOffset?: number;
}

const SurveyQuestion: FunctionComponent<SurveyQuestionProps> = (props) => {
	const [{ refs, settings }] = useStateContext();
	const [answerText, setAnswerText] = useState<string>(props.answer?.answerText ?? '');
	const [previousAnswerChanged, setPreviousAnswerChanged] = useState(false);

	const themeContext = useTheme();

	const { question } = props;
	const answers = question.answers;
	const isIntroQuestion = props.stepNumber === 1;

	useEffect(() => {
		if (props.isLastQuestion && !isIntroQuestion) {
			props.setDisableNext(true);
		}
	}, []);

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				if (!props.isFirstQuestion && props.isLastActivity && !props.answer) {
					animateQuestion(node, themeContext, refs.widget?.current, props.scrollOffset ?? 0);
				}
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSetComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setAnswerText(e.target.value);

		if (props.isLastQuestion) {
			props.setDisableNext(!e.target.value);
			props.setHandleNext(() => () => {
				void handleQuestionAnswer(answers[0].id);
			});
		} else {
			setPreviousAnswerChanged(props.answer?.answerText !== e.target.value);
		}
	};

	const handleQuestionAnswer = async (answerId: number) => {
		await props.handleQuestionAnswer({ answerId, answerText });
		setPreviousAnswerChanged(false);
	};

	return (
		<SCPQ.Wrapper ref={focusLastActivity}>
			<ActivityBubbleTitle
				title={
					<>
						{compiler(question.title, {
							overrides: {
								p: {
									component: ({ children, ...props }) => <p {...props}>{children}</p>,
									props: {
										'data-texttype': 'markdown'
									}
								},
								img: {
									component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
								}
							}
						})}
					</>
				}
				modalOpen={props.modalOpen}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity && props.isLastQuestion}
				disabled={props.disabled}
				stepNumber={props.stepNumber}
				stepperStyle={!props.isLastQuestion || !props.isLastActivity ? 'secondary' : 'primary'}
				shouldFocus={!isIntroQuestion}
			/>
			{question.type === 'Open' && (
				<>
					<TextArea $label="" value={answerText} name={`survey-${question.id}`} disabled={props.disabled} onChange={handleSetComment} />

					{(previousAnswerChanged || isIntroQuestion) && (
						<SCPQ.ConfirmButtonWrapper>
							<SCPQ.Button disabled={answerText === ''} onClick={() => void handleQuestionAnswer(answers[0].id)}>
								{isIntroQuestion
									? getLabel('WidgetNextButton', settings.applicationTexts, true)
									: getLabel('WidgetConfirmButton', settings.applicationTexts, true)}
							</SCPQ.Button>
						</SCPQ.ConfirmButtonWrapper>
					)}
				</>
			)}

			{question.type === 'Normal' && (
				<S.SurveyAnswerButtons style={{ marginTop: themeContext.spacings.smallMedium }}>
					{answers.map((answer) => (
						<SIC.CheckBox key={answer.id} checked={props.answer?.answerId === answer.id ? true : false}>
							<SP.Field
								type="radio"
								name={`survey_${question.id}`}
								id={`${question.id}_${answer.id}`}
								disabled={props.disabled}
								onClick={() => void handleQuestionAnswer(answer.id)}
							/>
							<SP.Label htmlFor={`${question.id}_${answer.id}`}>{answer.title}</SP.Label>
						</SIC.CheckBox>
					))}
				</S.SurveyAnswerButtons>
			)}
		</SCPQ.Wrapper>
	);
};

export default SurveyQuestion;
