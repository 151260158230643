import { useStateContext } from './useStateContext';

export default (question: string | number) => {
	const [{ conversation }] = useStateContext();

	let num = 1;
	for (let i = 0; i < conversation.length; i++) {
		const step = conversation[i];
		if (step.id === question) {
			return num;
		}

		if ('questions' in step) {
			for (let j = 0; j < step.questions.length; j++) {
				const stepQuestion = step.questions[j].question;
				if (stepQuestion.id === question) {
					return num;
				}
				num++;
			}
		} else {
			num++;
		}
	}

	return -1;
};
