import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`;

interface RadioButtonLabelProps {
	$isSelected?: boolean;
	$isExpandable?: boolean;
	$disabled?: boolean;
}

const RadioButtonLabel = styled.div<RadioButtonLabelProps>`
	border: black 1px solid;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px; // was 8px
	cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};

	@media ${(props) => props.theme.screens.medium} {
		padding: 24px;
		gap: 16px;
	}

	background: ${(props) => props.$isSelected && !props.$isExpandable && props.theme.colors.accentLight};
	border: 2px solid black;
	border-color: ${(props) => (props.$isSelected ? props.theme.colors.accent : 'rgba(0,0,0,0.1)')};
	border-radius: 10px;

	${(props) =>
		props.$isSelected &&
		props.$isExpandable &&
		`
		border-bottom-left-radius: unset;
		border-bottom-right-radius: unset;
		border-bottom: 2px solid rgba(0,0,0,0.1);
  	`}

	// icon
	.selected {
		color: ${(props) => props.theme.colors.accent};
	}
`;

const RadioButtonLabelText = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	h3 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 0px;
	}
	p {
		margin-top: 6px;
		margin-bottom: 0; // reset fix
	}
`;

const DateAndTimeSlotText = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 6px;
	gap: 2px;

	@media ${(props) => props.theme.screens.medium} {
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}
`;

interface TimeSlotRadioButtonLabelProps {
	$isSelected?: boolean;
}

const TimeSlotRadioButtonLabel = styled.label<TimeSlotRadioButtonLabelProps>`
	&&& {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		background: ${(props) => (props.$isSelected ? props.theme.colors.accentLight : 'transparent')};
		border: 2px solid black;
		border-color: ${(props) => (props.$isSelected ? props.theme.colors.accent : 'transparent')};
		border-radius: ${(props) => props.theme.radii.default};
		border: 1px solid rgba(0, 0, 0, 0.1);

		// time interval
		span {
			flex-grow: 1;
		}

		input {
			width: unset;
		}
	}
`;

const DateAndTimeSlotContainer = styled.div`
	// added to make content vertically scrollable
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const TimeSlotContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden; // added to make the drawer content scrollable
`;

const AvailableTimeSlotsText = styled.div`
	font-weight: bold;
	padding-top: 20px;
	padding-bottom: 20px;

	@media ${(props) => props.theme.screens.medium} {
		padding-bottom: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}
`;

const ScrollableContentWrapper = styled.div`
	overflow-y: auto;
	touch-action: pan-y;
	// note: max-height isn't used here so the height is the same for different days with a different number of time slots
	// note: should be smaller than max-height at DrawerContent
	height: 40vh;
`;

const DateAndTimeSlotContainerDesktop = styled.div`
	margin-top: -8px;
	padding-left: 20px;
	padding-right: 20px;

	max-height: 0;
	overflow-y: auto;
	transition: max-height 300ms ease-in-out;

	&.DateAndTimeSlotContainerDesktop-enter,
	&.DateAndTimeSlotContainerDesktop-enter-done {
		max-height: 1000px;
		opacity: 1;
	}
	&.DateAndTimeSlotContainerDesktop-enter-active,
	&.DateAndTimeSlotContainerDesktop-enter-done {
		border: 2px solid ${(props) => props.theme.colors.accent};
		border-top: none;
		border-radius: ${(props) => props.theme.radii.default};
		border-top-left-radius: unset;
		border-top-right-radius: unset;
	}

	&.DateAndTimeSlotContainerDesktop-exit,
	&.DateAndTimeSlotContainerDesktop-exit-done {
		max-height: 0;
	}
	&.DateAndTimeSlotContainerDesktop-exit-active {
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-top: none;
		border-radius: ${(props) => props.theme.radii.default};
		border-top-left-radius: unset;
		border-top-right-radius: unset;
	}
`;

const TimeSlotList = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacings.small};
`;

const TimeSlot = styled.div`
	padding: ${(props) => props.theme.spacings.small};
	margin: ${(props) => props.theme.spacings.small} 0;
	border: 1px solid #ccc;
	border-radius: ${(props) => props.theme.radii.default};
`;

const TimeSlotUnverifiedAlert = styled.div`
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => props.theme.colors.noticeDanger};
	padding: ${(props) => `${props.theme.spacings.medium} ${props.theme.spacings.medium}`};
	margin-bottom: ${(props) => props.theme.spacings.large};
	color: ${(props) => props.theme.colors.text900};
`;

const S = {
	Container,
	RadioButtonLabel,
	RadioButtonLabelText,
	TimeSlotRadioButtonLabel,
	TimeSlotContainer,
	AvailableTimeSlotsText,
	ScrollableContentWrapper,
	TimeSlotList,
	TimeSlot,
	DateAndTimeSlotContainer,
	DateAndTimeSlotContainerDesktop,
	DateAndTimeSlotText,
	TimeSlotUnverifiedAlert
};

export default S;
