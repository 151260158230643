import { useCallback } from 'react';

import { useStateContext } from '../useStateContext';
import type { ActivityStep, ActivityStepSurvey } from '../../../models';
import { CallSurveyGetNext } from '../../services';

export type UseNextStepSurveyResultType = (currentStep: ActivityStepSurvey) => Promise<ActivityStep>;

export const useNextStepSurvey: () => UseNextStepSurveyResultType = () => {
	const [{ session, settings }] = useStateContext();

	return useCallback(
		async (currentStep: Readonly<ActivityStepSurvey>): Promise<ActivityStep> => {
			const lastQuestion = currentStep?.questions.at(-1) ?? currentStep?.introQuestion;
			if (!lastQuestion || !lastQuestion.answer) {
				return {
					step: 0,
					id: `getNext-unknown`,
					type: 'noNextStepFound'
				};
			}

			let nextQuestion;

			if (lastQuestion.answer.answerId) {
				nextQuestion = await CallSurveyGetNext(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					questionId: lastQuestion.question.id,
					answerId: lastQuestion.answer.answerId,
					answerText: lastQuestion.answer.answerText,
					languageCode: settings.selectedLanguage.code
				});
			}

			if (nextQuestion && nextQuestion.question) {
				return {
					step: currentStep.step,
					id: currentStep.id,
					type: currentStep.type,
					surveyIntroStepId: currentStep.surveyIntroStepId,
					introQuestion: currentStep.introQuestion,
					questions: [...currentStep.questions, { question: nextQuestion.question }]
				};
			} else {
				return {
					step: 0,
					id: `survey-end`,
					type: 'surveyEnd'
				};
			}
		},
		[session, settings.ApiKey, settings.selectedLanguage]
	);
};
