import styled from 'styled-components';

interface AwesomeButtonProps {
	$minddclass?: string; // note: use of name 'data-minddclass' gives an error
	type?: 'button' | 'submit' | 'reset';
}

const PrimaryAwesomeButton = styled.button.attrs<AwesomeButtonProps>((props) => ({
	'data-minddclass': props.$minddclass || 'primaryawesomebutton',
	type: props.type ? props.type : 'button',
	...props
}))`
	&&& {
		all: unset;
		cursor: pointer;
		background: ${(props) => props.theme.colors.accent};
		border-color: ${(props) => props.theme.colors.text300};
		color: #fff;
		border: 2px solid ${(props) => props.theme.colors.accent};
		padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.large}`} !important;
		outline: 0;
		border-radius: 10px;
		cursor: pointer !important;
		font-weight: 600;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;
		svg path {
			fill: ${(props) => props.theme.colors.accent};
		}
		// TODO: provide consistent disabled styling for buttons later
		&[disabled] {
			cursor: not-allowed !important;
			border-color: ${(props) => props.theme.colors.text300};
			color: ${(props) => props.theme.colors.text300};
			svg path {
				fill: ${(props) => props.theme.colors.text300};
			}
			font-weight: normal;
			background: #fff;
		}
		&:hover:not([disabled]),
		&:active,
		&:focus {
			background: ${(props) => props.theme.colors.accentDarker};
		}
	}
`;

const SecondaryAwesomeButton = styled.button.attrs<AwesomeButtonProps>((props) => ({
	'data-minddclass': props.$minddclass || 'secondaryawesomebutton',
	type: props.type ? props.type : 'button',
	...props
}))`
	&&& {
		all: unset;
		cursor: pointer;
		background: transparent;
		color: ${(props) => props.theme.colors.accent};
		border: 2px solid ${(props) => props.theme.colors.accent};
		padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.large}`} !important;
		outline: 0;
		border-radius: 10px;
		cursor: pointer !important;
		font-weight: 600;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;
		svg path {
			fill: ${(props) => props.theme.colors.accent};
		}
		&[disabled] {
			cursor: default !important;
			border-color: ${(props) => props.theme.colors.text300};
			color: ${(props) => props.theme.colors.text300};
			svg path {
				fill: ${(props) => props.theme.colors.text300};
			}
		}
		&:focus,
		&:hover {
			outline: none;
			box-shadow: unset;
		}
		&:hover:not([disabled]),
		&:active,
		&:focus {
			color: ${(props) => props.theme.colors.accentDarker};
		}
	}
`;

const LinkAwesomeButton = styled.button.attrs<AwesomeButtonProps>((props) => ({
	'data-minddclass': props.$minddclass || 'linkawesomebutton',
	type: props.type ? props.type : 'button',
	...props
}))`
	&&& {
		all: unset;
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		background: transparent;
		color: ${(props) => (props.disabled ? props.theme.colors.text300 : props.theme.colors.accent)};
		border-color: ${(props) => (props.disabled ? props.theme.colors.text300 : 'unset')};
		padding: 6px 0px !important;
		outline: 0;
		font-weight: 600;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;
		svg path {
			fill: ${(props) => props.theme.colors.accent};
			fill: ${(props) => (props.disabled ? props.theme.colors.text300 : props.theme.colors.accent)};
		}

		&:focus,
		&:hover {
			outline: none;
			box-shadow: unset;
		}
		&:hover:not([disabled]),
		&:active,
		&:focus {
			color: ${(props) => props.theme.colors.accentDarker};
		}
	}
`;

const S = {
	SecondaryAwesomeButton,
	PrimaryAwesomeButton,
	LinkAwesomeButton
};

export default S;
