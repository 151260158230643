import React, { FunctionComponent } from 'react';
import { ReactComponent as MinddLogo } from '../../assets/mindd-logo-white.svg';
import background from '../../assets/background.png';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { default as SA } from '../Activity/Activity.styled';
import { default as S } from './MinddLogoLoader.styled';
import { LoaderConfig } from './LoaderConfig';

const MinddLogoLoader: FunctionComponent = () => {
	const [{ settings, assets }] = useStateContext();

	return (
		<>
			<S.LoaderWrapper>
				<S.LoaderSquare $slidingBackgroundUrl={assets.backgroundBlobUrl || background}>
					<S.SVGContainer>
						<MinddLogo />
					</S.SVGContainer>
				</S.LoaderSquare>
				<S.AnimatedBorder viewBox={`0 0 ${LoaderConfig.SIZE} ${LoaderConfig.SIZE}`}>
					<rect
						x={LoaderConfig.STROKE_WIDTH / 2}
						y={LoaderConfig.STROKE_WIDTH / 2}
						width={LoaderConfig.INNER_SIZE}
						height={LoaderConfig.INNER_SIZE}
						rx={LoaderConfig.BORDER_RADIUS}
						ry={LoaderConfig.BORDER_RADIUS}
					/>
				</S.AnimatedBorder>
			</S.LoaderWrapper>
			<SA.BubbleTitle className="text-centered full-width">{getLabel('LoadingAdvies', settings.applicationTexts)}</SA.BubbleTitle>
		</>
	);
};

export default MinddLogoLoader;
