import React, { FunctionComponent, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { default as SA } from '../Activity/Activity.styled';
import S from './ActivityTriage.styled';
import SP from '../Procedure/Procedure.styled';
import SCPQ from '../ConsultationPreparationQuestion/ConsultationPreparationQuestion.styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import PainSlider from '../PainSlider/PainSlider';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { ActivityProps } from '../Activity';
import animateQuestion from '../../helpers/styled/AnimateQuestionSlide';
import type { ActivityStepTriageQuestion, QuestionAnswer } from '../../models';
import useStepNumber from '../../helpers/hooks/useCurrentStepNumber';

interface ActivityTriageProps extends ActivityProps, ActivityStepTriageQuestion {
	isLastQuestion?: boolean;
	isFirstQuestion?: boolean;
	widgetIsNarrow?: boolean;
	handleQuestionAnswer: (questionId: number, answer: string | number) => Promise<void>;
}

const ActivityTriage: FunctionComponent<ActivityTriageProps> = (props) => {
	const [{ settings, refs }] = useStateContext();
	const themeContext = useTheme();
	const { question } = props;
	const stepNumber = useStepNumber(props.question.id);

	const answers =
		question.type !== 'MultipleChoice'
			? [
					{
						title: question.yesLabel || getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
						value: 'y'
					},
					{
						title: question.noLabel || getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true),
						value: 'n'
					}
			  ]
			: question.answers
			? question.answers
			: [];

	const handleQuestionAnswer = async (answer: string | number) => {
		if (answer) {
			await props.handleQuestionAnswer(question.id, answer); // handle answer registration centrally.
		}
	};

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				if (!props.isFirstQuestion && props.isLastActivity && !props.answer) {
					animateQuestion(node, themeContext, refs.widget?.current);
				}
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	return (
		<SCPQ.Wrapper ref={focusLastActivity}>
			{props.empathy && (
				<SA.EmpathyBubble>
					<SA.EmpathyBubbleTitle>{renderMarkdown(props.empathy)}</SA.EmpathyBubbleTitle>
				</SA.EmpathyBubble>
			)}
			<ActivityBubbleTitle
				title={question.title}
				info={question.info}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity && !!props.isLastQuestion}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
				widgetIsNarrow={props.widgetIsNarrow}
				stepNumber={stepNumber}
				stepperStyle={!props.isLastQuestion || !props.isLastActivity ? 'secondary' : 'primary'}
			/>

			{question.type === 'Normal' && (
				<S.TriageAnswerButtons>
					{(answers as { value: string; title: string }[]).map((answer, index) => (
						<S.CheckBox key={answer.value} checked={props.answer === answer.value}>
							<SP.Field
								type="radio"
								name={`consultation_${question.id}`}
								id={`${question.id}_${answer.value}`}
								disabled={props.disabled}
								onClick={() => void handleQuestionAnswer(`${answer.value}`)}
							/>
							<SP.Label htmlFor={`${question.id}_${answer.value}`}>{answer.title}</SP.Label>
						</S.CheckBox>
					))}
				</S.TriageAnswerButtons>
			)}

			{question.type === 'Scale' && <PainSlider disabled={props.disabled} handleActivityResponse={handleQuestionAnswer} />}

			{question.type === 'MultipleChoice' && (
				<S.TriageAnswerButtons>
					{(answers as QuestionAnswer[]).map((answer) => {
						// TEMPORARY FIX: This is a quick, dirty solution to make one specific ABCD multiple choice answer bold.
						// Hardcoded 'Nee, ga door naar mijn gekozen klacht' as the comparison string.
						// TODO: Evaluate if this is has the desired effect,
						// if so we should find a solution to use markdown in triageQuestions/answers while still supporting plain text for our API users
						const isBold =
							question.triageType === 'ABCD' && answer.title.trim().toLowerCase() === 'Nee, ga door naar mijn gekozen klacht'.toLowerCase();

						return (
							<S.CheckBox key={answer.id} checked={props.answer === `${answer.id}` ? true : false}>
								<SP.Field
									type="radio"
									name={`consultation_${question.id}`}
									id={`${question.id}_${answer.id}`}
									disabled={props.disabled}
									onClick={() => void handleQuestionAnswer(`${answer.id}`)}
								/>
								<SP.Label htmlFor={`${question.id}_${answer.id}`} style={isBold ? { fontWeight: 700 } : undefined}>
									{answer.title}
								</SP.Label>
							</S.CheckBox>
						);
					})}
				</S.TriageAnswerButtons>
			)}
		</SCPQ.Wrapper>
	);
};

export default ActivityTriage;
