import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import S, { ButtonProps as StyledButtonProps, IconProps, LoaderProps } from './Button.styled';

interface ButtonProps extends StyledButtonProps, React.PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
	$state?: boolean;
}

interface IconButtonProps extends ButtonProps, IconProps {
	$icon?: IconProp;
}

interface SpinnerButtonProps extends ButtonProps, LoaderProps {
	$isLoading?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props) => {
	const Button = props.$state ? S.ActiveButton : S.BaseButton;
	return <Button {...props}>{props.children}</Button>;
}

const ToggleButton: FunctionComponent<ButtonProps> = (props) => {
	return <Button {...props} $toggle />;
}

const IconButton: FunctionComponent<IconButtonProps> = (props) => {
	const IconButton = props.$state ? S.ActiveIconButton : S.BaseIconButton;
	const Icon = props.$icon ? S.Icon : undefined;

	if (!Icon) {
		return Button(props);
	}

	const renderIcon = () => {
		if (Icon !== undefined) {
			return <Icon icon={props.$icon as IconProp} $position={props.$position} />;
		}
		return null;
	};

	return (
		<IconButton {...props}>
			{props.$position === 'before' && renderIcon()}
			{props.children}
			{props.$position === 'after' && renderIcon()}
		</IconButton>
	);
}

const SpinnerButton: FunctionComponent<SpinnerButtonProps> = (props) => {
	const IconButton = props.$state ? S.ActiveIconButton : S.BaseIconButton;

	return (
		<IconButton {...props}>
			{props.children}
			{props.$isLoading && <S.Loader $primary={props.$primary} />}
		</IconButton>
	);
}

export default Button;
export { ToggleButton, IconButton, SpinnerButton };
