import React, { ReactNode } from 'react';
import S from './AwesomeButton.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AwesomeButtonProps {
	icon?: IconProp;
	position?: 'before' | 'after';
	children: ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	variant?: 'primary' | 'secondary' | 'link';
	title?: string;
	type?: 'button' | 'submit' | 'reset';
}

const StyledIcon = styled(FontAwesomeIcon)<{ position?: 'before' | 'after' }>`
	${(props) => (props.position === 'before' ? 'margin-inline-end: 10px;' : '')}
	${(props) => (props.position === 'after' ? 'margin-inline-start: 10px;' : '')}
`;

function AwesomeButton({ icon, position = 'before', children, variant = 'primary', ...props }: AwesomeButtonProps) {
	let StyledAwesomeButton = null;
	switch (variant) {
		case 'primary':
			StyledAwesomeButton = S.PrimaryAwesomeButton;
			break;
		case 'secondary':
			StyledAwesomeButton = S.SecondaryAwesomeButton;
			break;
		case 'link':
			StyledAwesomeButton = S.LinkAwesomeButton;
			break;
	}

	const renderIcon = () => {
		if (icon) {
			return <StyledIcon icon={icon} position={position} />;
		}
		return null;
	};

	return (
		<StyledAwesomeButton {...props}>
			{position === 'before' && renderIcon()}
			{children}
			{position === 'after' && renderIcon()}
		</StyledAwesomeButton>
	);
}

export default AwesomeButton;
