import React, { useState, useCallback, FunctionComponent, useEffect } from 'react';
import { compiler } from 'markdown-to-jsx';
import { useTheme } from 'styled-components';

import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import S from './ConsultationPreparationQuestion.styled';
import SIC from './InputChoices.styled';

import InputChoices from './InputChoices';
import InputNumber from './InputNumber';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import animateQuestion from '../../helpers/styled/AnimateQuestionSlide';
import type { ActivityAnswerConsultationPreparationQuestion, ActivityStepConsultationPreparationQuestion } from '../../models';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { TextArea } from '../FormComponents/FormComponents';
import { StepperStyle } from '../ActivityBubbleTitle/styled';

interface ConsultationPreparationQuestionProps {
	question: ActivityStepConsultationPreparationQuestion;
	isFirstQuestion: boolean;
	disabled: boolean;
	onChange: (answer?: ActivityAnswerConsultationPreparationQuestion) => Promise<boolean>;
	handleEditingPrevious: (isEditing: boolean) => void;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	modalOpen?: boolean;
	stepNumber: number;
	isLastQuestion: boolean;
	scrollOffset?: number;
	stepperStyle: StepperStyle;
}

const ConsultationPreparationQuestion: FunctionComponent<ConsultationPreparationQuestionProps> = (props) => {
	const [{ settings, refs }] = useStateContext();
	const themeContext = useTheme();

	const [answerText, setAnswerText] = useState<string>(props.question.answer?.answerText ?? '');
	const [answerChoices, setAnswerChoices] = useState<any[]>(props.question.answer?.answerChoices ?? []);
	const [showError, setShowError] = useState(false);
	const [previousAnswerChanged, setPreviousAnswerChanged] = useState(false);

	const { question } = props;
	const answers = question.question.answers;
	const choices = question.question.choices
		? question.question.choices.map((c) => ({ id: c.id, title: c.title, selected: answerChoices.some((ac) => ac === c.id) }))
		: [];

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				if (!props.isFirstQuestion && props.isLastActivity && !props.question.answer) {
					animateQuestion(node, themeContext, refs.widget?.current, props.scrollOffset ?? 0);
				}
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	useEffect(() => {
		props.handleEditingPrevious(previousAnswerChanged);
	}, [previousAnswerChanged]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = e.target.value;

		setAnswerText(value);
		setShowError(!value);

		if (props.isLastQuestion) {
			const answer = { answerId: answers[0].id, answerText: value };
			void handleSubmit(value ? answer : undefined);
		} else {
			setPreviousAnswerChanged(props.question.answer?.answerText !== value);
		}
	};

	const handleChoiceChange = (choiceId: number, checked: boolean) => {
		const updatedChoices = checked ? [...answerChoices, choiceId] : answerChoices.filter((c) => c !== choiceId);
		updatedChoices.sort((a, b) => a - b);

		setAnswerChoices(updatedChoices);
		setShowError(updatedChoices.length === 0);

		if (props.isLastQuestion) {
			const answer = { answerId: answers[0].id, answerChoices: updatedChoices };
			void handleSubmit(updatedChoices.length > 0 ? answer : undefined);
		} else {
			setPreviousAnswerChanged(JSON.stringify(updatedChoices) !== JSON.stringify(props.question.answer?.answerChoices));
		}
	};

	const handleSubmit = async (answer?: ActivityAnswerConsultationPreparationQuestion) => {
		const submitted = await props.onChange(answer);

		if (question.question.type === 'Normal') return;

		// If user cancels answer change, reset input to previous answer
		if (!submitted) {
			setAnswerChoices(question.answer?.answerChoices ?? []);
			setAnswerText(question.answer?.answerText ?? '');
		}

		setPreviousAnswerChanged(false);
	};

	return (
		<S.Wrapper ref={focusLastActivity}>
			<ActivityBubbleTitle
				title={
					<>
						{compiler(question.question.title, {
							overrides: {
								p: {
									component: ({ children, ...props }) => <p {...props}>{children}</p>,
									props: {
										'data-texttype': 'markdown'
									}
								},
								img: {
									component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
								}
							}
						})}
					</>
				}
				modalOpen={props.modalOpen}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity && !!props.isLastQuestion}
				disabled={props.disabled}
				stepNumber={props.stepNumber}
				stepperStyle={props.stepperStyle}
				style={{ padding: 0 }}
			/>
			{question.question.type === 'Open' && (
				<TextArea
					style={{ marginTop: themeContext.spacings.smallMedium }}
					$label=""
					$valid={!showError}
					value={answerText}
					name={`cvv-${question.question.id}`}
					disabled={props.disabled}
					onChange={handleInputChange}
				/>
			)}
			{question.question.type === 'Number' && (
				<SP.Col style={{ marginTop: themeContext.spacings.smallMedium }}>
					<InputNumber value={answerText} onChange={handleInputChange} disabled={props.disabled} style={{ maxWidth: 100 }} />
				</SP.Col>
			)}
			{question.question.type === 'MultipleChoice' && (
				<InputChoices name="CPAnswerChoices" choices={choices} disabled={props.disabled} onChange={handleChoiceChange} />
			)}
			{showError && (
				<>
					{question.question.type === 'Open' && (!answerText || answerText.length === 0) && (
						<SP.RowClean style={{ paddingTop: themeContext.spacings.small }}>
							<SP.Error>{getLabel('ConsultationPreparationErrorOpen', settings.applicationTexts, true)}</SP.Error>
						</SP.RowClean>
					)}
					{question.question.type === 'Number' && (!answerText || answerText.length === 0) && (
						<SP.RowClean style={{ paddingTop: themeContext.spacings.small }}>
							<SP.Error>{getLabel('ConsultationPreparationErrorNumber', settings.applicationTexts, true)}</SP.Error>
						</SP.RowClean>
					)}
					{question.question.type === 'MultipleChoice' && answerChoices.length === 0 && (
						<SP.RowClean style={{ paddingTop: themeContext.spacings.small }}>
							<SP.Error>{getLabel('ConsultationPreparationErrorMultipleChoice', settings.applicationTexts, true)}</SP.Error>
						</SP.RowClean>
					)}
				</>
			)}
			{question.question.type === 'Normal' && (
				<S.ConsultationPreparationAnswerButtons style={{ marginTop: themeContext.spacings.smallMedium }}>
					{answers.map((answer) => (
						<SIC.CheckBox key={answer.id} checked={props.question.answer?.answerId === answer.id ? true : false}>
							<SP.Field
								type="radio"
								name={`consultation_${question.question.id}`}
								id={`${question.question.id}_${answer.id}`}
								disabled={props.disabled}
								onClick={() => void handleSubmit({ answerId: answer.id })}
							/>
							<SP.Label htmlFor={`${question.question.id}_${answer.id}`}>{answer.title}</SP.Label>
						</SIC.CheckBox>
					))}
				</S.ConsultationPreparationAnswerButtons>
			)}

			{previousAnswerChanged && (
				<S.ConfirmButtonWrapper>
					<S.Button
						disabled={answerText === '' && answerChoices.length === 0}
						onClick={() => void handleSubmit({ answerId: answers[0].id, answerChoices, answerText })}
					>
						{getLabel('WidgetConfirmButton', settings.applicationTexts)}
					</S.Button>
				</S.ConfirmButtonWrapper>
			)}
		</S.Wrapper>
	);
};

export default ConsultationPreparationQuestion;
