import React, { FunctionComponent, useState } from 'react';
import type { ActivityAnswerSurvey, ActivityAnswerSurveyQuestion, ActivityStepSurvey } from '../../models';
import { default as SA } from '../Activity/Activity.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { ActivityProps } from '../Activity';
import Preloader from '../Preloader/Preloader';
import { useTheme } from 'styled-components';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import { useNextStepSurvey } from '../../helpers/hooks/nextStep/useNextStepSurvey';
import SurveyInquiry from '../SurveyInquiry/SurveyInquiry';

interface SurveyProps extends ActivityProps {
	currentStep: ActivityStepSurvey;

	isLastActivity: boolean;
	modalOpen?: boolean;
	disabled: boolean;
}

const Survey: FunctionComponent<SurveyProps> = ({ currentStep, ...props }) => {
	const [{ conversation }, dispatch] = useStateContext();
	const handleNextStepSurvey = useNextStepSurvey();
	const [loading, setLoading] = useState(false);
	const themeContext = useTheme();

	const handleQuestionAnswer = (index: number) => async (answer: ActivityAnswerSurveyQuestion) => {
		if (JSON.stringify(currentStep.questions[index].answer) === JSON.stringify(answer)) {
			return;
		}

		const updatedQuestions = currentStep.questions.slice(0, index + 1).map((q) => ({ ...q }));
		updatedQuestions[index].answer = answer;

		setLoading(true);
		const nextStep = await handleNextStepSurvey({ ...currentStep, questions: updatedQuestions });
		setLoading(false);

		if (nextStep) {
			if (nextStep.id === currentStep.id) {
				dispatch({
					type: 'conversation/updateStep',
					step: nextStep
				});
			} else {
				const surveyAnswer: ActivityAnswerSurvey = {
					introQuestion: currentStep.introQuestion,
					surveyFinished: true
				};

				dispatch({ type: 'conversation/updateStep', step: { ...currentStep, questions: updatedQuestions, answer: surveyAnswer } });

				handleSurveyFinished(surveyAnswer);
			}
		}
	};

	const handleSurveyInquirySent = (index: number) => () => {
		const updatedQuestions = currentStep.questions.slice(0, index + 1).map((q) => ({ ...q }));
		updatedQuestions[index].answer = { inquirySent: true };

		const surveyAnswer: ActivityAnswerSurvey = {
			introQuestion: currentStep.introQuestion,
			surveyFinished: true,
			inquirySent: true
		};

		dispatch({ type: 'conversation/updateStep', step: { ...currentStep, questions: updatedQuestions, answer: surveyAnswer } });

		handleSurveyFinished(surveyAnswer);
	};

	const handleSurveyFinished = (surveyAnswer: ActivityAnswerSurvey) => {
		const surveyIntroLocationStep = conversation.find((c) => c.id === currentStep.surveyIntroStepId);
		const updatedAnswer = { ...surveyIntroLocationStep?.answer, surveyAnswer };

		dispatch({
			type: 'conversation/setStepAnswer',
			stepId: currentStep.surveyIntroStepId,
			answer: updatedAnswer
		});

		dispatch({ type: 'currentSectionIndex/set', sectionIndex: conversation.findIndex((c) => c.id === currentStep.surveyIntroStepId) });
	};

	return (
		<SA.ActivityGroup>
			{/* Add margin to the bottom for smooth animation */}
			<SA.ActivityBubble>
				{currentStep.questions.map((question, index) => {
					if (question.question.type === 'Inquiry') {
						return (
							<SurveyInquiry
								key={question.question.id}
								isLastQuestion={index === currentStep.questions.length - 1}
								question={question.question}
								answer={question.answer}
								handleSurveyInquirySent={handleSurveyInquirySent(index)}
								stepNumber={index + 2}
								isFirstQuestion={index === 0}
								{...props}
							/>
						);
					} else {
						return (
							<SurveyQuestion
								key={question.question.id}
								isLastQuestion={index === currentStep.questions.length - 1}
								handleQuestionAnswer={handleQuestionAnswer(index)}
								question={question.question}
								answer={question.answer}
								stepNumber={index + 2}
								isFirstQuestion={index === 0}
								{...props}
							/>
						);
					}
				})}

				{loading ? (
					<Preloader style={{ marginTop: themeContext.spacings.large }} />
				) : (
					<div style={{ height: `calc(${themeContext.spacings.large} + ${themeContext.spacings.small})` }} />
				)}
			</SA.ActivityBubble>
		</SA.ActivityGroup>
	);
};

export default Survey;
