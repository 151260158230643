import type { Session } from '../../models';

export const validateSession = (session?: Partial<Session> | null): boolean => {
	// Perhaps in the future we can return the expiration time and validate that aswell
	if (session != null) {
		if (session.id && session.token && session.id.length > 0 && session.token.length > 0) {
			return true;
		}
	}

	return false;
};
