import styled from 'styled-components';
import AwesomeButton from '../AwesomeButton/AwesomeButton';

const DownloadButton = styled.div`
	font-weight: 400;
	margin-left: ${(props) => props.theme.spacings.medium};

	@media ${(props) => props.theme.screens.medium} {
		margin-left: 0;
	}
`;

const Button = styled(AwesomeButton)`
	&&& {
      font-weight: 400;
    }
`;

const S = {
	DownloadButton,
	Button
};

export default S;
