import React, { FunctionComponent } from 'react';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import S from './CallToAction.styled';

export type CTAType = 'callbackRequest' | 'zorgmailProcedure' | 'directCall';

interface CallToActionProps {
	type: CTAType;
	icon?: IconDefinition;
	title: string;
	subtitle?: string;
	onActionClick: (type: CTAType) => void;
	inputSection?: InputSection;
}

interface InputSection {
	InputComponent?: React.ReactNode;
	isValid?: boolean;
	submitButtonText: string;
}

const CallToAction: FunctionComponent<CallToActionProps> = ({ type, icon, title, subtitle, onActionClick, inputSection }) => {
	const handleOnClick = () => onActionClick(type);

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === ' ' || event.key === 'Enter') {
			event.preventDefault();
			handleOnClick();
		}
	};

	const hasAdditionalInput = !!inputSection;

	return (
		<S.CallToActionBanner
			onClick={!hasAdditionalInput ? handleOnClick : undefined}
			onKeyDown={!hasAdditionalInput ? handleKeyDown : undefined}
			role={!hasAdditionalInput ? 'button' : undefined}
			tabIndex={!hasAdditionalInput ? 0 : undefined}
			style={!hasAdditionalInput ? { cursor: 'pointer' } : undefined}
		>
			<S.CallToActionRow>
				<S.CallToActionWrapper>
					{icon && (
						<S.ActionIcon>
							<FontAwesomeIcon icon={icon} />
						</S.ActionIcon>
					)}
					<S.ActionTextWrapper>
						<S.ActionTitle>{renderMarkdown(title)}</S.ActionTitle>
						{subtitle && <S.ActionSubtitle>{renderMarkdown(subtitle)}</S.ActionSubtitle>}
					</S.ActionTextWrapper>
				</S.CallToActionWrapper>

				{!hasAdditionalInput && (
					<S.ChevronIcon>
						<FontAwesomeIcon icon={faChevronRight} />
					</S.ChevronIcon>
				)}
			</S.CallToActionRow>

			{hasAdditionalInput && (
				<S.InputSectionRow>
					<S.InputComponentWrapper $valid={inputSection.isValid}>{inputSection.InputComponent}</S.InputComponentWrapper>
					<S.SubmitButton onClick={handleOnClick}>{renderMarkdown(inputSection.submitButtonText)}</S.SubmitButton>
				</S.InputSectionRow>
			)}
		</S.CallToActionBanner>
	);
};

export default CallToAction;
