import styled from 'styled-components';
import { getLabel } from '../../helpers/constants/getLabels';
//import { default as SA } from '../Activity/styled';

const GRID_SIZE = 12;
const GRID_STEP_SIZE = 100 / GRID_SIZE;

interface ProcedureFieldProps {
	$valid?: boolean | null;
	required?: boolean | null;
	$optional?: boolean;
}

interface ProcedureFieldLabelProps {
	$requiredLabel?: string;
	$optionalLabel?: string;
}

interface ProcedureColProps {
	$size?: number;
	$xs?: number;
	$sm?: number;
	$md?: number;
}

interface OptionalStickyProps {
	$sticky?: boolean;
	$bottom?: boolean;
	$top?: boolean;
}

interface RowProps {
	$fullWidth?: boolean;
}

const Form = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	/* justify-content: space-between; */
`;

const Row = styled.div<RowProps>`
	width: ${(props) => (props.$fullWidth ? `calc(100% + ${props.theme.spacings.extraLarge})` : '100%')};
	margin: ${(props) => (props.$fullWidth ? `0 -${props.theme.spacings.medium}` : '0')};

	&:not(:first-child) {
		padding: ${(props) => props.theme.spacings.medium} 0 0 0;
	}
`;

const RowClean = styled(Row)<OptionalStickyProps>`
	padding: 0;

	${(props) => (props.$sticky ? 'position: sticky;' : '')}
	${(props) => (props.$bottom ? 'bottom: 0;' : '')}
	${(props) => (props.$top ? 'top: 0;' : '')}
`;

const RowCheckbox = styled(Row)`
	padding: 0;
	display: flex;
	> *:last-child {
		flex-grow: 2;
	}
`;

const RowConsent = styled(RowCheckbox)``;

const Col = styled.div<ProcedureColProps>`
	&&& {
		position: relative;
		width: ${(props) => (props.$size ?? GRID_SIZE) * GRID_STEP_SIZE}%;
		padding-inline-end: 1%;
		display: inline-block;

		${(props) =>
			props.$xs
				? `
			@media ${props.theme.screens.extraSmall} {
				width: ${(props.$xs || GRID_SIZE) * GRID_STEP_SIZE}%;
			}
		`
				: ''}
		${(props) =>
			props.$sm
				? `
			@media ${props.theme.screens.small} {
				width: ${(props.$sm || GRID_SIZE) * GRID_STEP_SIZE}%;
			}
		`
				: ''}
		${(props) =>
			props.$md
				? `
			@media ${props.theme.screens.medium} {
				width: ${(props.$md || GRID_SIZE) * GRID_STEP_SIZE}%;
			}
		`
				: ''}

		@media ${(props) => props.theme.screens.small} {
			&:not(:last-child) {
				margin-bottom: ${(props) => props.theme.spacings.medium};
			}
		}
	}
`;

const Col50 = styled(Col)`
	&&& {
		width: 48%;
		padding-inline-end: 2%;
	}
`;

const Col64 = styled(Col)`
	&&& {
		width: 100%;
		padding-inline-end: 1%;
		@media ${(props) => props.theme.screens.medium} {
			width: 64%;
		}
	}
`;

const Label = styled.label<ProcedureFieldProps & ProcedureFieldLabelProps>`
	color: ${(props) => props.theme.colors.text900};
	display: block;
	margin: 0;
	font-size: ${(props) => props.theme.fontSizes.base};
	font-weight: 600;
	margin-bottom: ${(props) => props.theme.spacings.extraSmall};
	cursor: text;
	line-height: 1.5; // added to override hbdr.nl website css, in order to vertically position radio buttons correctly
	&[required]::after {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 10px;
		font-size: ${(props) => props.theme.fontSizes.xs};
		line-height: ${(props) => props.theme.fontSizes.sm};
		border-radius: ${(props) => props.theme.radii.default};
		padding: 4.5px;
	}
	&[required]::after {
		content: '${(props) => props.$requiredLabel}';
		background-color: ${(props) => (props.$valid === false ? props.theme.colors.error : props.theme.colors.accent100)};
		color: ${(props) => (props.$valid === false ? 'var(--text-error-100, #FAE9E5)' : props.theme.colors.text900)};
	}
`;
const LabelInline = styled(Label)`
	display: inline-block;
	margin: 0;
	margin-inline-start: 1em;
`;

const Field = styled.input<ProcedureFieldProps>`
	&& {
		display: block !important;
		border-style: solid;
		border-width: ${(props) => (props.$valid === false ? '2px' : '1px')};
		border-color: ${(props) => (props.$valid === false ? props.theme.colors.error : '#cccccc')};
		border-radius: ${(props) => props.theme.radii.default};
		background: transparent;
		padding: 5px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		color: ${(props) => (props.disabled ? '#737373' : props.theme.colors.text900)}; // #737373 = placeholder color

		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&:focus:not([type='checkbox']) {
			outline: solid 2px ${(props) => props.theme.colors.accent400};
			border-color: ${(props) => (props.$valid === false ? props.theme.colors.error : '#cccccc')};
		}

		&[type='date'] {
			-webkit-appearance: textfield;
			-moz-appearance: textfield;
			background: #fff;
			line-height: 1.5;
		}
		&[type='checkbox'] {
			appearance: auto;
			display: inline-block !important;
			margin-top: calc(0.3 * ${(props) => props.theme.spacings.medium});
			vertical-align: top;
			width: auto;

			&:focus-visible {
				outline: 2px auto ${(props) => props.theme.colors.focus};
			}
		}
		&[type='number'] {
			appearance: textfield;
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				webkit-appearance: none;
				margin: 0;
			}
			&:hover,
			&:focus {
				appearance: number-input;
			}
		}
		&[type='file'] {
			padding: 2px 5px;

			&::file-selector-button {
				border: none;
				background-color: transparent;
				color: ${(props) => props.theme.colors.text600};
				border-right: solid 1px ${(props) => props.theme.colors.text600};
				margin-right: ${(props) => props.theme.spacings.small};
				padding-right: ${(props) => props.theme.spacings.small};
			}
		}
	}
`;

const FieldRound = styled(Field)`
	border-radius: ${(props) => props.theme.radii.default} !important;
	border: 1px solid ${(props) => props.theme.colors.accent} !important;
	padding: ${(props) => props.theme.spacings.extraSmall} ${(props) => props.theme.spacings.small};
`;

const FieldSelect = styled.select<ProcedureFieldProps>`
	&& {
		display: block !important;
		border-style: solid;
		border-width: ${(props) => (props.$valid === false ? '2px' : '1px')};
		border-color: ${(props) => (props.$valid === false ? props.theme.colors.error : '#cccccc')};
		border-radius: ${(props) => props.theme.radii.default};
		background: transparent;
		padding: 8px 10px 7px 10px;
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		width: 100%;
		font-weight: normal;
		height: auto;
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		&:focus {
			outline: solid 2px ${(props) => props.theme.colors.accent400};
		}
	}
`;

const FieldTextArea = styled.textarea<ProcedureFieldProps>`
	&& {
		display: block;
		border-style: solid;
		border-width: ${(props) => (props.$valid === false ? '2px' : '1px')};
		border-color: ${(props) => (props.$valid === false ? props.theme.colors.error : '#cccccc')};
		border-radius: ${(props) => props.theme.radii.default};
		background: transparent;
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		width: 100%;
		height: 100px;
		padding: 10px;
		&:focus {
			outline: solid 2px ${(props) => props.theme.colors.accent400};
		}
		@media ${(props) => props.theme.screens.medium} {
			height: 70px;
		}

		& + .textarea-info-character-counter {
			width: 100%;
			display: flex;
			justify-content: space-between;
			gap: ${(props) => props.theme.spacings.medium};

			svg {
				margin-inline-end: ${(props) => props.theme.spacings.extraSmall};
			}
		}

		& + .textarea-character-counter {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			font-size: ${(props) => props.theme.fontSizes.sm};
			padding-top: ${(props) => props.theme.spacings.small};
		}
	}
`;

const Error = styled.div.attrs({ role: 'alert' })`
	color: ${(props) => props.theme.colors.error} !important;

	* {
		color: ${(props) => props.theme.colors.error} !important;
	}

	ul {
		margin-block-start: 0.5em;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;

		li {
			list-style-type: disc;
		}
	}

	svg {
		margin-inline-end: ${(props) => props.theme.spacings.extraSmall};
	}
`;

const Succes = styled.div`
	color: #28a745 !important;
	padding: ${(props) => props.theme.spacings.medium} 0;
`;

const Alert = styled.div`
	cursor: pointer;
	color: ${(props) => props.theme.colors.accent} !important;
	padding-bottom: ${(props) => props.theme.spacings.medium};
`;

const FormWarningMessage = styled.div`
	&& {
		border-radius: ${(props) => props.theme.radii.default};
		background-color: ${(props) => props.theme.colors.noticeDefault};
		padding: ${(props) => `${props.theme.spacings.medium} ${props.theme.spacings.medium}`};
		color: ${(props) => props.theme.colors.text800};
	}
`;

export default {
	Form,
	FormWarningMessage,
	Row,
	RowClean,
	RowCheckbox,
	RowConsent,
	Col,
	Col50,
	Col64,
	Label,
	LabelInline,
	Field,
	FieldRound,
	FieldSelect,
	FieldTextArea,
	Error,
	Succes,
	Alert
};
