import React, { FunctionComponent } from 'react';

import S from './Preloader.styled';

interface PreloaderProps {
	style?: React.CSSProperties;
} 

const Preloader: FunctionComponent<PreloaderProps> = ({ style }) => (
	<S.Wrapper style={style}>
		<S.Bubble />
		<S.Bubble />
		<S.Bubble />
		<S.Bubble />
	</S.Wrapper>
);

export default Preloader;
