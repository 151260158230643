import { compiler } from 'markdown-to-jsx';
import { getLabel } from './getLabels';
import { markdownCompilerOverrides } from '../support/renderMarkdown';
import type { ApplicationText, LanguageApplicationTexts } from '../../models';

export function getLabelWithOverride(
	overrideLabel: string | LanguageApplicationTexts | undefined,
	label: string,
	labels: ApplicationText[],
	languageCode: string,
	textOnly: true
): string;
export function getLabelWithOverride(
	overrideLabel: string | LanguageApplicationTexts | undefined,
	label: string,
	labels: ApplicationText[],
	languageCode: string,
	textOnly: false
): JSX.Element | string;
export function getLabelWithOverride(
	overrideLabel: string | LanguageApplicationTexts | undefined,
	label: string,
	labels: ApplicationText[],
	languageCode: string
): JSX.Element | string;

/**
 *
 * @param {*} overrideLabel The override label, can be a string or an object with a property for the supported languages
 * @param {*} fallbackLabel The fallback label, if no suitable value is found in the overrideLabel this is used. Is assumed to already be compiled from markdown
 * @param {string} languageCode The current active language code, used to select to correct label from overrideLabel
 * @param {boolean} textOnly When true the string won't be compiled from markdown to HTML. Default is false
 */
export function getLabelWithOverride(
	overrideLabel: string | LanguageApplicationTexts | undefined,
	label: string,
	labels: ApplicationText[],
	languageCode: string,
	textOnly = false
): JSX.Element | string {
	let resultText = '';

	if (overrideLabel) {
		if (typeof overrideLabel === 'string' && languageCode === 'NL' && overrideLabel.trim() !== '') {
			resultText = overrideLabel.trim();
		} else if (typeof overrideLabel === 'object' && typeof overrideLabel[languageCode] === 'string' && overrideLabel[languageCode].trim() !== '') {
			resultText = overrideLabel[languageCode].trim();
		}
	}

	// We didn't find a suitable label in the override, so get the default label
	if (resultText === '') {
		return getLabel(label, labels, textOnly);
	}

	// Process the supplied override label
	resultText = resultText.replace(/\\n/g, '\n');

	if (textOnly) return resultText;

	return compiler(resultText, {
		overrides: markdownCompilerOverrides
	});
}
