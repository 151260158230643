import React, { FunctionComponent } from 'react';
import S from './PreviousNextButtons.styled';
import SB from '../Button/Button.styled';
import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';

interface PreviousNextButtonsProps {
	onPrevious?: () => void;
	onNext?: () => void;
	disabled?: boolean;
	disabledNext?: boolean;
	disabledPrevious?: boolean;
	nextButtonSuffix?: string;
	loading?: boolean;
	modalOpen?: boolean;
}

const PreviousNextButtons: FunctionComponent<PreviousNextButtonsProps> = ({
	onPrevious,
	onNext,
	disabled,
	disabledNext,
	disabledPrevious,
	loading = false,
	modalOpen = true
}) => {
	const [{ settings }] = useStateContext();

	return (
		<S.Buttons $sticky={modalOpen}>
			<S.Button onClick={onPrevious} disabled={disabled || disabledPrevious} tabIndex={0}>
				{getLabel('WidgetPreviousButton', settings.applicationTexts, true)}
			</S.Button>
			<S.Button onClick={onNext} disabled={disabled || disabledNext} tabIndex={0}>
				{loading ? (
					<S.ButtonLoaderWrapper>
						<SB.Loader $primary />
					</S.ButtonLoaderWrapper>
				) : (
					<>{getLabel('WidgetNextButton', settings.applicationTexts, true)}</>
				)}
			</S.Button>
		</S.Buttons>
	);
};

export default PreviousNextButtons;
