import { useCallback } from 'react';

import { StateContextType, useStateContext } from './useStateContext';
import { useDetermineNextStep } from './nextStep/useDetermineNextStep';

import { CallStartSession } from '../services';

export function useResetWidget(): (startNewSession: boolean) => Promise<void> {
	const [{ settings, profile }, dispatch]: StateContextType = useStateContext();
	const determineNextStep = useDetermineNextStep();

	return useCallback(
		async (startNewSession: boolean) => {
			const firstStep = await determineNextStep();
			if (firstStep) {
				dispatch({
					type: 'updateConversationActivities',
					conversation: [firstStep]
				});
			}

			dispatch({
				type: 'updateSession',
				session: {
					id: '',
					token: ''
				}
			});

			dispatch({ type: 'currentSectionIndex/reset' });

			dispatch({
				type: 'updateProfile/reset',
				forceInitialStateEmpty: settings.forceInitialStateEmpty
			});

			if (startNewSession) {
				const newSession = await CallStartSession(settings.ApiKey, {
					widgetType: settings.widgetType,
					restart: true,
					externalId: profile.externalId,
					phoneNumber: profile.phoneNumber,
					languageCode: settings.selectedLanguage.code
				});

				if (newSession) {
					dispatch({
						type: 'updateSession',
						session: {
							id: newSession.sessionId,
							token: newSession.sessionToken
						}
					});
				}
			}
		},
		[
			profile.externalId,
			profile.phoneNumber,
			settings.ApiKey,
			settings.forceInitialStateEmpty,
			settings.selectedLanguage.code,
			settings.widgetType,
			determineNextStep,
			dispatch
		]
	);
}
