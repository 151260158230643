import React from 'react';

import { default as SP } from '../Procedure/Procedure.styled';
import S from './InputChoices.styled';
import ST from '../ActivityTriage/ActivityTriage.styled';

interface Props {
	name: string;
	choices: Array<{ id: number; title: string; selected: boolean }>;
	disabled?: boolean;
	onChange: (id: number, checked: boolean) => void;
}

const InputChoices = (props: Props) => {
	return (
		<ST.TriageAnswerButtons>
			{props.choices.map((c) => (
				<S.CheckBox key={c.id} checked={c.selected}>
					<SP.Field
						id={`${props.name}_${c.id}`}
						type="checkbox"
						checked={c.selected}
						disabled={props.disabled}
						onChange={(e) => props.onChange(c.id, e.target.checked)}
					/>
					<SP.LabelInline htmlFor={`${props.name}_${c.id}`}>{c.title}</SP.LabelInline>
				</S.CheckBox>
			))}
		</ST.TriageAnswerButtons>
	);
};

export default InputChoices;
