import { isAxiosError } from 'axios';

import { logAxiosError, minddApiAxios } from './AxiosInstance';
import { LanguageInput, SessionInput } from './BaseInput';
import type { ProblemDetails } from '../../models';

export type SendEmailSmsAdviceInput = SessionInput &
	LanguageInput & {
		email?: string;
		phone?: string;
	};

export type SendEmailSmsAdviceOutput = {
	message: 'success' | 'ConsultationPreparation';
};

async function CallPostSendEmailSmsAdvice(apiKey: string, input: SendEmailSmsAdviceInput): Promise<SendEmailSmsAdviceOutput | ProblemDetails | undefined> {
	try {
		const { data } = await minddApiAxios.post<SendEmailSmsAdviceOutput>('PostSendEmailSmsAdvice?api-version=2.1', input, {
			headers: {
				'MINDD-OC-ApiKey': apiKey
			}
		});

		return data;
	} catch (error) {
		logAxiosError(error, 'CallPostSendEmailSmsAdvice');

		if (isAxiosError<ProblemDetails>(error) && error.response) {
			return error.response.data;
		}
	}

	return undefined;
}

export { CallPostSendEmailSmsAdvice };
