import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { compiler, MarkdownToJSX } from 'markdown-to-jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { default as SA } from '../../components/Activity/Activity.styled';
import tryCatch from '../../helpers/support/tryCatch';

/**
 *
 * @param {*} text
 * @param {*} overrides add or replace ovverides  example:{
						h1: {
							component: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
							props: {
								'data-texttype': 'markdown'
							}
						}
					}
 */
export const renderMarkdown = (text: string, overrides: MarkdownToJSX.Overrides = {}) => {
	if (!text) return false;
	text = text.length > 0 ? text.replace(/\\n/g, '\n') : text;

	return compiler(text, {
		overrides: {
			...markdownCompilerOverrides,
			...overrides
		}
	});
};

type OverrideComponentProps<T> = {
	children?: ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

const MarkDownAnchorElement: FunctionComponent<OverrideComponentProps<HTMLAnchorElement>> = ({ children, ...props }) => {
	// @ts-expect-error props.href should always exist for anchor tags
	// eslint-disable-next-line no-restricted-globals, react/prop-types
	const isExternalUrl = useMemo(() => tryCatch(() => new URL(props.href).origin !== location.origin, false), [props.href]);

	return (
		<SA.MarkDownLink target="_blank" rel="noopener noreferrer" {...props}>
			{isExternalUrl && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
			{children}
		</SA.MarkDownLink>
	);
};

export { MarkDownAnchorElement };

export const markdownCompilerOverrides = {
	p: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLParagraphElement>) => <SA.MarkDownP {...props}>{children}</SA.MarkDownP>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	h1: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h1">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	h2: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h2">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	h3: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h3">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	h4: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h4">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	h5: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h5">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	h6: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLHeadingElement>) => (
			<SA.BubbleSubTitle {...props} $heading="h6">
				{children}
			</SA.BubbleSubTitle>
		),
		props: {
			'data-texttype': 'markdown'
		}
	},
	ul: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLUListElement>) => <SA.MarkDownUl {...props}>{children}</SA.MarkDownUl>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	li: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLLIElement>) => <SA.MarkDownLi {...props}>{children}</SA.MarkDownLi>,
		props: {
			'data-texttype': 'markdown'
		}
	},
	a: {
		component: MarkDownAnchorElement
	},
	img: {
		component: ({ children, ...props }: OverrideComponentProps<HTMLImageElement>) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
	}
};
