import React, { FunctionComponent } from 'react';
import styled, { keyframes, css, ExecutionContext, FastOmit } from 'styled-components';

const dotFlashing = (props: ExecutionContext & FastOmit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, never>) => {
	return keyframes`
        0% {
           background-color: ${props.theme.colors.accent};
        }
        50%,
        100% {
            background-color:  ${props.theme.colors.text500};
		  }
    `;
};

const Dotflashing = styled.div`
	position: relative;
	margin-inline-start: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: ${(props) => props.theme.colors.accent};
	animation: ${(props) => css`
		${dotFlashing(props)} 1s infinite linear alternate
	`};
	animation-delay: 0.5s;
	::before,
	::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: ${(props) => props.theme.colors.accent};
		animation: ${(props) => css`
			${dotFlashing(props)} 1s infinite alternate
		`};
	}

	:before {
		inset-inline-start: -15px;
		animation-delay: 0s;
	}

	::after {
		inset-inline-start: 15px;
		animation-delay: 1s;
	}
`;

const Loader: FunctionComponent = () => {
	return <Dotflashing data-minddclass="dotflashing" />;
};

export default Loader;
