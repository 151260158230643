import React, { FunctionComponent } from 'react';

import S from './SessionSummaryForm.styled';
import SA from '../ActivityAdvice/ActivityAdvice.styled';
import SDB from '../DownloadButton/DownloadButton.styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SessionFields {
	Name?: string;
	GivenName?: string;
	NameParticle?: string;
	Surname?: string;
	Gender?: string;
	Bsn?: string;
	Email?: string;
	Tel?: string;
	Dob?: string;
	Doctor?: string;
	HapLocation?: string;
	Address?: string;
	HouseNumber?: string;
	ZipCode?: string;
	ImageData?: string;
	ImageContentType?: string;
	Comment?: string;
	CallbackLocation?: string;
}

interface SessionSummaryFormProps extends React.PropsWithChildren {
	callbackTime?: string;
	fields: SessionFields;
	isCallback?: boolean;
	showAsAlert?: boolean;
	showAsDownloadLink?: boolean;
	showAsButton?: boolean;
	title: string;
}

const SessionSummaryForm: FunctionComponent<SessionSummaryFormProps> = (props) => {
	const [{ settings, session }] = useStateContext();
	const { callbackTime, fields, isCallback, showAsAlert, showAsDownloadLink, showAsButton, title } = props;

	let pdfType = 'Default';
	if (isCallback) {
		pdfType = 'Callback';
	}

	const includeUser = isCallback;

	return (
		<S.Form method="POST" action={`${process.env.REACT_APP_API ?? ''}/GetSessionSummary/Form?api-version=2.1`} rel="noopener">
			<input type="hidden" name="ApiKey" value={settings.ApiKey} />
			<input type="hidden" name="SessionId" value={session.id} />
			<input type="hidden" name="SessionToken" value={session.token} />
			{includeUser && fields.Name && <input type="hidden" name="User.Name" value={fields.Name} />}
			{includeUser && fields.GivenName && <input type="hidden" name="User.GivenName" value={fields.GivenName} />}
			{includeUser && fields.NameParticle && <input type="hidden" name="User.NameParticle" value={fields.NameParticle} />}
			{includeUser && fields.Surname && <input type="hidden" name="User.Surname" value={fields.Surname} />}
			{includeUser && fields.Gender && <input type="hidden" name="User.Gender" value={fields.Gender} />}
			{includeUser && fields.Bsn && <input type="hidden" name="User.Bsn" value={fields.Bsn} />}
			{includeUser && fields.Email && <input type="hidden" name="User.Email" value={fields.Email} />}
			{includeUser && fields.Tel && <input type="hidden" name="User.PhoneNumber" value={fields.Tel} />}
			{includeUser && fields.Dob && <input type="hidden" name="User.DateOfBirth" value={fields.Dob} />}
			{includeUser && fields.Doctor && <input type="hidden" name="User.Doctor" value={fields.Doctor} />}
			{includeUser && fields.HapLocation && <input type="hidden" name="User.HapLocation" value={fields.HapLocation} />}
			{includeUser && fields.Address && <input type="hidden" name="User.Address" value={fields.Address} />}
			{includeUser && fields.HouseNumber && <input type="hidden" name="User.HouseNumber" value={fields.HouseNumber} />}
			{includeUser && fields.ZipCode && <input type="hidden" name="User.ZipCode" value={fields.ZipCode} />}
			{includeUser && fields.ImageData && <input type="hidden" name="User.ImageData" value={fields.ImageData} />}
			{includeUser && fields.ImageContentType && <input type="hidden" name="User.ImageContentType" value={fields.ImageContentType} />}
			{includeUser && fields.Comment && <input type="hidden" name="User.Comment" value={fields.Comment} />}
			{includeUser && fields.CallbackLocation && <input type="hidden" name="User.CallbackLocation" value={fields.CallbackLocation} />}
			<input type="hidden" name="PdfType" value={pdfType} />
			{callbackTime && <input type="hidden" name="CallbackTime" value={callbackTime} />}
			<input type="hidden" name="LanguageCode" value={settings.selectedLanguage.code} />
			<input type="hidden" name="ShowUrgenceInPdf" value="false" />
			{showAsAlert && (
				<S.Alert as="button" title={title}>
					{props.children}
				</S.Alert>
			)}
			{showAsDownloadLink && (
				<SDB.Button icon={faDownload} title={title} data-minddclass="downloadlink" type="submit" variant="link">
					{props.children}
				</SDB.Button>
			)}
			{showAsButton && (
				<div>
					<SA.AdviceHeaderButton type="submit">
						<FontAwesomeIcon icon={faDownload} />
						{props.children}
					</SA.AdviceHeaderButton>
				</div>
			)}
		</S.Form>
	);
};

export default SessionSummaryForm;
