import { useCallback, useEffect, useRef, useState } from 'react';

export default (registerHandlers = true) => {
	const [index, _setIndex] = useState(0);
	const indexRef = useRef(index);
	const setIndex = useCallback((data: number, pushState = false) => {
		if (data > indexRef.current && pushState) {
			window.history.pushState({ index: data }, '', '');
		}
		indexRef.current = data;
		_setIndex(data);
	}, [_setIndex]);

	const [handleBack, _setHandleBack] = useState<() => void>();
	const handleBackRef = useRef<() => void>();
	const [handleForward, _setHandleForward] = useState<() => void>();
	const handleForwardRef = useRef<() => void>();
	const setHandleBack = useCallback((callback: () => void) => {
		handleBackRef.current = callback;
		_setHandleBack(() => callback);
	}, [_setHandleBack]);
	const setHandleForward = useCallback((callback: () => void) => {
		handleForwardRef.current = callback;
		_setHandleForward(() => callback);
	}, [_setHandleForward]);

	const on = useCallback((event: 'back' | 'forward', callback: () => void) => {
		switch (event) {
			case 'back':
				setHandleBack(callback);
				break;
			case 'forward':
				setHandleForward(callback);
				break;
			default:
				break;
		}
	}, [setHandleBack, setHandleForward]);

	const onPopState = (event: PopStateEvent) => {
		const { state } = event;

		if (state && typeof state.index === typeof 42) {
			if (state.index < indexRef.current) {
				handleBackRef.current?.();
			} else if (state.index > indexRef.current) {
				handleForwardRef.current?.();
			}
			setIndex(state.index, false);
		}
	};

	const incrementIndex = useCallback(() => {
		setIndex(indexRef.current + 1, true);
	}, [setIndex]);

	const decrementIndex = useCallback(() => {
		setIndex(indexRef.current - 1);
	}, [setIndex]);

	useEffect(() => {
		if (!registerHandlers) {
			return;
		}
		window.history.replaceState(null, '');
		window.history.pushState({ index: -1 }, '', '');
		window.history.pushState({ index }, '', '');

		window.addEventListener('popstate', onPopState);

		return () => {
			window.removeEventListener('popstate', onPopState);
		};
	}, []);

	return {
		index,
		on,
		incrementIndex,
		decrementIndex
	};
};
