import styled from 'styled-components';

const WidgetDisabledAlert = styled.div`
	padding: ${(props) => props.theme.spacings.smallMedium};
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => props.theme.colors.noticeDefault};
	box-shadow: 0 0 4px 0 #00000026;
	color: ${(props) => props.theme.colors.text800};
	z-index: 15;
	display: flex;
	gap: ${(props) => props.theme.spacings.small};
	
	& svg {
		height: 24px;
		padding: 4px;
	}
`;

const WidgetDisabledAlertContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacings.extraSmall};
`;

const LinkToScheduler = styled.span`
	text-decoration: underline;
	cursor: pointer;
`;

export default {
	WidgetDisabledAlert,
	WidgetDisabledAlertContent,
	LinkToScheduler
};
