import styled from 'styled-components';

import { default as SAT } from '../ActivityTriage/ActivityTriage.styled';

const S = {
	CheckBox: styled(SAT.CheckBox)``,
	Wrapper: styled.div`
		gap: ${(props) => props.theme.spacings.extraSmall};
		display: flex;
		flex-direction: column;
	`
};

export default S;
