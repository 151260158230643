import React, { useState, useCallback, useEffect, FunctionComponent, useId, useMemo } from 'react';
import { compiler } from 'markdown-to-jsx';
import { default as SA } from '../Activity/Activity.styled';
import { default as SP } from '../Procedure/Procedure.styled';
import { default as SCPQ } from '../ConsultationPreparationQuestion/ConsultationPreparationQuestion.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { CallSurveyPostInquiryForm } from '../../helpers/services';
import type { ActivityProps } from '../Activity';
import type { ActivityAnswerSurveyQuestion, SurveyQuestion } from '../../models';
import { InputCheckBox, InputPhone, InputText, parseInputPhoneNumber } from '../FormComponents/FormComponents';
import ActivityBubbleTitle from '../ActivityBubbleTitle';
import animateQuestion from '../../helpers/styled/AnimateQuestionSlide';

import { useTheme } from 'styled-components';
import { validateEmail } from '../ProcedureContactRequest/ProcedureContactRequest';

interface SurveyInquiryProps extends ActivityProps {
	question: SurveyQuestion;
	answer?: ActivityAnswerSurveyQuestion;
	stepNumber: number;
	isFirstQuestion: boolean;
	isLastQuestion: boolean;
	scrollOffset?: number;
	handleSurveyInquirySent: () => void;
}

type Fields = {
	SurveyEmail?: string;
	SurveyTel?: string;
	SurveyConsent: boolean;
};

type ValidFormFields = {
	SurveyEmail?: boolean;
	SurveyTel?: boolean;
	SurveyConsent?: boolean;
};

const SurveyInquiry: FunctionComponent<SurveyInquiryProps> = (props) => {
	const [{ profile, settings, session, refs }] = useStateContext();
	const [hasSendDataBeenCalled, setHasSendDataBeenCalled] = useState(false);
	const [sendStatus, setSendStatus] = useState(props.answer?.inquirySent ?? false);

	const [validFields, setValidFields] = useState<ValidFormFields>({});

	const [fields, setFields] = useState<Fields>({
		SurveyEmail: '',
		SurveyTel: parseInputPhoneNumber(profile.phoneNumber),
		SurveyConsent: false
	});

	const themeContext = useTheme();

	const { question } = props;

	const focusLastActivity = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				if (!props.isFirstQuestion && props.isLastActivity && !props.answer) {
					animateQuestion(node, themeContext, refs.widget?.current, props.scrollOffset ?? 0);
				}
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const validateFields = useCallback(() => {
		const emailIsValid = validateEmail(fields.SurveyEmail || null, question.askForEmailAddress);
		const phoneNumberIsValid = !question.askForPhoneNumber || !!fields.SurveyTel;
		const consent = !!fields.SurveyConsent;

		const newValidFields: ValidFormFields = {
			SurveyEmail: emailIsValid,
			SurveyTel: phoneNumberIsValid,
			SurveyConsent: consent
		};

		setValidFields(newValidFields);

		return emailIsValid && phoneNumberIsValid && consent;
	}, [fields, question.askForEmailAddress, question.askForPhoneNumber]);

	const validationStatus = useMemo(() => (hasSendDataBeenCalled ? validateFields() : true), [hasSendDataBeenCalled, validateFields]);

	const handleChangeFormField = (field: React.ChangeEvent<HTMLInputElement>) => {
		if (field.target.type === 'checkbox') {
			setFields({
				...fields,
				[field.target.name]: field.target.checked ? true : null
			});
		} else {
			setFields({
				...fields,
				[field.target.name]: field.target.value === '' || field.target.value.trim() === '' ? null : field.target.value
			});
		}
	};

	const handleChangePhoneField = (value?: string) => {
		setFields({
			...fields,
			SurveyTel: value
		});
	};

	const handleSendData = useCallback(async () => {
		if (props.disabled) {
			return;
		}

		setHasSendDataBeenCalled(true);

		const valid = session !== null && validateFields();

		if (valid) {
			const result = await CallSurveyPostInquiryForm(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				email: fields.SurveyEmail,
				phoneNumber: fields.SurveyTel,
				userAgreedToPrivacyStatement: fields.SurveyConsent
			});

			if (result) {
				setSendStatus(true);
				props.handleSurveyInquirySent();
			}
		}
	}, [fields, question.askForEmailAddress, question.askForPhoneNumber, session, settings.ApiKey, props.disabled]);

	useEffect(() => {
		props.setDisableNext(false);
		props.setHandleNext(() => () => void handleSendData());

		return () => {
			props.setDisableNext(true);
			props.setHandleNext(() => undefined);
		};
	}, [handleSendData]);

	const telId = useId();

	return (
		<SCPQ.Wrapper ref={focusLastActivity}>
			<>
				<ActivityBubbleTitle
					title={
						<>
							{compiler(question.title, {
								overrides: {
									p: {
										component: ({ children, ...props }) => <p {...props}>{children}</p>,
										props: {
											'data-texttype': 'markdown'
										}
									},
									img: {
										component: ({ children, ...props }) => <SA.MarkdownImage {...props}>{children}</SA.MarkdownImage>
									}
								}
							})}
						</>
					}
					modalOpen={props.modalOpen}
					isFirstActivity={props.isFirstActivity}
					isLastActivity={props.isLastActivity && props.isLastQuestion}
					disabled={props.disabled}
					stepNumber={props.stepNumber}
					stepperStyle={!props.isLastQuestion || !props.isLastActivity ? 'secondary' : 'primary'}
				/>
				<>
					{renderMarkdown(settings.survey.labels.formIntro)}
					<SP.Form>
						{question.askForEmailAddress && (
							<SP.Col>
								<InputText
									onChange={handleChangeFormField}
									name="SurveyEmail"
									id="SurveyEmail"
									$label={getLabel('WidgetFormLabelEmail', settings.applicationTexts, true)}
									$valid={validationStatus || validFields.SurveyEmail}
									data-valid={validationStatus || validFields.SurveyEmail}
									$invalidError={getLabel('callBackRequestErrorEmail', settings.applicationTexts, true)}
									disabled={props.disabled}
									required={settings.contactRequest.clientEmailRequired}
									value={fields.SurveyEmail}
									autoComplete="email"
								/>
							</SP.Col>
						)}

						{question.askForPhoneNumber && (
							<SP.Col>
								<InputPhone
									onChange={handleChangePhoneField}
									name="Tel"
									id={telId}
									$label={getLabel('WidgetFormLabelTel', settings.applicationTexts, true)}
									defaultValue={fields.SurveyTel}
									$valid={validationStatus || validFields.SurveyTel}
									$invalidError={getLabel('callBackRequestErrorTel', settings.applicationTexts, true)}
									disabled={props.disabled}
									autoComplete="tel-national"
								/>
							</SP.Col>
						)}

						<SP.Row>
							<InputCheckBox
								onChange={handleChangeFormField}
								name="SurveyConsent"
								$label={renderMarkdown(settings.survey.labels.formConsent) || ''}
								type="checkbox"
								disabled={props.disabled}
							></InputCheckBox>
						</SP.Row>

						<SP.RowClean>{validationStatus === false && <SP.Error>{renderMarkdown(settings.survey.labels.formError)}</SP.Error>}</SP.RowClean>
					</SP.Form>
				</>
				{sendStatus && <SP.Succes>{renderMarkdown(settings.survey.labels.formSuccess)}</SP.Succes>}
			</>
		</SCPQ.Wrapper>
	);
};

export default SurveyInquiry;
